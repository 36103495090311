import axios from "axios";
const baseURL = "https://jdesmc.firexuae.com"


const AxiosMain = axios.create({
  baseURL: baseURL,
  timeout:1000000000000000
});

// axios.defaults.timeout = 30000;

AxiosMain.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  //console.log("Main Response", response);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});
export default AxiosMain;                      