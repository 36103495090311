import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../redux/components/LoadingComponent';
import AxiosMain from '../../utilits/const';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ArrowDownWard from '@mui/icons-material/ArrowDownwardOutlined';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';

import { PurchaseYTDDATA } from '../../redux/features/homeslice';
import { tableCustomStyles } from '../TableStyle';


export default function YearToDatePurchase() {
  const userauth = useSelector((state) => state.auth);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const apiData = useSelector((state) => state.home);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [total, setTotal] = useState(0)
  const curentYear = moment().year();;
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")

  const [sortResult, setSortResult] = useState([]);
  const [mi, setMI] = useState(0)
  const [op, setOP] = useState(0)
  const [nz, setNZ] = useState(0)
  const [ps, setPS] = useState(0)
  const [sumofallvalues, setSumOFAll] = useState(0)

  const [isCPLoadingfy, setIsCPLoadingFY] = useState(false);
  const [customerListfy, setCustomerListFY] = useState([]);
  const [sortResultFY, setSortResultFY] = useState([]);
  const [clickedValue, setclickValue]=useState();
  

  const [openFY, setOpenFY] = React.useState(false);

  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(PurchaseYTDDATA(customerdataList)).unwrap();
    setIsCPLoading(false);

  }


  useEffect(() => {
    if (apiData.status === 'succeeded') {
      if (apiData?.purchaseytdData) {
        if (apiData.purchaseytdData === undefined) {
          if (apiData.purchaseytdData.fs_DATABROWSE_V43121B == undefined || apiData.purchaseytdData.fs_DATABROWSE_V43121B == null) {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        } else {
          if (minutes != 60) {
            if (apiData.purchaseytdData.fs_DATABROWSE_V43121B!= null || apiData.purchaseytdData.fs_DATABROWSE_V43121B!= undefined) {
              const data = apiData.purchaseytdData.fs_DATABROWSE_V43121B.data.gridData.rowset;
              setCustomerList(data)
              getTotalamount(data)
            }
            else {
              (async () => {
                const defaultData = await getCustomerList();
              })()
            }
          }
          else {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        }
      }
      else{
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }else{
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }, [apiData]);


  const sortIcon = <ArrowDownWard />;

  const customerdataList = {
    "token": token,
    "targetName": "V43121B",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "100000",
    "returnControlIDs" : "F43121.UREC|F43121.AREC|F43121.DCTO|F43121.MATC",
    "query": {
      "autoFind": true,
      "condition":
        [{
          "value":
            [{
              "content": subtractDate,//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F43121.RCDJ",//reciept date
          "operator": "GREATER"
        },
        {
          "value":
            [{
              "content": nextDate,//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F43121.RCDJ",//reciept date
          "operator": "LESS"
        },
        {
          "value":
            [{
              "content": "1",//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F43121.MATC",//reciept date
          "operator": "EQUAL"
        },
          //  {
          //   "value" : 
          //   [ {
          //     "content" : "400",//reciept date
          //     "specialValueId" : "LITERAL"
          //     } 
          //   ],
          //   "controlId" : "F43121.LTTR",//reciept date
          //   "operator" : "EQUAL"
          //  }
        ]
    }
  }

  const labels = [
    `Imports: ${mi.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Local: ${op.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Non Stock: ${nz.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Services: ${ps.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
  ];
  const options = {
    chart: {
      type: 'pie',
      events: {
        dataPointSelection: (event, chartContext, config) => { 
          onGraphclick(config.dataPointIndex)
          // console.log(config.w.config.labels[config.dataPointIndex])
        }
        }
    },
    labels: labels,
    colors: [
      'rgba(255, 206, 86, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(255, 99, 132, 0.8)',
      
    ], // Colors in hexadecimal format
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  const series = [
    parseFloat(mi.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(op.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(nz.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(ps.toString().replace(/[^0-9.-]+/g, '')),
  ];

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V43121B.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }


  const getTotalamount = (data_save) => {
    var result = [];
    var miValue = 0;
    var opValue = 0;
    var nzValue = 0;
    var psValue = 0;
    data_save.reduce(function (res, value) {
      if (!res[value.F43121_DCTO]) {
        res[value.F43121_DCTO] = { F43121_DCTO: value.F43121_DCTO, F43121_AREC: 0 };
        result.push(res[value.F43121_DCTO])
      }
      res[value.F43121_DCTO].F43121_AREC += value.F43121_AREC;
      return res;
    }, {});

    setSortResult(result)
    result.map((values) => {
      if (values.F43121_DCTO === "MI") {
        const value = values.F43121_AREC
        miValue = value
        setMI(value)
      }
      if (values.F43121_DCTO === "OP") {
        const value2 = values.F43121_AREC
        opValue = value2
        setOP(value2)
      }
      if (values.F43121_DCTO === "NZ") {
        const value3 = values.F43121_AREC
        nzValue = value3
        setNZ(value3)
      }
      if (values.F43121_DCTO === "PS") {
        const value4 = values.F43121_AREC
        psValue = value4
        setPS(value4)
      }

    })

    let summation = (miValue + opValue + nzValue + psValue)
    let summation2 = summation.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    setSumOFAll(summation2)
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const onGraphclick = async (elements, chart) => {
    let clickedValue = elements;
    if (clickedValue === 0 ) {
      setclickValue("Imports")
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
    "targetName": "V5543121",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F43121.RCDJ|F43121.DOC|F43121.DOCO|F43121.DCTO|F43121.AN8|F0101.ALPH|F43121.LITM|F4101.DSC1|F4101.DSC2|F43121.UREC|F43121.PRRC|F43121.AREC",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value" : 
            [ {
              "content" : subtractDate,//reciept date
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F43121.RCDJ",//reciept date
            "operator" : "GREATER"
           },
           {
            "value" : 
            [ {
              "content" : nextDate,//reciept date
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F43121.RCDJ",//reciept date
            "operator" : "LESS"
           },
           {
            "value" : 
            [ {
              "content" : "1",//reciept date
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F43121.MATC",//reciept date
            "operator" : "EQUAL"
           },
           {
            "value" : 
            [ {
              "content" : "mi",//reciept date
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F43121.DCTO",//reciept date
            "operator" : "EQUAL"
           },

        ]
    }
        },
        config)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V5543121.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
            // getTotalamountDB(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
    if (clickedValue === 1 ) {
      setclickValue("Local")
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
      {
        "token": token,
  "targetName": "V5543121",
  "targetType": "view",
  "dataServiceType": "BROWSE",
  "maxPageSize": "1000000",
  "returnControlIDs": "F43121.RCDJ|F43121.DOC|F43121.DOCO|F43121.DCTO|F43121.AN8|F0101.ALPH|F43121.LITM|F4101.DSC1|F4101.DSC2|F43121.UREC|F43121.PRRC|F43121.AREC",
  "query": {
    "autoFind": true,
    "condition":
      [
        {
          "value" : 
          [ {
            "content" : subtractDate,//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.RCDJ",//reciept date
          "operator" : "GREATER"
         },
         {
          "value" : 
          [ {
            "content" : nextDate,//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.RCDJ",//reciept date
          "operator" : "LESS"
         },
         {
          "value" : 
          [ {
            "content" : "1",//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.MATC",//reciept date
          "operator" : "EQUAL"
         },
         {
          "value" : 
          [ {
            "content" : "op",//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.DCTO",//reciept date
          "operator" : "EQUAL"
         },

      ]
  }
      },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V5543121.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
    if (clickedValue === 2 ) {
      setclickValue("Non Stock")
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
      {
        "token": token,
  "targetName": "V5543121",
  "targetType": "view",
  "dataServiceType": "BROWSE",
  "maxPageSize": "1000000",
  "returnControlIDs": "F43121.RCDJ|F43121.DOC|F43121.DOCO|F43121.DCTO|F43121.AN8|F0101.ALPH|F43121.LITM|F4101.DSC1|F43121.UREC|F43121.PRRC|F43121.AREC|F4101.DSC2",
  "query": {
    "autoFind": true,
    "condition":
      [
        {
          "value" : 
          [ {
            "content" : subtractDate,//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.RCDJ",//reciept date
          "operator" : "GREATER"
         },
         {
          "value" : 
          [ {
            "content" : nextDate,//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.RCDJ",//reciept date
          "operator" : "LESS"
         },
         {
          "value" : 
          [ {
            "content" : "1",//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.MATC",//reciept date
          "operator" : "EQUAL"
         },
         {
          "value" : 
          [ {
            "content" : "nz",//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.DCTO",//reciept date
          "operator" : "EQUAL"
         },

      ]
  }
      },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V5543121.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
    if (clickedValue === 3 ) {
      setclickValue("Services")
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
      {
        "token": token,
  "targetName": "V5543121",
  "targetType": "view",
  "dataServiceType": "BROWSE",
  "maxPageSize": "1000000",
  "returnControlIDs": "F43121.RCDJ|F43121.DOC|F43121.DOCO|F43121.DCTO|F43121.AN8|F0101.ALPH|F43121.LITM|F4101.DSC1|F43121.UREC|F43121.PRRC|F43121.AREC|F4101.DSC2",
  "query": {
    "autoFind": true,
    "condition":
      [
        {
          "value" : 
          [ {
            "content" : subtractDate,//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.RCDJ",//reciept date
          "operator" : "GREATER"
         },
         {
          "value" : 
          [ {
            "content" : nextDate,//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.RCDJ",//reciept date
          "operator" : "LESS"
         },
         {
          "value" : 
          [ {
            "content" : "1",//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.MATC",//reciept date
          "operator" : "EQUAL"
         },
         {
          "value" : 
          [ {
            "content" : "ps",//reciept date
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F43121.DCTO",//reciept date
          "operator" : "EQUAL"
         },

      ]
  }
      },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V5543121.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
  }

  const columnsLable = [
    {
      id: 'RCDJ',
      name: 'OV Date',
      sortable: true,
      selector: (row) => <div>{moment(row.F43121_RCDJ).format('DD/MM/YYYY')}</div>,
      cell: (row) => <div>{row.F43121_RCDJ}</div>,
    },
    {
      id: 'DOC',
      name: 'OV Date',
      sortable: true,
      selector: (row) => <div>{moment(row.F43121_DOC).format('DD/MM/YYYY')}</div>,
      cell: (row) => <div>{row.F43121_DOC}</div>,
    },
    {
      id: 'DOCO',
      name: 'PO No.',
      sortable: true,
      selector: (row) => <div>{row.F43121_DOCO}</div>,
      cell: (row) => <div>{row.F43121_DOCO}</div>,
    },
    {
      id: 'DCTO',
      name: 'PO Type',
      sortable: true,
      selector: (row) => <div>{row.F43121_DCTO}</div>,
      cell: (row) => <div>{row.F43121_DCTO}</div>,
    },
    
    {
      id: 'an8',
      name: 'Supplier NO.',
      sortable: true,
      selector: (row) => <div>{row.F43121_AN8}</div>,
      cell: (row) => <div>{row.F43121_AN8}</div>,
    },
    {
      id: 'alph',
      name: 'Supplier Name',
      sortable: true,
      selector: (row) => <div>{row.F0101_ALPH}</div>,
      cell: (row) => <div>{row.F0101_ALPH}</div>,
    },
    {
      id: 'litm',
      name: 'Item Number',
      sortable: true,
      selector: (row) => <div>{row.F43121_LITM}</div>,
      cell: (row) => <div>{row.F43121_LITM}</div>,
    },
    {
      id: 'dsc1',
      name: 'Item Description',
      sortable: true,
      selector: (row) => <div>{row.F4101_DSC1}</div>,
      cell: (row) => <div>{row.F4101_DSC1}</div>,
    },
    {
      id: 'dsc2',
      name: 'Item Description2',
      sortable: true,
      selector: (row) => <div>{row.F4101_DSC2}</div>,
      cell: (row) => <div>{row.F4101_DSC2}</div>,
    },
    {
      id: 'UREC',
      name: 'Qty',
      sortable: true,
      selector: (row) => <div>{row.F43121_UREC}</div>,
      cell: (row) => <div>{row.F43121_UREC}</div>,
    },
    {
      id: 'PRRC',
      name: 'Unit Price',
      sortable: true,
      selector: (row) => <div>{row.F43121_PRRC}</div>,
      cell: (row) => <div>{row.F43121_PRRC}</div>,
    },
    {
      id: 'AREC',
      name: 'Values',
      sortable: true,
      selector: (row) => <div>{row.F43121_AREC}</div>,
      cell: (row) => <div>{row.F43121_AREC}</div>,
    },
  ]

  const handleCloseFY = () => {
    setOpenFY(false);
  };

  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })()
  // }, []);

  return (
    <div>
       <Dialog
  fullWidth={true}
  fullScreen
  maxWidth={'md'}
  open={openFY}
  onClose={handleCloseFY}
  id="FyModal"
  PaperProps={{
    sx: {
      maxWidth: 'lg',
      maxHeight: 700
    }
  }}
>
  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
    Yearly Purchase Summary - {clickedValue}
  </DialogTitle>
  <IconButton
    aria-label="close"
    onClick={handleCloseFY}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
  <DialogContent dividers>
    <Typography gutterBottom>
      <div >
        <LoadingComponent isLoading={isCPLoadingfy} component={(
          <DataTableExtensions columns={columnsLable}  
          data={customerListfy}
          export={true}
          print={false}
          filterPlaceholder="Search Here"
          exportHeaders={true}
          >
          <DataTable
            className="cm-category-admin-table"
            defaultSortFieldId="orderNumber"
            sortable={true}
            columns={columnsLable}  
            data={customerListfy}
            striped
            pagination
            customStyles={tableCustomStyles}
            expandableRows={false}
            sortIcon={sortIcon}
            fixedHeader={false}
            pointerOnHover={true}
            selectableRowsHighlight={true}
            selectableRowsSingle={true}
            selectableRowsVisibleOnly={true}
            style={{ width: '100px' }}
            overflowY={true}
            progressPending={isCPLoadingfy}
            
            // subHeaderComponent={subHeaderComponentMemo}
            // subHeader={true}
          />
          </DataTableExtensions>
        )} />
      </div>
    </Typography>
  </DialogContent>
</Dialog>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Year to Date Receipt</p>
          </div>
          <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
            <span style={{ color: 'rgb(17 24 39)', fontSize: 22 }}>{sumofallvalues}</span>
          </div>
          <div className="items-center justify-center my-5 m-0"style={{ width: '100%', height: '100%', overflow: 'hidden'}}>            
              <ReactApexChart options={options} series={series} type="pie" width={350} height={290} />
            </div>
        </div>
      )} />
    </div>

  )
}