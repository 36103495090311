import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { setProfileState } from '../redux/features/authStateSlice';
import TopBarcomponent from './TopBarcomponent';

import Sidenav from './sidenav';
function Pageoutlay (props) {
  const dispatch = useDispatch()
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const Profiledata = useSelector((state) => state.auth.profiledata);
  const userData = useSelector((state) => state.auth.data);


  useEffect(() => {
    setSidebarOpen(false);
    dispatch(setProfileState(userData.data.userInfo));
  }, [location])
  return (
    <>
      <div className="grid min-h-screen grid-rows-header bg-white">
        <div className="grid md:grid-cols-sidebar  min-h-screen">
          <Sidenav  open={!sidebarOpen} setOpen={setSidebarOpen} />
          <div className='p-3 relative overflow-auto bg-grey mt-[60px]'>
            <TopBarcomponent profile={Profiledata}  onMenuButtonClick={() => setSidebarOpen((prev) => !prev)} />
            <Outlet />
            <div className='bg-grey py-4 border-t-2 fixed left-0 right-0 bottom-0 cm-logged-ftr'>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pageoutlay