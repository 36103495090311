import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Chart, registerables } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { removeAuthState } from '../../redux/features/authStateSlice';
import AxiosMain from '../../utilits/const';
import CurrentMonthSales from '../sales/currentMonthSales';
import DailySales from '../sales/dailySales';
import GrossProfitMTD from '../sales/grossProfitMTD';
import GrossProfitMargin from '../sales/grossProfitMargin';
import TopTenCustomer from '../sales/top10CustomerYTD';
import YearToDateSales from '../sales/yearToDateSales';
import Countrywise from './top10CountryWise';

import AllOrder from './pendingOrder/allOrder';
import TodaysInvoiceValue from './todaysInvoice';
Chart.register(...registerables)


export default function SaleSummaryDashboard() {

  const dispatch = useDispatch();
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token
  const navigate = useNavigate();
  const [isCPLoading, setIsCPLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const validTokenData = {
    "token": token,
  }

  const checktokenValid = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/tokenrequest/validate`,
      validTokenData,
      config)
      .then((res) => {
        if (res.isValidSession == false) {
          Swal.fire({
            title: 'Warning',
            text: 'Token Expired.',
            icon: "warning",
          });
          setIsCPLoading(false);
          dispatch(removeAuthState());
          navigate("/");
        } else {
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
    setIsCPLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checktokenValid();
    }, [3600000]);
    return () => clearInterval(interval);
  }, []);


  const icon = (
    <Paper sx={{ m: 1 }} elevation={4}>
      <Box component="svg" sx={{ width: 100, height: 100 }}>
        <Box
          component="polygon"
          sx={{
            fill: (theme) => theme.palette.common.white,
            stroke: (theme) => theme.palette.divider,
            strokeWidth: 1,
          }}
          points="0,100 50,00, 100,100"
        />
      </Box>
    </Paper>
  );

  return (
    <div>

      <div className="bg-grey py-5">
        <div className='md:w-full  w-full '>
          <div className=" rounded-[10px] min-h-[100px]">
            <div className="cm-admin-category-table-wrapper m-0">

              {/* <div className='flex flex-wrap items-center justify-center my-5 m-0'>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <React.Fragment>
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                      <Tooltip title="Pending Orders">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <Avatar sx={{ width: 42, height: 42, fontSize: 10, fontWeight: 'bold' }}>Pending Order</Avatar>
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu

                      anchorEl={anchorEl}
                      className="horiz-menu"
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                      <MenuItem onClick={handleClose} className='horiz-menu'>
                        <ListItemIcon>
                          <PickSlip />
                        </ListItemIcon>

                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                </div>

              </div> */}


              <div className="bg-grey ">
        <div className='md:w-full w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">


              <div className="grid-container4">
              <div className="grid-item bg-white shadow"><AllOrder /></div>
              <div className=""></div>
                <div className=""></div>
              </div>
            </div>
          </div>
        </div>
      </div>
             

      <div className="grid-container6" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '10px', maxWidth: '100%', margin: '0 auto' }}>
                <div className="grid-item bg-white shadow"><YearToDateSales /></div>
                <div className="grid-item bg-white shadow"><CurrentMonthSales /></div>
                <div className="grid-item bg-white shadow"><DailySales /></div>
              </div>
            </div>
          </div>

        </div>

      </div>


      <div className="bg-grey ">
        <div className='md:w-full mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">


             
            <div className="grid-container6" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '16px', maxWidth: '100%', margin: '0 auto' }}>
                <div className="grid-item bg-white shadow"><TodaysInvoiceValue /></div>
                <div className="grid-item bg-white shadow"><GrossProfitMargin /></div>
                <div className="grid-item bg-white shadow"><GrossProfitMTD /></div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <div className="bg-grey ">
        <div className='md:w-full mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

             
              <div className="grid-container6">
              <div className="grid-item bg-white shadow"> <SalesYTDByDocumt /> </div>
              <div className="grid-item bg-white shadow"> <SalesMTDByDocumt /> </div>
              <div className="grid-item bg-white shadow"> <SalesDailyByDocumt /> </div>
              <div className="grid-item bg-white shadow"> <TodaysInvoiceByDocumt /> </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className="bg-grey ">
        <div className='md:w-full mb-10 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

            <div className="grid-container2" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '10px', maxWidth: '100%', margin: '0 auto' }}>
                <div className="grid-item bg-white shadow"> <TopTenCustomer /> </div>
                <div className="grid-item bg-white shadow"> <Countrywise /> </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}