import { Chart, registerables } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { removeAuthState } from '../../redux/features/authStateSlice';
import AxiosMain from '../../utilits/const';
import NetAr from './netAR/netARAsOnDate/netAr';
import CurrentMonthReceipts from './netAR/receiptMTD/monthlyReceipts';
import ReceiptCashMTD from './netAR/receiptMTD/receiptCash';
import ReceiptDraftMTD from './netAR/receiptMTD/receiptDraft';
import ReceiptCashYTD from './netAR/receiptYTD/receiptCash';
import ReceiptDraftYTD from './netAR/receiptYTD/receiptDraft';
import ReceiptYTD from './netAR/receiptYTD/totalReceiptsYTD';




Chart.register(...registerables)


export default function FinanceAR() {

  const dispatch = useDispatch();
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token
  const navigate = useNavigate();
  const [isCPLoading, setIsCPLoading] = useState(false);


 

  

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const validTokenData ={
    "token": token,
  }
  const checktokenValid = async () =>{
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/tokenrequest/validate`,
    validTokenData,
      config)
      .then((res) => {
        if (res.isValidSession == false) {
          Swal.fire({
            title: 'Warning',
            text: 'Token Expired.',
            icon: "warning",
          });          
          setIsCPLoading(false);
          dispatch(removeAuthState());
          navigate("/");
        }else{
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
      setIsCPLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checktokenValid();
    }, [3600000]);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div className="bg-grey py-1 ">
        <div className='md:w-full mb-3 w-full '>
          <div className="rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'></div>
              {/* <!-- Content Row --> */}
              <div className="grid-container6" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '10px', maxWidth: '100%', margin: '0 auto', overflow:'hidden' }}>
    <div className="grid-item bg-white shadow" ><NetAr /></div>
    <div className="grid-item bg-white shadow" ><ReceiptYTD /></div>
    <div className="grid-item bg-white shadow" ><CurrentMonthReceipts /></div>
    {/* <div className="grid-item bg-white shadow" style={{ width: '100%', boxSizing: 'border-box' }}><DailyReceipts /></div> */}
</div>


              

            </div>
          </div>
        </div>
      </div>


      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
           
                <div className="grid-item bg-white shadow"><NetGraphReciptDetail /></div>
                <div className="grid-item bg-white shadow"><YTDGraphReciptDetail /></div>
                <div className="grid-item bg-white shadow"><MTDGraphReciptDetail /></div>
               
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container6">
           
                <div className="grid-item ">
                  {/* <NetARInv/> */}
                  </div>
                <div className="grid-item bg-white shadow"><ReceiptCashYTD /></div>
                <div className="grid-item bg-white shadow"><ReceiptCashMTD /></div>
                {/* <div className="grid-item bg-white shadow"><MTDGraphReciptDetail /></div> */}
                {/* <div className="grid-item bg-white shadow"><DailyReceipts /></div> */}
               
              </div>
            </div>
          </div>
        </div>
      </div>
       <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container6">
           
            <div className="grid-item">
              {/* <NetARRU/> */}
              </div>
                <div className="grid-item bg-white shadow"><ReceiptDraftYTD/> </div>
                <div className="grid-item bg-white shadow"><ReceiptDraftMTD /></div>
                {/* <div className="grid-item bg-white shadow"><MTDGraphReciptDetail /></div> */}
                {/* <div className="grid-item bg-white shadow"><DailyReceipts /></div> */}
               
              </div>
            </div>
          </div>
        </div>
      </div>

      

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
           
                <div className="grid-item bg-white shadow"><NetARCN/></div>
               
               
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
           
                <div className="grid-item bg-white shadow"><NetARRH/></div>
                
               
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
           
                <div className="grid-item bg-white shadow"><NetARRM/></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
           
                <div className="grid-item bg-white shadow"><NetARRU/></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="bg-grey py-1">
        <div className='md:w-full mb-10 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

             
              <div className="grid-container2">
              <div className="grid-item bg-white shadow"> 
             <NetArGraph />
              </div>
              <div className="grid-item bg-white shadow"> <YTDGraphRecipts/> </div>
             
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
           
                <div ></div>
                <div className="grid-item bg-white shadow"><ReceiptOtherYTD/></div>
                <div className="grid-item bg-white shadow"><ReceiptOtherMTD /></div>
              
               
              </div>
            </div>
          </div>
        </div>
      </div> */}


     
      

      
    </div>
  )
}