import { Chart, registerables } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { removeAuthState } from '../../redux/features/authStateSlice';
import AxiosMain from '../../utilits/const';
import CustBal from './custBal';
import NetAr from './netAR/netARAsOnDate/netAr';
import NetAp from './netAp';
import SuppBal from './suppBal';


Chart.register(...registerables)


export default function FinanceDashboard() {

  const dispatch = useDispatch();
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token
  const navigate = useNavigate();
  const [isCPLoading, setIsCPLoading] = useState(false);


 

  const state1 = {
    labels: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    datasets: [
      {
        label: 'Class Strength',
        backgroundColor: [
          'Indigo',
          'Purple',
          'Yellow',
          'Teal',
          'Red',
          'Navy',
          'Brown',
        ],
        fill: false,
        lineTension: 0.5,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [10, 14, 17, 16, 19, 16, 17],
      },
    ],
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const validTokenData ={
    "token": token,
  }
  const checktokenValid = async () =>{
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/tokenrequest/validate`,
    validTokenData,
      config)
      .then((res) => {
        if (res.isValidSession == false) {
          Swal.fire({
            title: 'Warning',
            text: 'Token Expired.',
            icon: "warning",
          });          
          setIsCPLoading(false);
          dispatch(removeAuthState());
          navigate("/");
        }else{
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
      setIsCPLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checktokenValid();
    }, [3600000]);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div className="bg-grey py-5 ">
        <div className='md:w-full mb-3 w-full '>
          <div className="rounded-[10px] min-h-[300px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'><span style={{ fontSize: 24, fontWeight: 'bold' }}>Financial Indicators</span></div>
              {/* <!-- Content Row --> */}
              <div className="grid-container3">
                <div className="grid-item bg-white shadow"><NetAr /></div>
                <div className="grid-item bg-white shadow"><NetAp /></div>
                <div className="grid-item bg-white shadow"><SuppBal /></div>
                <div className="grid-item bg-white shadow"><CustBal /></div>
               
              </div>

            </div>
          </div>
        </div>
      </div>

      
    </div>
  )
}