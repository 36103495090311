import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../components/LoadingComponent';
import { SalesGrossProfitYTDDATA } from '../../redux/features/homeslice';


export default function GrossProfitMargin() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home.salesgrossprofitytdData);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0)
  const [totalPercentage, setTotalPercentag] = useState(0)
  const [total, setTotal] = useState(0)
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")


  const customerdataList = {
    "token" : token,
    "targetName" : "V554219A",
    "targetType" : "view",
    "dataServiceType" : "BROWSE",
    "maxPageSize":"100000",   
    "returnControlIDs" : "F42119.AEXP|F42119.SOQS|F42119.DOCO|F42119.LNID|F42119.ECST",
    "query" : {
      "autoFind" : true,
      "condition" : 
      [ 
        {
          "value" : 
          [ {
            "content" : "580",
            "specialValueId" : "LITERAL"
            } 
          ],
          "controlId" : "F42119.NXTR",
          "operator" : "GREATER"
        },
      // {
      //   "value" : 
      //   [ {
      //     "content" : "23",//last 2 digits of curretnt Year
      //     "specialValueId" : "LITERAL"
      //     } 
      //   ],
      //   "controlId" : "F42119.FY",
      //   "operator" : "EQUAL"
      // },
      {
        "value" : 
        [ {
          "content" : subtractDate,//date less than 1 date example today's date is 15/08/2023 then it should calculate //last month's last date
          "specialValueId" : "LITERAL"
          } 
        ],
        "controlId" : "F42119.IVD",
        "operator" : "GREATER"
      },
      {
        "value" : 
        [ {
          "content" : "0",//last 2 digits of curretnt Year
          "specialValueId" : "LITERAL"
          } 
        ],
        "controlId" : "F42119.LNID",
        "operator" : "GREATER"
      },
      {
        "value" : 
        [ {
          "content" : nextDate,//date greater than 1 date for today's date plus 1 date
          "specialValueId" : "LITERAL"
          } 
        ],
        "controlId" : "F42119.IVD",
        "operator" : "LESS"
      }
      ]
    }
  }

  const dispatch = useDispatch();
  const [minutes, setMinutes] = useState(0);


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(SalesGrossProfitYTDDATA(customerdataList)).unwrap();
    setIsCPLoading(false);
    
  }
  
  
  useEffect(() => {
    if (apiData === undefined) {
      if (apiData.fs_DATABROWSE_V554219A == undefined || apiData.fs_DATABROWSE_V554219A == null) {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    } else {
      if (minutes != 60) {
        if (apiData.fs_DATABROWSE_V554219A != null) {
          const data = apiData.fs_DATABROWSE_V554219A.data.gridData.rowset;
          setCustomerList(data)
          getTotalamount(data)
        }
        else {
          (async () => {
            const defaultData = await getCustomerList();
          })()
        }
      }
      else {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }
  }, [apiData]);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V554219A.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }


  const getTotalamount = (data_save) => {
    let settotalPrice = (data_save.reduce((a, v) => a = a + v.F42119_AEXP, 0))
    setTotalPrice(settotalPrice.toFixed(2))

    let settotalCost = (data_save.reduce((a, v) => a = a + v.F42119_ECST, 0))
    setTotalCost(settotalCost.toFixed(2))

    let profit = settotalPrice - settotalCost
    const formattedAmount = profit.toLocaleString('en-US', { style: 'currency', currency: 'INR' }); 
    setTotalProfit(formattedAmount)

    let percentage = (profit / settotalCost) * 100
    setTotalPercentag(percentage.toFixed(2))
  }

  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })() 
  // }, []);

  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-5/5 max-w-md mb-0'>
          <p className="text-gray-500 fontSmaller font-bold leading-loose">Gross Profit YTD
          
          
          <div className="grid-container-2">
          <div className="grid-item2 ml-[0.75rem] fontSmaller"> <p style={{ color: 'rgb(17 24 39)' }}>{totalProfit} </p> </div> 
          <div className="grid-item2 fontSmaller"><p style={{ color: 'rgb(17 24 39)',borderLeft: '1px solid #2A5273;' }}>&#40;{totalPercentage}%&#41;</p></div>
          </div>
          </p>
          </div>
          <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
            
            {/* <div className="grid-container">
              <div className="grid-item2"> <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Total Price</p> <p style={{ color: 'rgb(17 24 39)', fontSize: 18 }}>{totalPrice}</p></div>
              <div className="grid-item2"> <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Total Cost</p> <p style={{ color: 'rgb(17 24 39)', fontSize: 18 }}>{totalCost}</p></div>
              <div className="grid-item2"> <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Total Profit</p> <p style={{ color: 'rgb(17 24 39)', fontSize: 18 }}>{totalProfit}</p></div>
            </div> */}
          </div>
        </div>
      )} />
    </div>

  )
}