import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../components/LoadingComponent';
import { NetAPDailyPaymentData } from '../../redux/features/homeslice';



export default function PaymentDaily() {
const userauth = useSelector((state) => state.auth);
const apiData = useSelector((state) => state.home.netapidailyPaymentData);
const [isCPLoading, setIsCPLoading] = useState(false);
const token = userauth.data.data.userInfo.token
const [customerList, setCustomerList] = useState([]);
const [total, setTotal] = useState(0)
const curentYear = moment().year();;
const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
const subtractDate = moment().subtract(1, 'days').format('DD/MM/YYYY');

const customerdataList = {
  "token" : token,
  "targetName" : "V0413B",
  "targetType" : "view",
  "dataServiceType" : "BROWSE",  
  "maxPageSize" : "100000",
  "returnControlIDs" : "F0413.PAAP",
  "query" : {
    "autoFind" : true,
    "condition" : 
    [ 
    {
      "value" : 
      [ {
        "content" : subtractDate,
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F0413.DMTJ",
      "operator" : "GREATER"
    },
    {
      "value" : 
      [ {
        "content" : nextDate,
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F0413.DMTJ",
      "operator" : "LESS"
    }
   ]
  }
}

const config = {
  headers: {
    "Content-Type": "application/json",
  },
}

// const getCustomerList = async () => {
//   setIsCPLoading(true);
//   await AxiosMain.post(`/jderest/v2/dataservice`,
//     customerdataList,
//     config)  
//     .then((res) => {
//       if (res.status === 200) {
//         let data_save = res.data.fs_DATABROWSE_V0413B.data.gridData.rowset;
//         setCustomerList(data_save)
//         setIsCPLoading(false);
//         getTotalamount(data_save)
//       }
//     }).catch((e) => {
//       Swal.fire({
//         title: 'Warning',
//         text: e,
//         icon: "warning",
//       })
//       console.log("Error While getting Kit user", e);
//       setIsCPLoading(false);
//     })
// }

const getCustomerList = async () => {
  setIsCPLoading(true);
  await dispatch(NetAPDailyPaymentData(customerdataList)).unwrap();
  setIsCPLoading(false);
}

useEffect(() => {
  if (apiData === undefined) {
    if (apiData.fs_DATABROWSE_V0413B == undefined || apiData.fs_DATABROWSE_V0413B == null) {
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
    
  } else {
    if (minutes != 60) {
      if (apiData.fs_DATABROWSE_V0413B != null) {
        const data = apiData.fs_DATABROWSE_V0413B.data.gridData.rowset;
        setCustomerList(data)
        getTotalamount(data)
      }
      else {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }
    else {
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }
}, [apiData]);


const getTotalamount = (data_save) =>{
  let settotal = (data_save.reduce((a,v) =>  a = a + v.F0413_PAAP , 0 ))
  const formattedAmount = settotal.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
  setTotal(formattedAmount)
}

// useEffect(() => {
//   (async () => {
//     const defaultData = await getCustomerList();
//   })()  
// },[]);



const [minutes, setMinutes] = useState(0);

const updateMinutes = (newMinutes) => {
  setMinutes(newMinutes);
  localStorage.setItem('minutes', newMinutes.toString());
};


useEffect(() => {
  // Function to be executed every minute
  const updateEveryMinute = () => {
    // Update minutes
    updateMinutes(minutes + 1);
  };

  // Set up an interval to call the function every minute
  const intervalId = setInterval(updateEveryMinute, 36000000);

  // Cleanup function to clear the interval when the component is unmounted
  return () => clearInterval(intervalId);
}, [minutes]);



const dispatch = useDispatch();



// useEffect(() => {
//   const interval = setInterval(() => {
//     getCustomerList();
//   }, [1000]);
//   return () => clearInterval(interval);
// }, []);

return (
  <div >
    <LoadingComponent isLoading={isCPLoading} component={(
              <div className="flex flex-wrap items-center justify-center my-5 m-0">
              <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Payment Daily</p>
              </div>
              <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
              <span style={{color:'rgb(17 24 39)',fontSize:22}}>{total}</span>
              </div>
            </div>
            )} />
  </div>
  
)
}