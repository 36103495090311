import classNames from 'classnames';
import { Sidebar } from 'flowbite-react';
import React, { createRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { personalSidebarRoutes } from '../Routes/PerosnalRoute';
import logoi from '../assets/images/CyretLogo.png';


export default function Sidenav({ open, setOpen }) {

  const ref = createRef(null);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(1);

  const [sidebarLinks, setSidebarLinks] = useState([]);
  useOnClickOutside(ref, (e) => {
    setOpen(false);
  });

  useEffect(() => {

    setSidebarLinks(personalSidebarRoutes);

  }, [])

  const goToPage = (item,index) => {
    setActiveIndex(item.id);
    navigate(item.link)
  }
  return (<>
    <div
      className={classNames({
        "flex flex-col justify-between py-3 bg-[#212529]": true, // layout
        "text-zinc-50 py-3 ": true, // colors
        "md:w-full md:sticky md:top-16 md:z-0 top-0 z-20 fixed ": true, // positioning
        "md:h-[calc(100vh - 95px)] h-full w-[300px] ": true, // for height and width
        "transition-transform .3s ease-in-out md:-translate-x-0 ": true, //animations
        "-translate-x-full": !open, //hide sidebar to the left when closed
      })}
      ref={ref}
      style={{ borderRight: '3px solid #E36613' }}
    >
      <div className=' md:flex md:flex-col'>
        <img className='w-auto h-[100px] object-cover mx-auto cursor-pointer mb-3' src={logoi} alt='logo' onClick={() => navigate("/")} />
        {/* <div className='sidebar-heading text-left'>{currentUserType}</div> */}
        <Sidebar aria-label="Sidebar with multi-level dropdown" className='main-sidenav-bar-wrp'>
          <Sidebar.Items className='hello'>
            <Sidebar.ItemGroup className='main-grppp'>
              {
                sidebarLinks.length > 0 && sidebarLinks.map((item, index) => (

                  item.child.length > 0
                    ?
                    <Sidebar.Collapse label={item.label} key={`ADMIN-lin-collapse-${index}`} className="cm-admin-multi-menu" icon={() => item.icon}>
                      {
                        item.child.map((innerItem, innerIndex) => (
                          console.log('item',innerIndex,innerItem)
                          // <Sidebar.Item icon={() => innerItem.icon} key={`ADMIN-lin-${innerIndex}`} className='cm-menu-link p-0 cm-admin-sidebar-link' onClick={() => navigate(innerItem.link)}><div className='text-white hover:text-[#333333] p-2 flex'>{innerItem.label}</div></Sidebar.Item>
                        ))
                      }
                    </Sidebar.Collapse>
                    :
                    <Sidebar.Item key={`ADMIN-lin-${index}`} className='cm-menu-link p-0 cm-admin-sidebar-link admin-no-child ' active={item.id === activeIndex} onClick={(index) =>{goToPage(item,index)} }><div className={`${item.id === activeIndex ? "text-blue-800 hover:text-[#333333] p-2 flex": "text-white hover:text-[#333333] p-2 flex"}`}>{item.icon}<div className='pl-3'>{item.label}</div></div></Sidebar.Item>
                ))
              }
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>
    </div></>)
}