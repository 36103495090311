import { Chart } from "chart.js";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../../../components/LoadingComponent';
import AxiosMain from '../../../../utilits/const';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


import ArrowDownWard from '@mui/icons-material/ArrowDownwardOutlined';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';

import { tableCustomStyles } from "../../../TableStyle";

import 'react-data-table-component-extensions/dist/index.css';

import "chartjs-plugin-datalabels";

import { NetARASONDATE } from '../../../../redux/features/homeslice';


export default function NetAr() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home.netarasondDateData);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const [isCPLoadingfy, setIsCPLoadingFY] = useState(false);
  const [isCPLoadingfyClicked, setIsCPLoadingFYClicked] = useState(false);
  const [isCPLoadingfyClickedMonth, setIsCPLoadingFYClickedMonth] = useState(false);

  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [customerListfy, setCustomerListFY] = useState([]);
  const [customerListfyclicked, setCustomerListFYClicked] = useState([]);
  const [customerListfyclickedMonth, setCustomerListFYClickedMonth] = useState([]);

  const [total, setTotal] = useState(0)
  const curentDate = moment().format("DD/MM/YYYY");
  const lastDate2 = moment().startOf('year').format('DD/MM/YYYY');
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const subtractDate2 = moment(lastDate2).subtract(1, "days").format("DD/MM/YYYY")
  const [ru, setRU] = useState(0)
  const [rm, setRM] = useState(0)
  const [rh, setRH] = useState(0)
  const [cn, setCN] = useState(0)
  const [ri, setRI] = useState(0)
  const [av, setAV] = useState(0)
  const [sumofallvalues, setSumOFAll] = useState(0)
  const [sortResult, setSortResult] = useState([]);
  const [sortResultFY, setSortResultFY] = useState([]);
  const [sortResultFYClicked, setSortResultFYClicked] = useState([]);
  const [openFY, setOpenFY] = React.useState(false);
  const [openFYClicked, setOpenFYClicked] = React.useState(false);

  const [sortResultFYClickedMonth, setSortResultFYClickedMonth] = useState([]);
  const [openFYClickedMonth, setOpenFYClickedMonth] = React.useState(false);


  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();

  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);

  Chart.register(ChartDataLabels);

  const customerdataList = {
    "token": token,
    "targetName": "V03B11B",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B11.AAP|F03B11.DCT",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "0", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B11.POST",
            "operator": "NOT_EQUAL"
          },          
        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B11.AAP",
        "direction": "DESC"
      },
      ]
    }
  } 

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V03B11B.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)

  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }

  useEffect(() => {
    if (apiData === undefined) {
      if (apiData.fs_DATABROWSE_V03B11B == undefined || apiData.fs_DATABROWSE_V03B11B == null) {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    } else {
      if (minutes != 60) {
        if (apiData.fs_DATABROWSE_V03B11B != null) {
          const data = apiData.fs_DATABROWSE_V03B11B.data.gridData.rowset;
          setCustomerList(data)
          getTotalamount(data)
        }
        else {
          (async () => {
            const defaultData = await getCustomerList();
          })()
        }
      }
      else {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }
  }, [apiData]);

  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(NetARASONDATE(customerdataList)).unwrap();
    setIsCPLoading(false);

  }

  const getTotalamount = (data_save) => {
    var result = [];
    var ruValue = 0;
    var rmValue = 0;
    var rhValue = 0;
    var cnValue = 0;
    var riValue = 0;
    var avValue = 0;
    var r1Value = 0;
    data_save.reduce(function (res, value) {
      
      if (!res[value.F03B11_DCT]) {
        if(res[value.F03B11_DCT] !== "R1"){
        res[value.F03B11_DCT] = { F03B11_DCT: value.F03B11_DCT, F03B11_AAP: 0 };
        result.push(res[value.F03B11_DCT])
      }
    }
      res[value.F03B11_DCT].F03B11_AAP += value.F03B11_AAP;
      return res;
    }, {});
    const items = result.filter(item => item.F03B11_DCT !== "R1");
    setSortResult(items)
    result.map((values) => {
      if (values.F03B11_DCT === "RU") {
      
        const value = values.F03B11_AAP
        ruValue = value;
        setRU(value)
        
      }
      if (values.F03B11_DCT === "RM") {
        
          const value = values.F03B11_AAP 
          rmValue = value;
          setRM(value)
        
      }
      if (values.F03B11_DCT === "RH") {
        const value = values.F03B11_AAP
        rhValue = value;
        setRH(value)
      }
      if (values.F03B11_DCT === "CN") {
        const value = values.F03B11_AAP
        cnValue = value;
        setCN(value)
      }
      if (values.F03B11_DCT === "RI") {
        const value = values.F03B11_AAP
        riValue = value;
        setRI(value)
      }
      if (values.F03B11_DCT === "AV") {
        const value = values.F03B11_AAP
        avValue = value;
        setAV(value)
      }
      // if (values.F03B11_DCT === "R1") {
      //   const value = values.F03B11_AAP
      //   r1Value = value;
      //   setR1(value)
      // }
    })
    let summation = (ruValue + rmValue + rhValue + cnValue + riValue + avValue)
    let summation2 = summation.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    setSumOFAll(summation2)
  }

  const handleCloseFYClicked = () => {
    setOpenFYClicked(false);
  };

  const handleCloseFYClickedMonth = () => {
    setOpenFYClickedMonth(false);
  };
 

  const state = {
    labels: sortResult.map(o => o.F03B11_DCT),
    // labels: sortResult.map(o => o.F03B11_DCT),
    datasets: [
      {
        label: ['AR'],
        // backgroundColor: '#214B6C',
        // [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        //   'rgba(255, 205, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(201, 203, 207, 0.2)'
        // ],
        borderColor: [
          'rgb(237, 28, 36)',
          'rgb(247, 191, 71)',
          'rgb(27, 185, 52)',
          'rgb(51, 174, 154)',
          'rgb(29, 183, 255)',
          'rgb(202, 143, 255)',
          'rgb(201, 203, 207)'
        ],
        backgroundColor: [
          'rgba(153, 102, 255, 0.8)',
          
          'rgba(255, 99, 132, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(201, 203, 207, 0.8)',
        ],
        borderColor: '#fff',
        data: sortResult.map(o => parseFloat(o.F03B11_AAP).toFixed(2)),
        
      },
    ],
  }

  const handleCloseFY = () => {
    setOpenFY(false);
  };

  const onBarclickFY = async (elements, chart) => {
    let clickedValue = 0;

    if (elements[0]) {
      const i = elements[0].index;
      clickedValue = chart.data.labels[i]
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V553B11",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F03B11.AAP|F03B11.DCT|F03B11.FY|F03B11.AN8",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": clickedValue, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F03B11.DCT",
                  "operator": "EQUAL"
                },
              ]
          },
          "aggregation": {
            "orderBy": [{
              "column": "F03B11.AN8",
              "direction": "DESC"
            },
            ]
          }
        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V553B11.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
            getTotalamountFY(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }

  }

  const getTotalamountFY = (data_save) => {
    var result = [];
    data_save.reduce(function (res, value) {
      if (!res[value.F03B11_FY]) {
        res[value.F03B11_FY] = { F03B11_FY: value.F03B11_FY, F03B11_AAP: 0,F03B11_DCT:value.F03B11_DCT};
        result.push(res[value.F03B11_FY])
      }
      res[value.F03B11_FY].F03B11_AAP += value.F03B11_AAP;
      return res;
    }, {});
    setSortResultFY(result)
    result.map((values) => {
      if (values.F03B11_DCT === "RI") {
        const value = values.F03B11_AAP.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setRI(value)
      }

    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const stateFY = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: sortResultFY.map(o => o.F03B11_FY + " " + o.F03B11_DCT),
    datasets: [
      {
        label: 'By Year',
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
         
        ],

        // borderWidth: 1,
        borderColor: '#fff',
        data: sortResultFY.map(o => parseFloat(o.F03B11_AAP).toFixed(2)),
      },
    ],
  }

  const stateFYClicked = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: sortResultFYClicked.map(o => o.F03B11_FY + " " + o.F03B11_PN + " " +o.F03B11_DCT),
    datasets: [
      {
        label: 'By Month',
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
         
        ],

        // borderWidth: 1,
        borderColor: '#fff',
        data: sortResultFYClicked.map(o => parseFloat(o.F03B11_AAP).toFixed(2)),
      },
    ],
  }


  const   onBarclicked = async (elements, chart) => {
    let clickedValue = 0;

    if (elements[0]) {
      const i = elements[0].index;
      clickedValue = chart.data.labels[i]
      const splited_value  = clickedValue.split(" ")
      
      const clickedValue_0 = splited_value[0]
      const clickedValue_1 = splited_value[1]
      setIsCPLoadingFYClicked(true);
      setOpenFYClicked(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V553B11",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F03B11.AAP|F03B11.DCT|F03B11.FY|F03B11.AN8|F03B11.PN",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": clickedValue_1, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F03B11.DCT",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": clickedValue_0, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F03B11.FY",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": "0", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F03B11.POST",
                  "operator": "NOT_EQUAL"
                },


              ]
          },
          "aggregation": {
            "orderBy": [{
              "column": "F03B11.AN8",
              "direction": "DESC"
            },
            ]
          }
        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V553B11.data.gridData.rowset;
            setCustomerListFYClicked(data_save)
            setIsCPLoadingFYClicked(false);
            getTotalamountFYClicked(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFYClicked(false);
        })
    }

  }

  const   onBarclickedMonth = async (elements, chart) => {
    let clickedValue = 0;
    console.log(elements[0])
    if (elements[0]) {
      const i = elements[0].index;
      clickedValue = chart.data.labels[i]
      const splited_value  = clickedValue.split(" ")
      const clickedValue_0 = splited_value[0]
      const clickedValue_1 = splited_value[1]
      const clickedValue_2 = splited_value[2]
      console.log(clickedValue_0,clickedValue_1,clickedValue_2)
      setIsCPLoadingFYClickedMonth(true);
      setOpenFYClickedMonth(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V553B11",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F03B11.DOC|F03B11.DCT|F03B11.AN8|F0101.ALPH|F03B11.DIVJ|F03B11.ICUT|F03B11.AAP|F03B11.POST|F03B11.FY|F03B11.PN",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": clickedValue_2, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F03B11.DCT",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": clickedValue_0, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F03B11.FY",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": clickedValue_1, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F03B11.PN",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": "0", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F03B11.POST",
                  "operator": "NOT_EQUAL"
                },

              ]
          },
          "aggregation": {
            "orderBy": [{
              "column": "F03B11.AN8",
              "direction": "DESC"
            },
            ]
          }
        },
        config)
        .then((res) => {
          console.log('bar monthly',res)
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V553B11.data.gridData.rowset;
            setCustomerListFYClickedMonth(data_save)
            setIsCPLoadingFYClickedMonth(false);
            // getTotalamountFYClicked(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFYClickedMonth(false);
        })
    }

  }

  const getTotalamountFYClicked = (data_save) => {
    var result = [];
    data_save.reduce(function (res, value) {
      if (!res[value.F03B11_PN]) {
        res[value.F03B11_PN] = { F03B11_FY: value.F03B11_FY, F03B11_AAP: 0,F03B11_DCT:value.F03B11_DCT,F03B11_PN:value.F03B11_PN};
        result.push(res[value.F03B11_PN])
      }
      res[value.F03B11_PN].F03B11_AAP += value.F03B11_AAP;
      return res;
    }, {});
    const  sorted = result.sort((a,b) => b.F03B11_PN - a.F03B11_PN);
    setSortResultFYClicked(sorted)
  }

  const columnsLable = [
    {
      id: 'DOC',
      name: 'Invoice Number',
      sortable: true,
      selector: (row) => row.F03B11_DOC,
      cell: (row) => <div>{row.F03B11_DOC}</div>,
    },
    {
      id: 'DCT',
      name: 'Recepit Type ',
      sortable: true,
      selector: (row) => <div>{row.F03B11_DCT}</div>,
      cell: (row) => <div>{row.F03B11_DCT}</div>,
    },
    {
      id: 'AN8',
      name: 'Customr Number',
      sortable: true,
      cell: (row) => <div>{row.F03B11_AN8}</div>,
      selector: (row) => row.F03B11_AN8,
    },

    {
      id: "ALPH",
      name: "Customer Name",
      sortable: true,
      selector: (row) => row.F03B11_ALPH,
      cell: (row) => <div>{row.F03B11_ALPH}</div>,
    },
    {
      id: "DIVJ",
      name: "Invoice Date",
      sortable: true,
      cell: (row) => <div>{moment(row.F03B11_DIVJ).format('DD/MM/YYYY')}</div>,
      selector: (row) => row.F03B11_DIVJ,
    },
    {
      id: "ICUT",
      name: "Batch Type",
      sortable: true,
      selector: (row) => row.F03B11_ICUT,
      cell: (row) => <div>{row.F03B11_ICUT}</div>,
    },
    {
      id: "AAP",
      name: "Open Amount",
      sortable: true,
      cell: (row) => <div>{row.F03B11_AAP}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F03B11_AAP,
    },
    {
      id: "POST",
      name: "POST Status",
      sortable: true,
      cell: (row) => <div>{row.F03B11_POST}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F03B11_POST,
    },
    {
      id: "FY",
      name: "Year",
      sortable: true,
      cell: (row) => <div>{row.F03B11_FY}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F03B11_FY,
    },
    {
      id: "PN",
      name: "Period",
      sortable: true,
      cell: (row) => <div>{row.F03B11_PN}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F03B11_PN,
    },
  ]

  const sortIcon = <ArrowDownWard />;

  const options = {
    indexAxis: "y",
  scales: {
    x: {
      stacked: true,
      display: false
    },
    y: {
      stacked: true
    }
  },
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom"
      
    },
    title: {
      display: false,
      text: ""
    }
  },
  onClick: (event, elements, chart) => {
    onBarclickFY(elements, chart)
  }
  };

  // useEffect(() => {
  //   getCustomerList();

  // }, []);

  return (
    <div>
      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFY}
        onClose={handleCloseFY}
        id="FyModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Open AR by Year
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFY}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfy} component={(
                <div className='flex flex-wrap items-center justify-center w-5/5 m-0'>
                  <Bar
                    data={stateFY}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: true,
                          text: 'By Year',
                          
                        }
                      },
                      onClick: (event, elements, chart) => {
                        onBarclicked(elements, chart)
                      }
                    }}
                  />
                </div>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFYClicked}
        onClose={handleCloseFYClicked}
        id="FyclickedModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Open AR by Month
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFYClicked}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfyClicked} component={(
                <div className='flex flex-wrap items-center justify-center w-5/5 m-0'>
                <Bar
                  data={stateFYClicked}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'By Month',
                        
                      }
                    },
                    onClick: (event, elements, chart) => {
                      onBarclickedMonth(elements, chart)
                    }
                  }}
                />
              </div>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFYClickedMonth}
        onClose={handleCloseFYClickedMonth}
        id="FyclickedModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modal title
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFYClickedMonth}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfyClickedMonth} component={(
                <DataTableExtensions columns={columnsLable}  
                data={customerListfyclickedMonth}
                export={true}
                print={false}
                filterPlaceholder="Search Here"
                exportHeaders={true}
                >
                <DataTable
                  className="cm-category-admin-table"
                  defaultSortFieldId="orderNumber"
                  sortable={true}
                  columns={columnsLable}  
                  data={customerListfyclickedMonth}
                  striped
                  pagination
                  customStyles={tableCustomStyles}
                  expandableRows={false}
                  sortIcon={sortIcon}
                  fixedHeader={false}
                  pointerOnHover={true}
                  selectableRowsHighlight={true}
                  selectableRowsSingle={true}
                  selectableRowsVisibleOnly={true}
                  style={{ width: '100px' }}
                  overflowY={true}
                  progressPending={isCPLoadingfyClickedMonth}
                  
                  // subHeaderComponent={subHeaderComponentMemo}
                  // subHeader={true}
                />
                </DataTableExtensions>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div>
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-5/5 max-w-auto mb-0'>
            <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Net Account Receivable As on Date</p>
          </div>
          <div className='flex flex-col gap-y-5 w-auto max-w-md mb-0'>
            <span style={{ color: 'rgb(17 24 39)', fontSize: 22 }}>{sumofallvalues}</span>
          </div>
          
        </div>
        <div className="grid-container4">
          <div className='grid-item'>
              
              <Bar

                data={state} 
                options={options}
                plugins={[ChartDataLabels]}
                // options={{
                //   responsive: true,
                //   plugins: {
                //     legend: {
                //       position: 'top',
                //     },
                //     title: {
                //       display: true,
                //       text: 'Top 10 Item By Quantity',
                //       font: {
                //         size: 16,
                        
                //       }
                //     }
                //   },
                // }}
              />
          </div>

        
        </div>

        {/* <div className="items-center justify-center my-5 m-0"style={{ width: '500px', height: '250px', overflow: 'hidden'}}>
             
              <ReactApexChart options={options} series={series} type="pie" width={500} height={400} />
            </div> */}
        
        </div>

      )} />
    </div>

  )
}