import { Chart, registerables } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { removeAuthState } from '../../redux/features/authStateSlice';
import AxiosMain from '../../utilits/const';
import CurrentMonthProduction from './currentMonthProduction';
import DailyProduction from './dailyProduction';
import DailyByItemGroupProduction from './dailybyitemgroup';
import MonthToDateByItemGroupProduction from './mtdbyitemgroup';
import YearToDateProduction from './yearToDateProduction';
import YearToDateByItemGroupProduction from './ytdbyitemgroup';

Chart.register(...registerables)


export default function ProductionSummaryDashboard() {

  const dispatch = useDispatch();
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token
  const navigate = useNavigate();
  const [isCPLoading, setIsCPLoading] = useState(false);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const validTokenData ={
    "token": token,
  }
  const checktokenValid = async () =>{
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/tokenrequest/validate`,
    validTokenData,
      config)
      .then((res) => {
        if (res.isValidSession == false) {
          Swal.fire({
            title: 'Warning',
            text: 'Token Expired.',
            icon: "warning",
          });          
          setIsCPLoading(false);
          dispatch(removeAuthState());
          navigate("/");
        }else{
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
      setIsCPLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checktokenValid();
    }, [3600000]);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div className="bg-grey py-5 px-3">
        <div className='md:w-full mb-3 w-full '>
          <div className="rounded-[10px] ">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-0">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'></div>
              {/* <!-- Content Row --> */}
              <div className="grid-container6">
                <div className="grid-item bg-white shadow"><YearToDateProduction /></div>
                <div className="grid-item bg-white shadow"><CurrentMonthProduction /></div>
                <div className="grid-item bg-white shadow"><DailyProduction/></div>  
               
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="bg-grey px-3">
        <div className='md:w-full mb-3 w-full '>
          <div className="rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              {/* <div className='flex flex-wrap items-center justify-center my-5 m-0'><span style={{ fontSize: 24, fontWeight: 'bold' }}>Graphs</span></div> */}
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '10px', maxWidth: '100%', margin: '0 auto' }}>
              <div className="grid-item bg-white shadow"><YearToDateByItemGroupProduction /></div>
              <div className="grid-item bg-white shadow"><MonthToDateByItemGroupProduction /></div>
              <div className="grid-item bg-white shadow"><DailyByItemGroupProduction /></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-white  px-3">
        <div className='md:w-full mb-3 w-full '>
          <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'><span style={{ fontSize: 24, fontWeight: 'bold' }}>Graphs</span></div>
              <div>
        <Line
          data={state1}
          options={{
            title: {
              display: true,
              text: 'Class Strength',
              fontSize: 20,
            },
            legend: {
              display: true,
              position: 'right',
            },
          }}
        />
      </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}