import { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Login from './pages/Loginpage.jsx';
import LogoutPage from './pages/LogoutPage';
import FinanceAP from './pages/finances/financeAPDashboard';
import FinanceAR from './pages/finances/financeARDashboard';
import FinanceDashboard from './pages/finances/financeDashboard';
import InventorySummaryDashboard from './pages/inventory/inventorySummary';
import OrderDetail from './pages/orderjourney/OrderDetailPage';
import PersonalDashboard from './pages/orderjourney/PersonalDashboard';
import OrderJourneyExport from './pages/orderjourney/orderJourneyExport';
import Pageoutlay from './pages/pageoutlay';
import ProductionSummaryDashboard from './pages/production/productionSummary';
import PurchaseSummary from './pages/purchase/purchaseSummary';
import SalesSummary from './pages/sales/salesSummaryDashboard';

function App() {
  const [isLogggedin, setLoggedin] = useState(false);
  return (
    <div >
      <Router>
        <Routes>
          <Route exact path="/" element={<Login/>} />
          <Route element={<Pageoutlay />}>
          <Route exact path="/dashboard" element={<PersonalDashboard />} />
          <Route exact path="/orderjourneyexport" element={<OrderJourneyExport />} />
          <Route exact path="/logout" element={<LogoutPage />} />
          <Route exact path="/orderdetail" element={<OrderDetail />} />
          <Route exact path="/finance" element={<FinanceDashboard />} />
          <Route exact path="/salesummary" element={<SalesSummary />} />
          <Route exact path="/purchasesummary" element={<PurchaseSummary />} />
          <Route exact path="/productsummary" element={<ProductionSummaryDashboard />} />
          <Route exact path="/inventorysummary" element={<InventorySummaryDashboard />} />
          <Route exact path="/financeAR" element={<FinanceAR />} />
          <Route exact path="/financeAP" element={<FinanceAP />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
