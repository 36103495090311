import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../components/LoadingComponent';
import { SalesTodayDATA } from '../../redux/features/homeslice';


export default function TodaysInvoiceValue() {
  const userauth = useSelector((state) => state.auth);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const apiData = useSelector((state) => state.home.todaySaleData);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [total, setTotal] = useState(0)
  const curentYear = moment().year();;
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate =moment().startOf('month').format('DD/MM/YYYY');
  const today = moment().format('DD/MM/YYYY')

  const endOfMonth   = moment().endOf('month').format('DD/MM/YYYY');
  const subtractDate = moment().subtract(1, 'days').format('DD/MM/YYYY');


  const dispatch = useDispatch();
  const [minutes, setMinutes] = useState(0);


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(SalesTodayDATA(customerdataList)).unwrap();
    setIsCPLoading(false);
    
  }
  
  
  useEffect(() => {
    if (apiData === undefined) {
      if (apiData.fs_DATABROWSE_V4211A == undefined || apiData.fs_DATABROWSE_V4211A == null) {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    } else {
      if (minutes != 60) {
        if (apiData.fs_DATABROWSE_V4211A != null) {
          const data = apiData.fs_DATABROWSE_V4211A.data.gridData.rowset;
          setCustomerList(data)
          getTotalamount(data)
        }
        else {
          (async () => {
            const defaultData = await getCustomerList();
          })()
        }
      }
      else {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }
  }, [apiData]);
  
  const customerdataList = {
    "token" : token,
    "targetName" : "V4211A",
    "targetType" : "view",
    "maxPageSize":"10000", 
    "dataServiceType" : "BROWSE",  
    "returnControlIDs" : "F4211.AEXP|F4211.SOQS|F4211.DOCO|F4211.IVD|F4211.LNID",
    "query" : {
      "autoFind" : true,
      "condition" : 
      [ 
      // {
      //   "value" : 
      //   [ {
      //     "content" : "SO",//from Drop Down list of Order types
      //     "specialValueId" : "LITERAL"
      //     } 
      //   ],
      //   "controlId" : "F4211.DCTO",
      //   "operator" : "EQUAL"
      // },
      {
        "value" : 
        [ {
          "content" : "580",
          "specialValueId" : "LITERAL"
          } 
        ],
        "controlId" : "F4211.LTTR",
        "operator" : "EQUAL"
      },
      {
        "value" : 
        [ {
          "content" : "620",
          "specialValueId" : "LITERAL"
          } 
        ],
        "controlId" : "F4211.NXTR",
        "operator" : "EQUAL"
      },
      {
        "value" : 
        [ {
          "content" : "0",//last 2 digits of curretnt Year
          "specialValueId" : "LITERAL"
          } 
        ],
        "controlId" : "F4211.LNID",
        "operator" : "GREATER"
      },
      {
        "value" : 
        [ {
          "content" : today,//date less than 1 date example today's date is 15/08/2023 then it should calculate //last month's last date
          "specialValueId" : "LITERAL"
          } 
        ],
        "controlId" : "F4211.IVD",
        "operator" : "EQUAL"
      },
      // {
      //   "value" : 
      //   [ {
      //     "content" : nextDate,//date greater than 1 date for today's date plus 1 date
      //     "specialValueId" : "LITERAL"
      //     } 
      //   ],
      //   "controlId" : "F4211.IVD",
      //   "operator" : "LESS"
      // }
      ]
    }
  }
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  
  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save1 = res.data.fs_DATABROWSE_V4211A.data.gridData.rowset;
  //         let data_save = res.data.fs_DATABROWSE_V4211A.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }
  
  
  const getTotalamount = (data_save) =>{
    let settotal = (data_save.reduce((a,v) =>  a = a + v.F4211_AEXP , 0 ))
    const formattedAmount = settotal.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    setTotal(formattedAmount)
  }
  
  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })()     
  // },[]);

return (
  <div>
    <LoadingComponent isLoading={isCPLoading} component={(
              <div className="flex flex-wrap items-center justify-center my-5 m-0">
              <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className="text-gray-500 fontSmaller font-bold leading-loose ">Todays Invoice Value</p>
              </div>
              <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
              <span style={{color:'rgb(17 24 39)',fontSize:22}}>{total}</span>
              </div>
            </div>
            )} />
  </div>
  
)
}