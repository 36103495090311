import ArrowDownWard from '@mui/icons-material/ArrowDownwardOutlined';
import { Button, Label } from "flowbite-react";
import React, { useEffect, useRef, useState } from 'react';
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Select, { createFilter } from 'react-select';
import Swal from 'sweetalert2';
import DefaultLandingImage from '../../assets/images/DefaultLandingImage.png';
import LoadingComponent from '../../components/LoadingComponent';
import { removeAuthState } from '../../redux/features/authStateSlice';
import AxiosMain from '../../utilits/const';
import { tableCustomStyles } from '../TableStyle';


import moment from 'moment';
import OrderDetailPage from "./OrderDetailPage";

export default function OrderJourneyExport() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userauth = useSelector((state) => state.auth);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPostPages, settotalPostPages] = useState(0);
  const [responseData, setResponseData] = useState([]);
  const [searchResponseData, setSearchResponseData] = useState([]);
  const token = userauth.data.data.userInfo.token
  // const [customerName, setCustomerName] = useState('')
  // const [itemDescription, setItemDescription] = useState('')
  // const [jdestatus, setJdeStatus] = useState('')
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [customerList, setCustomerList] = useState([]);
  const [itemDescpList, setItemDescpList] = useState([]);
  const [dropDownValue, setDropDrownValue] = useState();
  // const [soTypeValue, setSOTypeValue] = useState();
  // const [dropDownItemDescpValue, setDropDrownItemDespValue] = useState();
  const [dropDownOrderType, setDropDrownOrderType] = useState();
  const orderType = [
    { value: 'SO', label: 'SO' },
    { value: 'SE', label: 'SE' },
    { value: 'SN', label: 'SN' },
    { value: 'CO', label: 'CO' }
  ]

  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
const lastDate =moment().startOf('year').format('DD/MM/YYYY');
const lastyearDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")
const selectedFomDate = moment(lastyearDate, 'DD/MM/YYYY').toDate();
const todayDate1 = moment(toDate).format('DD/MM/YYYY')
const lastmonthlastdate=moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY')
  const columnsLable = [
    // {
    //   id: 'action',
    //   name: "Action",
    //   selector: row => <Button size="xs" className="mt-1 py-1 w-full h-min-0" onClick={() => navigate('/orderdetail', { state: row })}>View</Button>,
    //   maxWidth: "50px",
    // },
    // {
    //   id: 'orderNumber',
    //   name: 'Order Number',
    //   sortable: true,
    //   selector: row => row.F42199_DOCO,
    //   cell: row => <div>{row.F42199_DOCO}</div>,
    //   width: '1%',
    //   minWidth:'1%',
    // },
    // {
    //   id: 'or_ty',
    //   name: 'Or Ty',
    //   sortable: true,
    //   selector: row => row.F42199_DCTO,
    //   cell: row => <div>{row.F42199_DCTO}</div>,
    //   maxWidth: "10px",
    // },
    // {
    //   id: 'description',
    //   name: 'Description',
    //   sortable: true,
    //   selector: row => row.F42199_DSC1,
    //   cell: row => <div>{row.F42199_DSC1}</div>,
    //   maxWidth: "50px",
    // },
    // {
    //   id: 'address_number',
    //   name: 'Address Number',
    //   sortable: true,
    //   selector: row => row.F42199_AN8,
    //   cell: row => <div>{row.F42199_AN8}</div>,
    //   maxWidth: "50px",
    // },
    // {
    //   id: '2nd _item_number',
    //   name: '2nd Item Number',
    //   sortable: true,
    //   cell: row => <div>{row.F42199_LITM}</div>,
    //   selector: row => row.F42199_LITM,
    //   maxWidth: "50px",
    // },
    // {
    //   id: 'quantity shipped',
    //   name: 'Date Quantity Shipped',
    //   sortable: true,
    //   selector: (row) => row.F42199_DOCO,
    //   cell: (row) => <div>{row.F42199_DOCO}</div>,
    //   maxWidth: "50px",
    // },
    // {
    //   id: 'quantity_ordered',
    //   name: 'Quantity Ordered',
    //   sortable: true,
    //   selector: (row) => row.F42199_DCTO,
    //   cell: (row) => <div>{row.F42199_DCTO}</div>,
    //   maxWidth: "50px",
    // },
    {
      id: 'order_date',
      name: 'Order Date',
      sortable: true,
      cell: (row) => <div>{moment(row.F42199_TRDJ).format('DD/MM/YYYY')}</div>,
      selector: (row) => row.F42199_TRDJ,
      maxWidth: "50px",
    },

    {
      id: "address_number",
      name: "Address Number",
      sortable: true,
      selector: (row) => row.F42199_AN8,
      cell: (row) => <div>{row.F42199_AN8}</div>,
      maxWidth: "50px",
    },
    {
      id: "2nd _item_number",
      name: "2nd Item Number",
      sortable: true,
      cell: (row) => <div>{row.F42199_LITM}</div>,
      selector: (row) => row.F42199_LITM,
      maxWidth: "100px",
    },
    {
      id: "description",
      name: "Description",
      sortable: true,
      selector: (row) => row.F42199_DSC1,
      cell: (row) => <div>{row.F42199_DSC1}</div>,
      maxWidth: "50px",
    },
    {
      id: "quantity_ordered",
      name: "Quantity Ordered",
      sortable: true,
      cell: (row) => <div>{row.F42199_UORG}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F42199_UORG,
      maxWidth: "50px",
    },
    {
      id: "quantity shipped",
      name: "Quantity Shipped",
      sortable: true,
      cell: (row) => <div>{row.F42199_SOQS}</div>,
      selector: (row) => row.F42199_SOQS,
      maxWidth: "50px",
    },


    {
      id: 'unit_price',
      name: 'Unit Price',
      sortable: true,
      cell: row => <div>{row.F42199_UPRC}</div>,
      selector: row => row.F42199_UPRC,
      maxWidth: "50px",
    },
    {
      id: 'extended_price',
      name: 'Extended Price',
      sortable: true,
      cell: (row) => <div>{row.F42199_AEXP}</div>,
      selector: (row) => row.F42199_AEXP,
      maxWidth: "50px",
    },

    {
      id: 'time_of_day',
      name: 'Time Of Day',
      sortable: true,
      cell: row => <div>{row.F42199_TDAY}</div>,
      selector: row => row.F42199_TDAY,
      maxWidth: "50px",
    },


  ]
  // const curentDate = moment(new Date()).format("DD/MM/YYYY");
  const today = new Date()
  const todayDate = moment(today, 'DD/MM/YYYY').toDate()
  // const subtractDate = moment(fromDate).subtract(1, "days").format("DD/MM/YYYY")
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  const data = {
    "token": token,
    "targetName": "V42199A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "100000",
    // "enableNextPageProcessing" : true,
    "returnControlIDs": "F42199.DOCO|F42199.AN8|F42199.UPRC|F42199.AEXP|F42199.UORG|F42199.SOQS|F42199.LITM|F42199.DSC1|F42199.DCTO|F42199.LNID|F42199.TRDJ",
    "query": {
        "autoFind": true,
        "condition": [
            {
                "value": [
                    {
                        "content": "519", //Status 
                        "specialValueId": "LITERAL"
                    }
                ],
                "controlId": "F42199.LTTR",
                "operator": "GREATER"
            },
            {
                "value": [
                    {
                        "content": String(lastmonthlastdate), //Transaction date To Date By default Today's date
                        "specialValueId": "LITERAL"
                    }
                ],
                "controlId": "F42199.TRDJ",
                "operator": "GREATER"
            },
            {
                "value": [
                    {
                        "content": String(nextDate), //Transaction date To Date By default Today's date
                        "specialValueId": "LITERAL"
                    }
                ],
                "controlId": "F42199.TRDJ",
                "operator": "LESS"
            },
            {
                "value": [
                    {
                        "content": "SE", //Transaction date To Date By default Today's date
                        "specialValueId": "LITERAL"
                    }
                ],
                "controlId": "F42199.DCTO",
                "operator": "EQUAL"
            },
            {
                "value": [
                    {
                        "content": "0", //Transaction date To Date By default Today's date
                        "specialValueId": "LITERAL"
                    }
                ],
                "controlId": "F42199.AEXP",
                "operator": "GREATER"
            }
        ]
    }
  }
  const customerdataList = {
    "token": token,
    "targetName": "V0101C",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    // "enableNextPageProcessing" : true,
    "maxPageSize": "10000",
    "returnControlIDs": "F0101.AN8|F0101.ALPH",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "0",//Address Number Sold To
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0101.AN8",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": "CL",//Address Number Sold To
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0101.AT1",
            "operator": "EQUAL"
          },
          // {
          //   "value":
          //     [{
          //       "content": "CF",//Address Number Sold To
          //       "specialValueId": "LITERAL"
          //     }
          //     ],
          //   "controlId": "F0101.AT1",
          //   "operator": "EQUAL"
          // }
        ]
    }
  }
  const getNextData = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      data,
      config)
      .then((res) => {
        if (res.status === 200) {
          setResponseData(res.data.fs_DATABROWSE_V42199A.data.gridData.rowset)
          setIsCPLoading(false);
          
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e); 
        setIsCPLoading(false);
      })
  }

  const ExpandedComponent = ({ data }) => {
    return (
      <div>
        <OrderDetailPage data={data} />
      </div>
    )
  };


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      
      customerdataList,
      config)
      .then(async (res) => {
        if (res.status === 200) {
          setCustomerList(res.data.fs_DATABROWSE_V0101C.data.gridData.rowset)
          setIsCPLoading(false);
          // const defaultData = await getNextData();
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  const renderCustomerList = () => {
    return (customerList.map(data => ({ label: data.F0101_ALPH, value: data.F0101_AN8 })))
  }
  const onChangeFromDate = (newdate) => {
    const new_Date = moment(newdate).format('DD/MM/YYYY')
    setFromDate(new_Date)
    const next100days = moment(new_Date,"DD/MM/YYYY").add(100, 'days')
    const to_Date = next100days.format('DD/MM/YYYY')
    setToDate(to_Date)
  }

  // const renderItemDescpList = () => {
  //   return (itemDescpList.map(data => ({ label: data.F4101_DSC1, value: data.F4101_LITM })))
  // }

  const validTokenData ={
    "token": token,
  }
  const checktokenValid = async () =>{
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/tokenrequest/validate`,
    validTokenData,
      config)
      .then((res) => {
        if (res.isValidSession == false) {
          Swal.fire({
            title: 'Warning',
            text: 'Token Expired.',
            icon: "warning",
          });          
          setIsCPLoading(false);
          dispatch(removeAuthState());
          navigate("/");
        }else{
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
      setIsCPLoading(false);
  }

  useEffect(() => {
    (async () => {
      // const defaultData = await getNextData();
      const custometr = await getCustomerList();
    })()
    // getItemDescpritionList();
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      checktokenValid();
    }, [360000]);
    return () => clearInterval(interval);
  }, []);

  const setCustomValue = (selectedOption) => {
    setDropDrownValue(selectedOption.value)
  }

  // const setItemDespValue = (selectedOption) => {
  //   setDropDrownItemDespValue(selectedOption.value)
  // }

  const setOrdertypeValue = (selectedOption) => {
    setDropDrownOrderType(selectedOption.value)
  }

  const itemDescriptionList = {
    "token": token,
    "targetName": "V4101A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "10000",
    // "enableNextPageProcessing": true,
    "returnControlIDs": "F4101.LITM|F4101.DSC1",
    "query": {
      "autoFind": true,
      "condition": [
        {
          "value": [
            {
              "content": "0",
              "specialValueId": "LITERAL"
            }
          ],
          "controlId": "F4101.ITM",
          "operator": "GREATER"
        }
      ]
    }
  }

  const getItemDescpritionList = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      itemDescriptionList,
      config)
      .then((res) => {
        if (res.status === 200) {
          setItemDescpList(res.data.fs_DATABROWSE_V4101A.data.gridData.rowset)
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  const searchDataConst = {
    "token": token,
    "targetName": "V42199A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "10",
    // "enableNextPageProcessing": true,
    "returnControlIDs": "F42199.DOCO|F42199.AN8|F42199.UPRC|F42199.AEXP|F42199.UORG|F42199.SOQS|F42199.LITM|F42199.DSC1|F42199.DCTO|F42199.LNID|F42199.TRDJ",
    "query": {
      "autoFind": true,
      "condition": [
        {
          "value": [
            {
              "content": "519", //Status 
              "specialValueId": "LITERAL"
            }
          ],
          "controlId": "F42199.LTTR",
          "operator": "GREATER"
        },
        // {
        //   "value": [
        //     {
        //       "content": dropDownItemDescpValue ? dropDownItemDescpValue : "", //Item Number
        //       "specialValueId": "LITERAL"
        //     }
        //   ],
        //   "controlId": "F42199.LITM",
        //   "operator": "EQUAL"
        // },
        {
          "value": [
            {
              "content": todayDate1, //Transaction date To Date By default Today's date
              "specialValueId": "LITERAL"
            }
          ],
          "controlId": "F42199.TRDJ",
          "operator": "LESS"
        },
        {
          "value": [
            {
              "content": lastyearDate, //Transaction date From Date Hardcoded
              "specialValueId": "LITERAL"
            }
          ],
          "controlId": "F42199.TRDJ",
          "operator": "GREATER"
        },
        {
          "value": [
            {
              "content": dropDownValue ? dropDownValue : "0", //Address Number Sold To
              "specialValueId": "LITERAL"
            }
          ],
          "controlId": "F42199.AN8",
          "operator": "EQUAL"
        }
      ]
    }
  }

  const searchData = async () => {
    setIsCPLoading(true);

    await AxiosMain.post(`/jderest/v2/dataservice`,
      searchDataConst,
      config)
      .then((res) => {
        if (res.status === 200) {
          setSearchResponseData(res.data.fs_DATABROWSE_V42199A.data.gridData.rowset)
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  const sortIcon = <ArrowDownWard />;

 

  const selectInputRef = useRef();

  return (

    <div className="bg-grey py-5 ">
      <div className='md:w-full mb-3 w-full '>
        <div className="rounded-[10px] min-h-[300px]">
          <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">
            <div className="flex justify-center">
              {/* <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Data</h2> */}


              <div className='grid  justify-center md:grid-cols-4 mb-3 gap-x-2 '>

                <div>
                  <Label>From Date</Label> <br />
                  <DatePicker
                    className='w-full px-5 p-2 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base'
                    name="from_date" //only when value has changed
                    selected={todayDate}
                    disabledKeyboardNavigation={false}
                    showYearDropdown
      yearDropdownItemNumber={15}
      scrollableYearDropdown
                    onChange={onChangeFromDate}
                    isClearable={false}
                    dateFormat="dd/MM/yyyy"
                    // onChange={(date) =>
                    //   setFromDate(date)}
                    placeholderText="From Date"
                    peekNextMonth
                    showMonthDropdown
                    dropdownMode="select"
                    dateFormatCalendar="yyyy"
                    yearItemNumber={9}
                    minDate={moment().subtract(1, "years")}
                  />
                </div>
                <div>
                  <Label>To Date</Label> <br />
                  <DatePicker
                    className='w-full px-5 p-2 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base'
                    name="to_date" //only when value has changed
                    value={toDate}
                    disabled
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                    // onChange={(date) =>
                    //   setToDate(date)}
                    placeholderText="To Date"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>

                <div>
                  <Label>Customer Name</Label>
                  <Select
                    options={renderCustomerList()}
                    className=' border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base'
                    isSearchable={true}
                    placeholder="Customer Name"
                    onChange={(e) => setCustomValue(e)}
                    filterOption={createFilter({ ignoreAccents: false })}
                    isClearable={false}
                    backspaceRemovesValue={true}
                    escapeClearsValue={true}
                  />
                </div>
                {/* <div>
                <Label>Order Type</Label>
                  <Select
                    options={orderType}
                    className=' border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base'
                    placeholder="Order Type"
                    onChange={(e) => setOrdertypeValue(e)}
                    filterOption={createFilter({ ignoreAccents: false })}

                  /> 
                </div> */}
                {/* <div>
                   <Label>Item Description</Label>
                  <Select
                    options={renderItemDescpList()}
                    className=' border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base'
                    isSearchable={true}
                    placeholder="Item Descprition"
                    onChange={(e) => setItemDespValue(e)}
                    filterOption={createFilter({ ignoreAccents: false })}

                  /> 
              </div> */}
                <div>
                  <br/>
                <Button className="mb-2  min-w-[130px]" onClick={() => searchData()}>
                  Search
                </Button>
                </div>
              </div>
              

            </div>
            {searchResponseData && searchResponseData.length > 0 ?  
            <LoadingComponent isLoading={isCPLoading} component={(
              <DataTable
                className="cm-category-admin-table"

                columns={columnsLable}
                data={searchResponseData}
                defaultSortFieldId="orderNumber"
                sortable={true}
                striped
                pagination
                customStyles={tableCustomStyles}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                sortIcon={sortIcon}
                fixedHeader={false}
                pointerOnHover={true}
                selectableRowsHighlight={true}
                selectableRowsSingle={true}
                selectableRowsVisibleOnly={true}
                style={{ width: '100px' }}
                overflowY={true}
                progressPending={isCPLoading}
              />
            )} />:
            <div className='md:w-full mb-3 w-full'>
              <img
                  className=" w-full object-cover"
                  src={DefaultLandingImage}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                   
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
              </div>}
          </div>
        </div>
        {

          <div className="flex justify-center mt-2">
            {/* <Pagination
                    
                  
                    showIcons={true}
                     /> */}
          </div>
        }
      </div>
    </div>

  );
}
