import { Chart, registerables } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { removeAuthState } from '../../redux/features/authStateSlice';
import AxiosMain from '../../utilits/const';
import TotalFGValuation from './fGValuation';
import TotalInvValuation from './invValuation';
import TotalOtherValuation from './otherValuation';
import TotalRMValuation from './rmValuation';
import TotalInventoryValuation from './totalInventoryValution';

Chart.register(...registerables)


export default function InventorySummaryDashboard() {

  const dispatch = useDispatch();
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token
  const navigate = useNavigate();
  const [isCPLoading, setIsCPLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [invValue, setInvValue] = useState([]);

  
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }


  const validTokenData ={
    "token": token,
  }

 

  const checktokenValid = async () =>{
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/tokenrequest/validate`,
    validTokenData,
      config)
      .then((res) => {
        if (res.isValidSession == false) {
          Swal.fire({
            title: 'Warning',
            text: 'Token Expired.',
            icon: "warning",
          });          
          setIsCPLoading(false);
          dispatch(removeAuthState());
          navigate("/");
        }else{
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
      setIsCPLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checktokenValid();
    }, [3600000]);
    return () => clearInterval(interval);
  }, []);

  // const getTotalamount = (data_save) => {
  //   data_save.map((id)=>{
  //     if(id.id == 3){
  //       const value = id.value
  //     setInvValue(cuurent=>[...cuurent,...value])
  //     }
  //     else if(id.id == 5){
  //       const value = id.value
  //       setInvValue(current=>[...current,...value])
  //       }
  //   })

  //   console.log('invValue',invValue)
  //   // let settotalPrice = (data_save.reduce((a, v) => a = a + v.value, 0))
  //   // const result = Number(settotalPrice).toFixed(2);
  //   // console.log('result',result)
  //   // setTotalPrice(result)

  //   // let settotalCost = (data_save.reduce((a, v) => a = a + v.F4211_ECST, 0))
  //   // setTotalCost(settotalCost.toFixed(2))

  //   // let profit = settotalPrice - settotalCost
  //   // setTotalProfit(profit.toFixed(2))

  //   // let percentage = (profit / settotalCost) * 100
  //   // setTotalPercentag(percentage.toFixed(2))
  // }





  return (
    <div>
      <div className="bg-grey py-5 px-3">
        <div className='md:w-full mb-3 w-full '>
          <div className="rounded-[10px] p-3 ">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-0">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'></div>
              {/* <!-- Content Row --> */}
              <div className="grid-container3">
                <div className="grid-item bg-white shadow"><TotalFGValuation /></div>
                <div className="grid-item bg-white shadow"><TotalInvValuation /></div>
                <div className="grid-item bg-white shadow"><TotalRMValuation/></div>  
                <div className="grid-item bg-white shadow"><TotalOtherValuation/></div>  
              </div>

              <div className="grid-container7">
                <div className="grid-item bg-white shadow"><TotalInventoryValuation/></div>
                </div>

            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-white px-3">
        <div className='md:w-full mb-3 w-full '>
          <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'><span style={{ fontSize: 24, fontWeight: 'bold' }}>Graphs</span></div>
              <div>
                <TopTenCustomer />
                
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-white  px-3">
        <div className='md:w-full mb-3 w-full '>
          <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'><span style={{ fontSize: 24, fontWeight: 'bold' }}>Graphs</span></div>
              <div>
        <Line
          data={state1}
          options={{
            title: {
              display: true,
              text: 'Class Strength',
              fontSize: 20,
            },
            legend: {
              display: true,
              position: 'right',
            },
          }}
        />
      </div>
            </div>
          </div>
        </div>
      </div> */
      }
    </div>
  )
}