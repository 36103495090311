import AOS from 'aos';
import 'aos/dist/aos.css';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Logimg from '../assets/images/CyretLogo.png';
import LoadingComponent from '../components/LoadingComponent';
import { setAuthState } from '../redux/features/authStateSlice';
import AxiosMain from '../utilits/const';


export default function Loginpage() {
  const [isCPLoading, setIsCPLoading] = useState(false);
  // const [selectLinkType, setSelectLinkType] = useState('');
  const dispatch = useDispatch();

  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
    //var jwt = require('jsonwebtoken');
    const navigate = useNavigate();
    const loginSchema = Yup.object().shape({
        username: Yup.string()
        .required("Please enter your username"),
        // .matches(/^[0-9]+$/, "Only number allowed")
        // .min(6,"Minimum 6 Digit Required")
        // .max(10, "Max 10 Digit Required"),
        password:Yup.string()
        .required("Required"),
        // .min(5)
        // .matches(passwordRules, { message: "Please create a stronger password" })
        
    });

    const linkType = [
      { value: 'pd', label: 'PD' },
      { value: 'py', label: 'PY' },
      { value: 'dv', label: 'DV' },
      
    ]

  useEffect(() => {
    AOS.init( {duration : 2000});
    // setSelectLinkType({ value: 'pd', label: 'PD' })
  }, [])

  const loginFormik = useFormik({
    initialValues:{
        username:"",
        password:""
    },
    validationSchema:loginSchema,
    onSubmit:async (values)=>{
        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          }
         await AxiosMain.post("/jderest/v2/tokenrequest/", values, config).then((res)=>{
            if(res.status === 200){
                Swal.fire({
                    title: 'Done',
                    text: 'User Successfully Logged in',
                    icon:"success",
                    timer: 1000,
                    showConfirmButton: false
                  })    
                  // const {access, refresh} = res.data;
                  // console.log('access',access)
                  // const datauser = jwt_decode(access);
                  // console.log(datauser)
                  // const usePayload = {
                  //   user:datauser,
                  //   token:res.data.userInfo.token
                  // }
                  dispatch(setAuthState(res));
                  // dispatch(setUserSelectedLinks(selectLinkType))
                navigate("/dashboard");
            }
          }).catch((e)=>{
            Swal.fire({
                title: 'Warning',
                text: `Please Enter Valid Username and Password`,
                icon:"warning",
              })
          })
    }
});

  return (
    <div className="flex items-center h-screen w-screen imageBackgroundLogin" >
      <div className="login-container mx-auto mt-[2%] " data-aos="fade-up" data-aos-delay="500">
        {/* <img className="centerimage" src={MainLogo} alt="logo" width={"15%"} /> */}
        <div className="grid grid-cols-1 md:grid-cols-2 bg-white rounded h-3/5 w-4/5 lg:w-3/5 mx-auto my-12 " >
            <div className="hidden md:flex justify-center p-6 md:items-center bg-white rounded ">
              <img
                className=" w-5/5 object-cover  4xl:w-[85%]  2xl:w-[65%]  5xl:w-[70%] 6xl:w-full lg:w-[90%] xl:w-[80%] md:w-full"
                src={Logimg}
                alt="logo"
              />
          </div>
          {/* <div className='flex w-full md:hidden justify-center items-center pt-4'>
            <img className=' inline-block' src={MainLogo} width="100" alt='Robokidz' />
            </div> */}
          <div className="flex justify-center p-6 items-center gap-y-2 rounded" >
            
            <div className="cm-register-form block w-full ml-10 md:ml-0 rounded" >
              <div className='flex flex-row text-xs text-[#000] sm:justify-center md:justify-center lg:justify-center md:px-5'>
            {/* <img className="grid grid-cols-1  w-4/5 mb-3 gap-x-4 md:mx-auto" src={MainLogo} alt="logo" width={"35%"} /> */}
              </div>
              <h1 className="flex text-xl md:text-2xl font-normal mb-3 md:justify-center px-1 md:px-0">
                Enter Your JDE Credentials
              </h1>
              <LoadingComponent
                isLoading={isCPLoading}
                component={
              <form onSubmit={loginFormik.handleSubmit}>
                    <div className="grid grid-cols-1  w-4/5 mb-3 gap-x-4 md:mx-auto" >
                      <input
                        className="p-2 px-5 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md mb-2 md:mb-0 text-xs md:text-base"
                        type="text"
                        placeholder="Enter User Name"
                        aria-label="username"
                        name="username"
                        onBlur={loginFormik.handleBlur}
                        onChange={loginFormik.handleChange}
                        value={loginFormik.values.username}
                      ></input>
                      {loginFormik.errors.username &&
                        loginFormik.touched.username && (
                          <p className="form-error  mb-0">
                            {loginFormik.errors.username}
                          </p>
                        )}
                    </div>
                    <div className="grid grid-cols-1  w-4/5 mb-3 gap-x-4 md:mx-auto">
                      <input
                        className="p-2 px-5 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base "  
                        type="password"
                        placeholder="Password"
                        aria-label="password"
                        name="password"
                        onBlur={loginFormik.handleBlur}
                        onChange={loginFormik.handleChange}
                        value={loginFormik.values.password}
                      ></input>
                      {loginFormik.errors.password &&
                        loginFormik.touched.password && (
                          <p className="form-error  mb-0">
                            {loginFormik.errors.password}
                          </p>
                        )}
                    </div>
                    {/* <div className="grid grid-cols-1  w-4/5 mb-3 gap-x-4 md:mx-auto">
                    <Select
                    options={linkType}
                    className=' border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base'
                    placeholder=" Select Link"
                    onChange={e => {setSelectLinkType(e)}}
                    value={selectLinkType}
                    
                    
                  /> 
                    </div> */}
                    {/* <div className='flex flex-row mb-3 gap-x-2 md:gap-x-4 md:px-6 lg:px-11 2xl:px-14 4xl:px-11 5xl:px-20'>
                    <input type="checkbox" />
                    <label htmlFor="rememberme" className=' text-xs'>Remember me</label>
                  </div> */}
                    <button
                      className="flex flex-row w-4/5 py-2 text-xs md:text-base rounded-2xl bg-[#4e73df] text-white  justify-center md:mx-auto"
                      type="submit"
                    >
                      Login
                    </button>
                  </form>
                   }
              />

              {/* <div className=" flex w-full border-b p-1 border-[#d1d3e2]"></div> */}
              {/* <Link
                className="flex flex-row items-center mt-4 cursor-pointer"
                type="link"
                to={"/loginnew"}
              >
               
                <p className="text-sky-700 text-sm font-bold leading-loose ml-[0.75rem]">
                  New Login Page
                </p>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}