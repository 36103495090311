import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../components/LoadingComponent';

import { SalesYesterdayDATA } from '../../redux/features/homeslice';

export default function DailySales() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home.salesyesterdayData);

  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [total, setTotal] = useState(0)
  const curentYear = moment().year();;
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('month').format('DD/MM/YYYY');
  const todayDate = moment().format("DD/MM/YYYY")
  const endOfMonth = moment().endOf('month').format('DD/MM/YYYY');
  const subtractDate = moment().subtract(1, 'days').format('DD/MM/YYYY');

  const [se, setSE] = useState(0)
  const [so, setSO] = useState(0)
  const [co, setCO] = useState(0)
  const [sn, setSN] = useState(0)
  const [sortResult, setSortResult] = useState([]);
  const [sumofallvalues, setSumOFAll] = useState(0)


  const dispatch = useDispatch();
  const [minutes, setMinutes] = useState(0);


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(SalesYesterdayDATA(customerdataList)).unwrap();
    setIsCPLoading(false);
    
  }
  
  
  useEffect(() => {
    if (apiData === undefined) {
      if (apiData.fs_DATABROWSE_V554219A == undefined || apiData.fs_DATABROWSE_V554219A == null) {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    } else {
      if (minutes != 60) {
        if (apiData.fs_DATABROWSE_V554219A != null) {
          const data = apiData.fs_DATABROWSE_V554219A.data.gridData.rowset;
          setCustomerList(data)
          getTotalamount(data)
        }
        else {
          (async () => {
            const defaultData = await getCustomerList();
          })()
        }
      }
      else {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }
  }, [apiData]);

  

  const customerdataList = {
    "token": token,
    "targetName": "V554219A",
    "targetType": "view",
    "maxPageSize": "10000",
    "dataServiceType": "BROWSE",
    "returnControlIDs": "F42119.AEXP|F42119.SOQS|F42119.DOCO|F42119.LNID|F42119.DCTO|F42119.IVD",
    "query": {
      "autoFind": true,
      "condition":
        [
          // {
          //   "value" : 
          //   [ {
          //     "content" : "SO",//from Drop Down list of Order types
          //     "specialValueId" : "LITERAL"
          //     } 
          //   ],
          //   "controlId" : "F42119.DCTO",
          //   "operator" : "EQUAL"
          // },
          {
            "value":
              [{
                "content": "580",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.LTTR",
            "operator": "GREATER"
          },
          // {
          //   "value" : 
          //   [ {
          //     "content" : "999",
          //     "specialValueId" : "LITERAL"
          //     } 
          //   ],
          //   "controlId" : "F42119.NXTR",
          //   "operator" : "EQUAL"
          // },
          {
            "value":
              [{
                "content": "0",//last 2 digits of curretnt Year
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.LNID",
            "operator": "GREATER"
          },
          // {
          //   "value" : 
          //   [ {
          //     "content" : subtractDate,//date less than 1 date example today's date is 15/08/2023 then it should calculate //last month's last date
          //     "specialValueId" : "LITERAL"
          //     } 
          //   ],
          //   "controlId" : "F42119.IVD",
          //   "operator" : "GREATER"
          // },
          {
            "value":
              [{
                "content": subtractDate,//date greater than 1 date for today's date plus 1 date
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.IVD",
            "operator": "EQUAL"
          }
        ]
    }
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {

  //         let data_save1 = res.data.fs_DATABROWSE_V554219A.data.gridData.rowset;
  //         let data_save = res.data.fs_DATABROWSE_V554219A.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }


  const getTotalamount = (data_save) => {
    var result = [];
    var soValue = 0;
    var coValue = 0;
    var seValue = 0;
    var snValue = 0;
    data_save.reduce(function (res, value) {
      if (!res[value.F42119_DCTO]) {
        res[value.F42119_DCTO] = { F42119_DCTO: value.F42119_DCTO, F42119_AEXP: 0 };
        result.push(res[value.F42119_DCTO])
      }
      res[value.F42119_DCTO].F42119_AEXP += value.F42119_AEXP;
      return res;
    }, {});

    setSortResult(result)
    result.map((values) => {
      if (values.F42119_DCTO === "SO") {
        const value = values.F42119_AEXP
        soValue = value;
        setSO(value)
      }
      if (values.F42119_DCTO === "CO") {
        const value2 = values.F42119_AEXP
        coValue = value2;
        setCO(value2)
      }
      if (values.F42119_DCTO === "SE") {
        const value3 = values.F42119_AEXP
        seValue = value3;
        setSE(value3)
      }
      if (values.F42119_DCTO === "SN") {
        const value4 = values.F42119_AEXP
        snValue = value4;
        setSN(value4)
      }

    })

    let summation = (soValue + coValue + seValue + snValue)
    let summation2 = summation.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    setSumOFAll(summation2)
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })()
  // }, []);

  const labels = [
    `Local Sales: ${so.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Export Sales: ${se.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Credit Order: ${co.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Service Order: ${sn.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
  ];
  const options = {
    chart: {
      type: 'pie',
    },
    labels: labels,
    colors: [
      'rgba(153, 102, 255, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(201, 203, 207, 0.8)',
     ], // Colors in hexadecimal format
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  const series = [
    parseFloat(so.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(se.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(co.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(sn.toString().replace(/[^0-9.-]+/g, ''))
  ];

  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-gray-500 fontSmaller font-bold leading-loose ">Sales for - {subtractDate}</p>
          </div>
          <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
            <span style={{ color: 'rgb(17 24 39)', fontSize: 22 }}>{sumofallvalues}</span>
          </div>
          <div className="items-center justify-center my-5 m-0"style={{ width: '100%', height: '100%', overflow: 'hidden'}}>            
              <ReactApexChart options={options} series={series} type="pie" width={350} height={300} />
            </div>
        </div>
      )} />
    </div>

  )
}