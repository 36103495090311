// import SettingsIcon from '@mui/icons-material/';
import { AttachMoneyOutlined, Inventory2Outlined, PrecisionManufacturingOutlined, ReceiptOutlined, TrackChangesOutlined, TrendingUpOutlined } from '@mui/icons-material';
import LogoutIcon from 'remixicon-react/LogoutBoxLineIcon';
import LogoutPage from '../pages/LogoutPage';
import FinanceAP from '../pages/finances/financeAPDashboard';
import FinanceAR from '../pages/finances/financeARDashboard';
import FinanceDashboard from '../pages/finances/financeDashboard';
import InventorySummaryDashboard from '../pages/inventory/inventorySummary';
import PersonalDashboard from '../pages/orderjourney/PersonalDashboard';
import OrderJourneyExport from '../pages/orderjourney/orderJourneyExport';
import PurchaseSummary from '../pages/purchase/purchaseSummary';
import SalesSummary from '../pages/sales/salesSummaryDashboard';
const personalSidebarRoutes = [
    {
        id:1,
        label: "Order Journey",
        icon: <TrackChangesOutlined color='#fff' />,
        icont: <TrackChangesOutlined className=' mx-12 h-8 w-7' color='#333333' />,
        link: "/dashboard",
        component: "Hello",
        child: []
    },

    // {
    //     label: "Order Journey Export",
    //     icon: <TrackChangesOutlined color='#fff' />,
    //     icont: <TrackChangesOutlined className=' mx-12 h-8 w-7' color='#333333' />,
    //     link: "/orderjourneyexport",
    //     component: "Hello",
    //     child: []
    // },
    
    // {
    //     label: "Finances Indicators",
    //     icon: <TrendingUpOutlined color='#fff' />,
    //     icont: <TrendingUpOutlined className=' mx-12 h-8 w-7' color='#333333' />,
    //     link: "/finance",
    //     component: "finance",
    //     child: []
    // },
    {
        id:2,
        label: "Finances AR",
        icon: <TrendingUpOutlined color='#fff' />,
        icont: <TrendingUpOutlined className=' mx-12 h-8 w-7' color='#333333' />,
        link: "/financeAR",
        component: "financeAR",
        child: []
    },
    {
        id:3,
        label: "Finances AP",
        icon: <TrendingUpOutlined color='#fff' />,
        icont: <TrendingUpOutlined className=' mx-12 h-8 w-7' color='#333333' />,
        link: "/financeAP",
        component: "financeAP",
        child: []
    },
    {
        id:4,
        label: "Sales Summary",
        icon: <AttachMoneyOutlined color='#fff' />,
        icont: <AttachMoneyOutlined className=' mx-12 h-8 w-7' color='#333333' />,
        link: "/salesummary",
        component: "Hello",
        child: []
    },
    {
        id:5,
        label: "Purchases Summary",
        icon: <ReceiptOutlined color='#fff' />,
        icont: <ReceiptOutlined className=' mx-12 h-8 w-7' color='#333333' />,
        link: "/purchasesummary",
        component: "Hello",
        child: []
    },
    {
        id:6,
        label: "Production Summary",
        icon: <PrecisionManufacturingOutlined color='#fff' />,
        icont: <PrecisionManufacturingOutlined className=' mx-12 h-8 w-7' color='#333333' />,
        link: "/productsummary",
        component: "Hello",
        child: []
    },
    {
        id:7,
        label: "Inventory Summary",
        icon: <Inventory2Outlined color='#fff' />,
        icont: <Inventory2Outlined className=' mx-12 h-8 w-7' color='#333333' />,
        link: "/inventorysummary",
        component: "Hello",
        child: []
    },
    {
        id:8,
        label: "Logout",
        icon: <LogoutIcon color='#fff' />,
        icont: <LogoutIcon className=' mx-12 h-8 w-7' color='#333333' />,
        link: "/logout",
        component: "Hello",
        child: []
    },
   
];
const personalRoutes = [
   
    {
        link: "/dashboard",
        component: <PersonalDashboard />
    },
    {
        link: "/orderjourneyexport",
        component: <OrderJourneyExport />
    },
    {
        link: "/logout",
        component: <LogoutPage />
    },
    {
        link: "/finance",
        component: <FinanceDashboard />
    },
    {
        link: "/salesummary",
        component: <SalesSummary />
    },
    {
        link: "/purchasesummary",
        component: <PurchaseSummary />
    },
    {
        link: "/productsummary",
        component: <PurchaseSummary />
    },
    {
        link: "/inventorysummary",
        component: <InventorySummaryDashboard />
    },
    {
        link: "/financeAR",
        component: <FinanceAR />
    },
    {
        link: "/financeAP",
        component: <FinanceAP />
    },
]
export {
    personalRoutes, personalSidebarRoutes
};

