import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../components/LoadingComponent';
import { InventoryFinishGoodsData } from '../../redux/features/homeslice';

export default function TotalFGValuation() {
  const userauth = useSelector((state) => state.auth); 
   const apiData = useSelector((state) => state.home);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0)
  const [totalPercentage, setTotalPercentag] = useState(0)
  const [totalamount, setTotalAmount] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const curentYear = moment().year();;
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")

  const customerdataList = {
    "token": token,
    "name": "NERForUnitCost",
    "isAsync" : false,
    "inParams" : [
        {
            "id": 7,
            "value": "1"   // Type Code to be selected from the drop down
        }
    ],
    "outputIds" : [
        8,10
        // ,11,12,13,14,15,16,17
    ]
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/bsfnservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.result.output;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }

  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(InventoryFinishGoodsData(customerdataList)).unwrap();
    setIsCPLoading(false);

  }


  useEffect(() => {
    if (apiData.status === 'succeeded') {
      if (apiData?.finishGood) {
        if (apiData.finishGood === undefined) {
          if (apiData.finishGood.result == undefined || apiData.finishGood.result == null) {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        } else {
          if (minutes != 60) {
            if (apiData.finishGood.result != null || apiData.finishGood.result != undefined) {
              const data = apiData.finishGood.result.output;
              setCustomerList(data)
              getTotalamount(data)
            }
            else {
              (async () => {
                const defaultData = await getCustomerList();
              })()
            }
          }
          else {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        }
      }
      else{
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }else{
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }, [apiData]);

  const getTotalamount = (data_save) => {
    data_save.map((id)=>{
      if(id.id === 8){
        const formattedAmount = Number(id.value).toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setTotalAmount(formattedAmount)
      }
      if(id.id === 10){
        const result = Number(id.value).toFixed(2);
        setTotalQuantity(result)
      }
    })
  }

  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })() 
  // }, []);

  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
          <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Finished Goods </p> 
          </div>
          <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
            
            <div className="grid-container2">
              <div className="grid-item2"> <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Amount</p> <p className='ml-[0.75rem] fontSmaller' style={{ color: 'rgb(17 24 39)'}}>{totalamount}</p></div>
              <div className="grid-item2"> <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Quantity</p> <p className='ml-[0.75rem] fontSmaller' style={{ color: 'rgb(17 24 39)' }}>{totalQuantity}</p></div>
              {/* <div className="grid-item2"> <span style={{ fontSize: 15 }}>Total Profit</span> <span style={{ color: 'rgb(17 24 39)', fontSize: 18 }}>{totalProfit}</span></div> */}
            </div>
          </div>
        </div>
      )} />
    </div>

  )
}