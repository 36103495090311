import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../../../components/LoadingComponent';
// import { NetARReceiptCashYTDDATA, NetARVoidReceiptYTDDATA } from '../../../../redux/features/homeslice';
import AxiosMain from '../../../../utilits/const';


export default function ReceiptCashYTD() {
  const userauth = useSelector((state) => state.auth);
  // const apiData = useSelector((state) => state.home.netareceiptcashytdData);
  // const apiDataVoid = useSelector((state) => state.home);
  // console.log('apdiData--',apiDataVoid);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [sortResult, setSortResult] = useState([]);
  const [total, setTotal] = useState(0)
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate =moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")
  const [rc, setRC] = useState(0)

  const [customerListvod, setCustomerListVOD] = useState([]);
  const [customerListeck, setCustomerListECK] = useState([]);

  const [vod, setVOD] = useState(0)
  const [eck, setECK] = useState(0)

  // const [minutes, setMinutes] = useState(0);
  // const dispatch = useDispatch();

  // const updateMinutes = (newMinutes) => {
  //   setMinutes(newMinutes);
  //   localStorage.setItem('minutes', newMinutes.toString());
  // };


  // useEffect(() => {
  //   // Function to be executed every minute
  //   const updateEveryMinute = () => {
  //     // Update minutes
  //     updateMinutes(minutes + 1);
  //   };

  //   // Set up an interval to call the function every minute
  //   const intervalId = setInterval(updateEveryMinute, 36000000);

  //   // Cleanup function to clear the interval when the component is unmounted
  //   return () => clearInterval(intervalId);
  // }, [minutes]);



  const customerdataList = {
    "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.CKAM|F03B13.ICUT|F03B13.DGJ",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "RB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value" : 
            [ {
              "content" : subtractDate,
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F03B13.DMTJ",
            "operator" : "GREATER"
          },
          {
            "value" : 
            [ {
              "content" : nextDate,
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F03B13.DMTJ",
            "operator" : "LESS"
          },
        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B13.PAAP",
        "direction": "DESC"
      },
      ]
    }
  }

  const customerdataListvod = {
    "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.CKAM|F03B13.ICUT|F03B13.DGJ",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "RB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value" : 
            [ {
              "content" : subtractDate,
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F03B13.DMTJ",
            "operator" : "GREATER"
          },
          {
            "value" : 
            [ {
              "content" : nextDate,
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F03B13.DMTJ",
            "operator" : "LESS"
          },
          {
            "value" : 
            [ {
              "content" : "VOD",
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F03B13.VRE",
            "operator" : "EQUAL"
          },
          
        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B13.PAAP",
        "direction": "DESC"
      },
      ]
    }
  }

  const customerdataListeck = {
    "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.CKAM|F03B13.ICUT|F03B13.DGJ",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "RB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value" : 
            [ {
              "content" : subtractDate,
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F03B13.DMTJ",
            "operator" : "GREATER"
          },
          {
            "value" : 
            [ {
              "content" : nextDate,
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F03B13.DMTJ",
            "operator" : "LESS"
          },
          {
            "value" : 
            [ {
              "content" : "ECK",
              "specialValueId" : "LITERAL"
              } 
            ],
            "controlId" : "F03B13.VRE",
            "operator" : "EQUAL"
          },
          
        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B13.PAAP",
        "direction": "DESC"
      },
      ]
    }
  }


  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // useEffect(() => {
  //   if (apiData === undefined || apiDataVoid === undefined) {
  //     if (apiData.fs_DATABROWSE_V03B13A == undefined || apiData.fs_DATABROWSE_V03B13A == null || apiDataVoid.fs_DATABROWSE_V03B13A == undefined || apiDataVoid.fs_DATABROWSE_V03B13A == null) {
  //       (async () => {
  //         const defaultData = await getCustomerList();
  //       })()
  //     }
  //   } else {
  //     if (minutes != 60) {
  //       if (apiData.fs_DATABROWSE_V03B13A != null || apiDataVoid.fs_DATABROWSE_V03B13A != null) {
  //         if(apiData.fs_DATABROWSE_V03B13A.title == 'Data Browser - V03B13A [Standard Receipts Entry Header]'){
  //           const data = apiData.fs_DATABROWSE_V03B13A.data.gridData.rowset;
  //         setCustomerList(data)
  //         getTotalamount(data)
  //         }
          
          
  //       }
  //       else {
  //         console.log('else if if else  called');
  //         (async () => {
  //           const defaultData = await getCustomerList();
  //         })()
  //       }
  //     }
  //     else {
  //       console.log('else else called');
  //       (async () => {
  //         const defaultData = await getCustomerList();
  //       })()
  //     }
  //   }
  // }, [apiData,apiDataVoid]);

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await dispatch(NetARReceiptCashYTDDATA(customerdataList)).unwrap();
  //   getCustomerListVod();
  //   setIsCPLoading(false);

  // }

  const getCustomerList = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataList,
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;   
          setCustomerList(data_save)
          setIsCPLoading(false);
          getTotalamount(data_save)
          getCustomerListVod();
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  // const getCustomerListVod = async () => {
  //   setIsCPLoading(true);
  //   await dispatch(NetARVoidReceiptYTDDATA(customerdataListvod)).unwrap();
  //   getCustomerListEck();
  //   setIsCPLoading(false);

  // }

  const getCustomerListVod = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataListvod,
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;   
          setCustomerListVOD(data_save)
          setIsCPLoading(false);
          getTotalamountVod(data_save)
          getCustomerListEck();
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  const getCustomerListEck = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataListeck,
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;   
          setCustomerListECK(data_save)
          setIsCPLoading(false);
          getTotalamountECK(data_save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }


  const getTotalamount = (data_save) => {
    var result = [];
    data_save.reduce(function(res, value) {
      if (!res[value.F03B13_ICUT]) {
        res[value.F03B13_ICUT] = { F03B13_ICUT: value.F03B13_ICUT, F03B13_CKAM: 0 };
        result.push(res[value.F03B13_ICUT])
      }
      res[value.F03B13_ICUT].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});

    setSortResult(result)
    result.map((values)=>{
      if(values.F03B13_ICUT === "RB"){
        const value = values.F03B13_CKAM.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setRC(value)
      }
     
    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const getTotalamountVod = (data_save) => {
    var result = [];
    data_save.reduce(function(res, value) {
      if (!res[value.F03B13_ICUT]) {
        res[value.F03B13_ICUT] = { F03B13_ICUT: value.F03B13_ICUT, F03B13_CKAM: 0 };
        result.push(res[value.F03B13_ICUT])
      }
      res[value.F03B13_ICUT].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});

    setSortResult(result)
    result.map((values)=>{
      if(values.F03B13_ICUT === "RB"){
        const value = values.F03B13_CKAM.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setVOD(value)
      }
     
    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const getTotalamountECK = (data_save) => {
    var result = [];
    data_save.reduce(function(res, value) {
      if (!res[value.F03B13_ICUT]) {
        res[value.F03B13_ICUT] = { F03B13_ICUT: value.F03B13_ICUT, F03B13_CKAM: 0 };
        result.push(res[value.F03B13_ICUT])
      }
      res[value.F03B13_ICUT].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});

    setSortResult(result)
    result.map((values)=>{
      if(values.F03B13_ICUT === "RB"){
        const value = values.F03B13_CKAM.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setECK(value)
      }
     
    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  useEffect(() => {
    getCustomerList();
  }, []);

  return (
    <div>
     <LoadingComponent isLoading={isCPLoading} component={(
              <div className="flex flex-wrap items-center justify-center my-5 m-0">
              <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className="text-gray-500 font-bold leading-loose ml-[0.75rem] textAlign fontSmaller">Receipts:<span className='fontSmaller text-rgb(17 24 39)-600'>{rc}</span></p>
              </div>
              <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className=" fontSmaller font-bold leading-loose ml-[0.75rem] textAlign text-slate-700">Void Receipts:<span className='fontSmaller text-rgb(17 24 39)-600' >{vod}</span></p>
              </div>
              <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className=" fontSmaller font-bold leading-loose ml-[0.75rem] textAlign text-slate-700">Error Receipts:<span className='fontSmaller text-rgb(17 24 39)-600' >{eck}</span></p>
              </div>
            </div>
            )} />
    </div>

  )
}