import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuLineIcon from 'remixicon-react/MenuFillIcon';
import UserProfileDropdown from './UserProfileDropDown';
function TopBarcomponent(props) {
        const { profile } = props;
        const navigate = useNavigate();
        return (
                <>
                        <div className='cm-logged-ftr bg-[#343A40]  z-10 px-4 flex flex-wrap items-center px-9 shadow-lg shadow-[#3a3b451a] z-10 py-4 fixed left-0 right-0 top-0'>
                                <div className='flex w-[85%]'>
                                        <div className='md:hidden'>
                                                <MenuLineIcon onClick={props.onMenuButtonClick} />
                                        </div>
                                </div>
                                <div className='flex justify-center lg:navbar-divider w-[15%]'>
                                        <UserProfileDropdown profile={profile} />
                                </div>
                        </div>

                </>
        )
}
export default TopBarcomponent;