import { Chart } from "chart.js";
import "chartjs-plugin-datalabels";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../redux/components/LoadingComponent';
import { SalesTop10customerDATA } from "../../redux/features/homeslice";


export default function TopTenCustomer() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [top10Result, setTop10Result] = useState([]);
  const [sortResult, setSortResult] = useState([]);
  const [total, setTotal] = useState(0)
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastmonthlastdate=moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY')
  const lastDate =moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")
  const barRef = useRef();

  Chart.register(ChartDataLabels);

  const customerdataList = {
    "token": token,
    "targetName": "V564219A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F42119.AEXP|F42119.SOQS|F42119.DOCO|F42119.AN8|F42119.LNID|F0101.ALPH",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "580",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.NXTR",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": subtractDate, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.IVD",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": nextDate,//date that is today's date plus 1 date
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.IVD",
            "operator": "LESS"
          }
        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F42119.AEXP",
        "direction": "DESC"
      },
      // {
      //   "column": "F42119.DOCO",
      //   "direction": "ASC"
      // }
      ]
    }
  }

  
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V564219A.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }


  const getTotalamount = (data_save) => {
    var result = [];
  

    data_save.reduce(function (res, value) {
      if (!res[value.F42119_AN8]) {
        res[value.F42119_AN8] = { F42119_AN8: value.F42119_AN8, F42119_AEXP: 0,F0101_ALPH:value.F0101_ALPH};
        result.push(res[value.F42119_AN8])
      }
      res[value.F42119_AN8].F42119_AEXP += value.F42119_AEXP;
      return res;
    }, {});
    setSortResult(result)
    const  sorted = result.sort((a,b) => b.F42119_AEXP - a.F42119_AEXP);
    const firstTen = sorted.slice(0, 10);
    const top10 = sorted.filter((idx) => idx < 10)
    setTop10Result(firstTen)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  // useEffect(() => {
  //   getCustomerList();

  // }, []);

  const state = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: top10Result.map(o => o.F0101_ALPH),
    datasets: [
      {
        label: 'Top 10 Customers by Ammount (YTD)',
        // backgroundColor: [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        //   'rgba(255, 205, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(201, 203, 207, 0.2)'
        // ],
        // borderColor: [
        //   'rgb(255, 99, 132)',
        //   'rgb(255, 159, 64)',
        //   'rgb(255, 205, 86)',
        //   'rgb(75, 192, 192)',
        //   'rgb(54, 162, 235)',
        //   'rgb(153, 102, 255)',
        //   'rgb(201, 203, 207)'
        // ],
        backgroundColor: [
          'rgba(153, 102, 255, 0.8)',
          'rgba(201, 203, 207, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
        
        ],
        borderColor:'#fff',
        data: top10Result.map(o => parseFloat(o.F42119_AEXP).toFixed(2)),
      },
    ],
  }

  const options = {
    indexAxis: "y",
  scales: {
    x: {
      stacked: true,
      display: false
    },
    y: {
      stacked: true
    }
  },
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  // plugins: {
  //   legend: {
  //     position: "bottom"
  //   },
  //   title: {
  //     display: true,
  //     text: "Top 10 Customers"
  //   }
  // }
  };

  const dispatch = useDispatch();
  const [minutes, setMinutes] = useState(0);


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(SalesTop10customerDATA(customerdataList)).unwrap();
    setIsCPLoading(false);
    
  }
  
  
  useEffect(() => {
    if (apiData.status === 'succeeded') {
      console.log('')
      if (apiData?.salesTop10customerdData) {
        if (apiData.salesTop10customerdData === undefined) {
          if (apiData.salesTop10customerdData.fs_DATABROWSE_V564219A == undefined || apiData.salesTop10customerdData.fs_DATABROWSE_V564219A == null) {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        } else {
          if (minutes != 60) {
            if (apiData.salesTop10customerdData.fs_DATABROWSE_V564219A != null || apiData.salesTop10customerdData.fs_DATABROWSE_V564219A != undefined) {
              const data = apiData.salesTop10customerdData.fs_DATABROWSE_V564219A.data.gridData.rowset;
              setCustomerList(data)
              getTotalamount(data)
            }
            else {
              (async () => {
                const defaultData = await getCustomerList();
              })()
            }
          }
          else {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        }
      }
      else{
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }else{
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }, [apiData]);

 
  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="grid-container4">
        <div className='grid-item'>
            
              <Bar

                data={state}
                options={options}
                plugins={[ChartDataLabels]}
                // options={{
                //   responsive: true,     
                //   plugins: {
                //     legend: {
                //       position: 'top',
                //     },
                //     title: {
                //       display: true,
                //       text: 'Top 10 Customers',
                //       font: {
                //         size: 16,
                //       }
                //     }
                //   },
                  
                // }}
              />
          </div>
        </div>
      )} />
    </div>

  )
}