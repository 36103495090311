import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../redux/components/LoadingComponent';
import { PurchaseTodayDATA } from '../../redux/features/homeslice';

export default function DailyPurchaseReceipt() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [total, setTotal] = useState(0)
  const curentYear = moment().year();;
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate =moment().startOf('month').format('DD/MM/YYYY');
  
  const endOfMonth   = moment().endOf('month').format('DD/MM/YYYY');
  const subtractDate = moment().subtract(1, 'days').format('DD/MM/YYYY');

  const [sortResult, setSortResult] = useState([]);
  const [mi, setMI] = useState(0)
  const [op, setOP] = useState(0)
  const [nz, setNZ] = useState(0)
  const [ps, setPS] = useState(0)
  const [sumofallvalues, setSumOFAll] = useState(0)
  
  const customerdataList = {
    "token" : token,
  "targetName" : "V43260A",
  "targetType" : "view",
  "dataServiceType" : "BROWSE", 
  "maxPageSize":"10000", 
  "returnControlIDs" : "F43121.UREC|F43121.AREC|F43121.DCTO|F43121.MATC",
  "query" : {
    "autoFind" : true,
    "condition" : 
    [ {
      "value" : 
      [ {
        "content" : subtractDate,//reciept date
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F43121.RCDJ",//reciept date
      "operator" : "GREATER"
     },
     {
      "value" : 
      [ {
        "content" : nextDate,//reciept date
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F43121.RCDJ",//reciept date
      "operator" : "LESS"
     },
     {
      "value" : 
      [ {
        "content" : "1",//reciept date
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F43121.MATC",//reciept date
      "operator" : "EQUAL"
     },
    //  {
    //   "value" : 
    //   [ {
    //     "content" : "280",//reciept date
    //     "specialValueId" : "LITERAL"
    //     } 
    //   ],
    //   "controlId" : "F43121.LTTR",//reciept date
    //   "operator" : "GREATER"
    //  }
  ]
  }
  }
  
  const labels = [
    `Imports: ${mi.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Local: ${op.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Non Stock: ${nz.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Services: ${ps.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
  ];
  const options = {
    chart: {
      type: 'pie',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(config.w.config.labels[config.dataPointIndex]);
        }
      }
    },
    labels: labels,
    colors: [
      'rgba(255, 159, 64, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 206, 86, 0.8)',
      'rgba(54, 162, 235, 0.8)',
     
     
      
    ], // Colors in hexadecimal format
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  const series = [
    parseFloat(mi.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(op.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(nz.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(ps.toString().replace(/[^0-9.-]+/g, '')),
  ];


  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(PurchaseTodayDATA(customerdataList)).unwrap();
    setIsCPLoading(false);

  }


  useEffect(() => {
    if (apiData.status === 'succeeded') {
      if (apiData?.todayPurchaseData) {
        if (apiData.todayPurchaseData === undefined) {
          if (apiData.todayPurchaseData.fs_DATABROWSE_V43260A == undefined || apiData.todayPurchaseData.fs_DATABROWSE_V43260A == null) {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        } else {
          if (minutes != 60) {
            if (apiData.todayPurchaseData.fs_DATABROWSE_V43260A!= null || apiData.todayPurchaseData.fs_DATABROWSE_V43260A!= undefined) {
              const data = apiData.todayPurchaseData.fs_DATABROWSE_V43260A.data.gridData.rowset;
              setCustomerList(data)
              getTotalamount(data)
            }
            else {
              (async () => {
                const defaultData = await getCustomerList();
              })()
            }
          }
          else {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        }
      }
      else{
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }else{
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }, [apiData]);
  
  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V43260A.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }
  
  
  const getTotalamount = (data_save) => {
    var result = [];
    var miValue = 0;
    var opValue = 0;
    var nzValue = 0;
    var psValue = 0;
    data_save.reduce(function (res, value) {
      if (!res[value.F43121_DCTO]) {
        res[value.F43121_DCTO] = { F43121_DCTO: value.F43121_DCTO, F43121_AREC: 0 };
        result.push(res[value.F43121_DCTO])
      }
      res[value.F43121_DCTO].F43121_AREC += value.F43121_AREC;
      return res;
    }, {});

    setSortResult(result)
    result.map((values) => {
      if (values.F43121_DCTO === "MI") {
        const value = values.F43121_AREC
        miValue = value
        setMI(value)
      }
      if (values.F43121_DCTO === "OP") {
        const value2 = values.F43121_AREC
        opValue = value2
        setOP(value2)
      }
      if (values.F43121_DCTO === "NZ") {
        const value3 = values.F43121_AREC
        nzValue = value3
        setNZ(value3)
      }
      if (values.F43121_DCTO === "PS") {
        const value4 = values.F43121_AREC
        psValue = value4
        setPS(value4)
      }

    })

    let summation = (miValue + opValue + nzValue + psValue)
    let summation2 = summation.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    setSumOFAll(summation2)
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }
  
  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })()     
  // },[]);


return (
  <div>
    <LoadingComponent isLoading={isCPLoading} component={(
              <div className="flex flex-wrap items-center justify-center my-5 m-0">
              <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Daily Receipts</p>
              </div>
              <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
              <span style={{color:'rgb(17 24 39)',fontSize:22}}>{sumofallvalues}</span>
              </div>
              <div className="items-center justify-center my-5 m-0"style={{ width: '100%', height: '100%', overflow: 'hidden'}}>            
              <ReactApexChart options={options} series={series} type="pie" width={350} height={280} />
            </div>
            </div>
            )} />
           
  </div>
  
)
}