import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AxiosMain from "../../utilits/const";

import LoadingComponent from "../../components/LoadingComponent";

import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import DeliveryNotes from "../../assets/images/DeliveryNote.png";
import CancelSaleOrder from "../../assets/images/cancel_saleOrder.png";
import Completed from "../../assets/images/completed.png";
import EnterOrder from '../../assets/images/enter_order.png';
import GLSaleRecapDetails from "../../assets/images/gl_sale_recap_details.png";
import InWarehouse from '../../assets/images/in_warehouse.png';
import InvoiceJournal from "../../assets/images/invoiceJournal.png";
import Ordercompleted from "../../assets/images/order_completed.png";
import PrintAck from '../../assets/images/print_ack.png';
import PrintConfirmation from '../../assets/images/print_confirmation.png';
import PrintInterBranchInvoice from "../../assets/images/print_interbranch_invoice.png";
import PrintInvoice from "../../assets/images/print_invoice.png";
import PrintPick from '../../assets/images/print_pick.png';
import PrintShippingDocument from "../../assets/images/print_shipping_document.png";
import SalesUpdate from "../../assets/images/sales_update.png";
import ShippingConfirmation from "../../assets/images/ship_confirmation.png";
export default function OrderDetailPage(props) {
  const  prevData = props.data
  const [isCPLoading, setIsCPLoading] = useState(false);
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token;
  const [responseFetchRecordData, setFetchRecordData] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(1);
  const handleChange = (e) => {
    setValue(e.value);
  };


  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const data = {
    token: token,
    targetName: "V42199B",
    targetType: "view",
    dataServiceType: "BROWSE",
    returnControlIDs: "F42199.USER|F42199.UPMJ|F42199.LTTR|F42199.DRQJ",
    query: {
      autoFind: true,
      condition: [
        {
          value: [
            {
              content: prevData.F42199_DOCO, //order number
              specialValueId: "LITERAL",
            },
          ],
          controlId: "F42199.DOCO",
          operator: "EQUAL",
        },
        {
          value: [
            {
              content: prevData.F42199_DCTO, //order type as input
              specialValueId: "LITERAL",
            },
          ],
          controlId: "F42199.DCTO",
          operator: "EQUAL",
        },
        {
          value: [
            {
              content: prevData.F42199_LNID, //Line Number as input
              specialValueId: "LITERAL",
            },
          ],
          controlId: "F42199.LNID",
          operator: "EQUAL",
        },
      ],
    },
  };


  const goBack = () => {
    navigate("/dashboard");
  }

  useEffect(() => {
    setIsCPLoading(true);
     AxiosMain.post(`/jderest/v2/dataservice`, data, config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V42199B.data.gridData.rowset;
          setFetchRecordData(data_save);
          setIsCPLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Warning",
          text: e,
          icon: "warning",
          timer: 1000,
          showConfirmButton: false,
        });
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      });
  }, []);

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, #0ba360 0%, #0ba360 50%, #0ba360 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, #0ba360 0%, #0ba360 50%, #0ba360 100%)',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <SettingsIcon />,
      2: <SettingsIcon />,
      3: <SettingsIcon />,
      4: <SettingsIcon />,
      5: <SettingsIcon />,
      6: <SettingsIcon />,
      7: <SettingsIcon />,
      8: <SettingsIcon />,
      9: <SettingsIcon />,
      10: <SettingsIcon />,
      11: <SettingsIcon />,
      12: <SettingsIcon />,
      13: <SettingsIcon />,
      14: <SettingsIcon />,
      15: <SettingsIcon />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

    ColorlibStepIcon.propTypes = {
      /**
       * Whether this step is active.
       * @default false
       */
      active: PropTypes.bool,
      className: PropTypes.string,
      /**
       * Mark the step as completed. Is passed to child components.
       * @default false
       */
      completed: PropTypes.bool,
      /**
       * The label displayed in the step icon.
       */
      icon: PropTypes.node,
    };
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        width:0,
        height:10,
        backgroundImage:
          'linear-gradient( 95deg,#0E7490 0%,#0E7490 50%,#0E7490 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        marginLeft:20,
        marginRight:50,
        backgroundImage:
          'linear-gradient( 95deg,#0E7490 0%,#0E7490 50%,#0E7490 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#0ba360',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#0ba360',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#0ba360',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  
  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#0ba360',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#0ba360',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#0ba360',
      zIndex: 1,
      fontSize: 25,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#0ba360',
    },
  }));
  
  function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  
  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  const activeStep = responseFetchRecordData ? responseFetchRecordData.length : 0;


const renderIcon = (user) =>{
  let statusname  = user.F42199_LTTR
let text =''
switch (statusname) {
  case '520':
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={EnterOrder}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '525':
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={PrintAck}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '535':
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={InWarehouse}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                   
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '540':
    text ='Print Pick'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={PrintPick}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                   
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '545':
    text ='Pick Confirmation'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={PrintConfirmation}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                   
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '550':
    text ='Print Shipping Documents'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={PrintShippingDocument}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '555':
    text ='Pack Confirmation'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={Completed}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '560':
    text ='Ship Confirmation'
    return(
      <div>
        <img
                  className=" w-3/5 object-cover"
                  src={ShippingConfirmation}
                  alt="logo"
                  style={{ 
                    zIndex: 1,
                    color: '#fff',
                    width: 130,
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
      </div>
    )
    break;
  case '565':
    text ='Print Delivery Notes'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={DeliveryNotes}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                   
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '575':
      text ='Cycle Billing'
      return(
        <div>
          <img
                    className=" w-2/5 object-cover"
                    src={Completed}
                    alt="logo"
                    style={{ zIndex: 1,
                      color: '#fff',
                      width: 100,
                      height: 100,
                      display: 'flex',
                     
                      justifyContent: 'center',
                      alignItems: 'center',}}
                  />
        </div>
      )
      break; 
  case '578':
    text ='EDI Receiving Advice Reject'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={Completed}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '580':
    text ='Print Invoices'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={PrintInvoice}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 80,
                    height: 90,
                    display: 'flex',
                   marginBottom:10,
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '585':
    text ='Print Interbranch Invoice'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={PrintInterBranchInvoice}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break;
  case '600':
    text ='Invoice Journal'
    return(
      <div>
        <img
                  className=" w-2/5 object-cover"
                  src={InvoiceJournal}
                  alt="logo"
                  style={{ zIndex: 1,
                    color: '#fff',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignItems: 'center',}}
                />
      </div>
    )
    break; 
  case '610':
      text ='Print G/L Sales Recap-Detail'
      return(
        <div>
          <img
                    className=" w-2/5 object-cover"
                    src={GLSaleRecapDetails}
                    alt="logo"
                    style={{ zIndex: 1,
                      color: '#fff',
                      width: 100,
                      height: 100,
                      display: 'flex',
                      
                      justifyContent: 'center',
                      alignItems: 'center',}}
                  />
        </div>
      )
      break;
  case '620':
      text ='Sales Update'
      return(
        <div>
          <img
                    className=" w-2/5 object-cover"
                    src={SalesUpdate}
                    alt="logo"
                    style={{ zIndex: 1,
                      color: '#fff',
                      width: 100,
                      height: 100,
                      display: 'flex',
                      
                      justifyContent: 'center',
                      alignItems: 'center',}}
                  />
        </div>
      )
      break;
  case '999':
      text ='Complete - Ready to Purge'
      return(
        <div>
          <img
                    className=" w-2/5 object-cover"
                    src={Ordercompleted}
                    alt="logo"
                    style={{ zIndex: 1,
                      color: '#fff',
                      width: 100,
                      height: 100,
                      display: 'flex',
                      
                      justifyContent: 'center',
                      alignItems: 'center',}}
                  />
        </div>
      )
      break;
  case '980':
      text ='Cancel Order'
      return(
        <div>
          <img
                    className=" w-2/5 object-cover"
                    src={CancelSaleOrder}
                    alt="logo"
                    style={{ zIndex: 1,
                      color: '#fff',
                      width: 100,
                      height: 100,
                      display: 'flex',
                      
                      justifyContent: 'center',
                      alignItems: 'center',}}
                  />
        </div>
      )
      break;               
}
  
}


const renderStep = (user) =>{
let date1 = moment(user.F42199_UPMJ).format('DD/MM/YYYY')
let reuested_date = moment(user.F42199_DRQJ).format('DD/MM/YYYY')
let statusname  = user.F42199_LTTR
let text =''
switch (statusname) {
  case '520':
    text ='Enter Order'
    break;
  case '525':
    text ='Print Acknowledgement'
    break;
  case '535':
    text ='In Warehouse'
    break;
  case '540':
    text ='Print Pick'
    break;
  case '545':
    text ='Pick Confirmation'
    break;
  case '550':
    text ='Print Shipping Documents'
    break;
  case '555':
    text ='Pack Confirmation'
    break;
  case '560':
    text ='Ship Confirmation'
    break;
  case '565':
    text ='Print Delivery Notes'
    break;
  case '575':
      text ='Cycle Billing'
      break; 
  case '578':
    text ='EDI Receiving Advice Reject'
    break;
  case '580':
    text ='Print Invoices'
    break;
  case '585':
    text ='Print Interbranch Invoice'
    break;
  case '600':
    text ='Invoice Journal'
    break; 
  case '610':
      text ='Print G/L Sales Recap-Detail'
      break;
  case '620':
      text ='Sales Update'
      break;
  case '999':
      text ='Complete - Ready to Purge'
      break;
  case '980':
      text ='Cancel Order'
      break;               
}
return(
   <Step key={user.F42199_USER}>
            <StepLabel  icon={renderIcon(user)} >
          Order Status : {text} <br/>
          Created On :  {date1}<br/>
          Created By : {user.F42199_USER}<br/>
          Requested Date : {user.reuested_date}
          </StepLabel>
          
          </Step>
)
}
 
  return (
    <div className="bg-white py-5 px-3">
      <div className="md:w-full mb-3 w-full  mx-auto">
        <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
          <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">
            <div className="flex justify-between">
              {/* <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">
                Data
              </h2> */}
              {/* <Button
                color="light"
                size="xs"
                className=" min-w-[100px] py-2 text-xs rounded-2xl bg-[#4e73df] text-white"
                onClick={() => goBack()}
              >
                Back
              </Button> */}
            </div>
            <div>
              {responseFetchRecordData !== undefined ? (
                
                  <LoadingComponent
                    isLoading={isCPLoading}
                    component={
                      <div >
                      
          
          <div style={{margin: '50px'}}>
          <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
      {responseFetchRecordData.map((user) => (
        renderStep(user)
      ))}
      </Stepper>
      </Box>
      
      
    </div>
         
                      </div>
                    }
                  />
                
              ) : (
                <p>No data available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


