import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { removeAuthState } from '../redux/features/authStateSlice';
import { removeHomeSliceState } from '../redux/features/homeslice';

export default function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutuser = () => {
    dispatch(removeAuthState());
    dispatch(removeHomeSliceState());
    localStorage.clear();
    Swal.fire({
              title: 'Done',
              text: 'Logout Successfully',
              icon: "success",
              timer: 3000,
              showConfirmButton: false
            })
    navigate("/")
  }

  useEffect(()=>{
    logoutuser();
  },[])
  return (
    <div>
      <span>LogoutPage</span>
    </div>
  )
}