import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../components/LoadingComponent';
import { ProductionMTDDATA } from '../../redux/features/homeslice';

export default function CurrentMonthProduction() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [total, setTotal] = useState(0)
  const curentYear = moment().year();;
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('month').format('DD/MM/YYYY');
  const [qty, setQty] = useState(0)
  const endOfMonth = moment().endOf('month').format('DD/MM/YYYY');
  const subtractDate = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');

  const customerdataList = {
    "token": token,
    "targetName": "V4111B",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "10000",
    "returnControlIDs": "F4111.TRQT|F4111.PAID",
    "query": {
      "autoFind": true,
      "condition":
        [{
          "value":
            [{
              "content": subtractDate,//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F4111.TRDJ",//reciept date
          "operator": "GREATER"
        },
        {
          "value":
            [{
              "content": nextDate,//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F4111.TRDJ",//reciept date
          "operator": "LESS"
        },
        {
          "value":
            [{
              "content": "IC",//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F4111.DCT",//reciept date
          "operator": "EQUAL"
        },
          // {
          //   "value":
          //     [{
          //       "content": "TRUM",//reciept date
          //       "specialValueId": "LITERAL"
          //     }
          //     ],
          //   "controlId": "F4111.EA",//reciept date
          //   "operator": "EQUAL"
          // },
        ]
    }
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(ProductionMTDDATA(customerdataList)).unwrap();
    setIsCPLoading(false);

  }


  useEffect(() => {
    if (apiData.status === 'succeeded') {
      if (apiData?.productionMTD) {
        if (apiData.productionMTD === undefined) {
          if (apiData.productionMTD.fs_DATABROWSE_V4111B == undefined || apiData.productionMTD.fs_DATABROWSE_V4111B == null) {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        } else {
          if (minutes != 60) {
            if (apiData.productionMTD.fs_DATABROWSE_V4111B != null || apiData.productionMTD.fs_DATABROWSE_V4111B != undefined) {
              const data = apiData.productionMTD.fs_DATABROWSE_V4111B.data.gridData.rowset;
              setCustomerList(data)
              getTotalamount(data)
            }
            else {
              (async () => {
                const defaultData = await getCustomerList();
              })()
            }
          }
          else {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        }
      }
      else{
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }else{
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }, [apiData]);

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V4111B.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       setIsCPLoading(false);
  //     })
  // }


  const getTotalamount = (data_save) => {
    let settotal = (data_save.reduce((a, v) => a = a + v.F4111_PAID, 0))
    const formattedAmount = settotal.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    setTotal(formattedAmount)

    let setqty = (data_save.reduce((a, v) => a = a + v.F4111_TRQT, 0))
    setQty(setqty.toFixed(2))
  }

  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })() 
  // }, []);

  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
          <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Current Month Production</p>
          </div>
          <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
            
            <div className="grid-container2">
              <div className="grid-item2"><p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Amount</p> <p className='fontSmaller' style={{ color: 'rgb(17 24 39)' }}>{total}</p></div>
              <div className="grid-item2"> <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Quantity</p><p className='fontSmaller' style={{ color: 'rgb(17 24 39)' }}>{qty}</p></div>
            </div>
          </div>
        </div>
      )} />
    </div>

  )
}