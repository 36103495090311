import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../../../components/LoadingComponent';
import AxiosMain from '../../../../utilits/const';


export default function ReceiptDraftYTD() {
  const userauth = useSelector((state) => state.auth);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [customerListvod, setCustomerListVOD] = useState([]);
  const [customerListeck, setCustomerListECK] = useState([]);
  const [customerListnsf, setCustomerListNSF] = useState([]);
  const [sortResult, setSortResult] = useState([]);
  const [total, setTotal] = useState(0)
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")
  const [r1, setR1] = useState(0)

  const [vod, setVOD] = useState(0)
  const [eck, setECK] = useState(0)
  const [nsf, setNSF] = useState(0)

  const customerdataList = {
    "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.CKAM|F03B13.ICUT|F03B13.VLDT",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "DB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": subtractDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": nextDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "LESS"
          },

        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B14.PAAP",
        "direction": "DESC"
      },
      ]
    }
  }

  const customerdataList1 = {
    "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.CKAM|F03B13.ICUT|F03B13.VLDT",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "DB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": subtractDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": nextDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "LESS"
          },
          {
            "value":
              [{
                "content": "VOD",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.VRE",
            "operator": "EQUAL"
          },

        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B14.PAAP",
        "direction": "DESC"
      },
      ]
    }
  }

  const customerdataList2 = {
    "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.CKAM|F03B13.ICUT|F03B13.VLDT",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "DB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": subtractDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": nextDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "LESS"
          },
          {
            "value":
              [{
                "content": "ECK",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.VRE",
            "operator": "EQUAL"
          },

        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B14.PAAP",
        "direction": "DESC"
      },
      ]
    }
  }

  const customerdataList3 = {
    "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.CKAM|F03B13.ICUT|F03B13.VLDT",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "DB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": subtractDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": nextDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "LESS"
          },
          {
            "value":
              [{
                "content": "NSF",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.VRE",
            "operator": "EQUAL"
          },

        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B14.PAAP",
        "direction": "DESC"
      },
      ]
    }
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const getCustomerList = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataList,
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;
          setCustomerList(data_save)
          setIsCPLoading(false);
          getTotalamount(data_save)
          getCustomerListVOD();
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  const getCustomerListVOD = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataList1,
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;
          setCustomerListVOD(data_save)
          setIsCPLoading(false);
          getTotalamountVOD(data_save)
          getCustomerListECK();
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  const getCustomerListECK = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataList2,
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;
          setCustomerListECK(data_save)
          setIsCPLoading(false);
          getTotalamountECK(data_save)
          getCustomerListNSF();
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  const getCustomerListNSF = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataList3,
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;
          setCustomerListNSF(data_save)
          setIsCPLoading(false);
          getTotalamountNSF(data_save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }

  const getTotalamount = (data_save) => {
    var result = [];
    data_save.reduce(function (res, value) {
      if (!res[value.F03B13_ICUT]) {
        res[value.F03B13_ICUT] = { F03B13_ICUT: value.F03B13_ICUT, F03B13_CKAM: 0 };
        result.push(res[value.F03B13_ICUT])
      }
      res[value.F03B13_ICUT].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});

    setSortResult(result)
    result.map((values) => {
      if (values.F03B13_ICUT === "DB") {
        const value = values.F03B13_CKAM.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setR1(value)
      }

    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }


  const getTotalamountVOD = (data_save) => {
    var result = [];
    data_save.reduce(function (res, value) {
      if (!res[value.F03B13_ICUT]) {
        res[value.F03B13_ICUT] = { F03B13_ICUT: value.F03B13_ICUT, F03B13_CKAM: 0 };
        result.push(res[value.F03B13_ICUT])
      }
      res[value.F03B13_ICUT].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});

    setSortResult(result)
    result.map((values) => {
      if (values.F03B13_ICUT === "DB") {
        const value = values.F03B13_CKAM.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setVOD(value)
      }

    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const getTotalamountECK = (data_save) => {
    var result = [];
    data_save.reduce(function (res, value) {
      if (!res[value.F03B13_ICUT]) {
        res[value.F03B13_ICUT] = { F03B13_ICUT: value.F03B13_ICUT, F03B13_CKAM: 0 };
        result.push(res[value.F03B13_ICUT])
      }
      res[value.F03B13_ICUT].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});

    setSortResult(result)
    result.map((values) => {
      if (values.F03B13_ICUT === "DB") {
        const value = values.F03B13_CKAM.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setECK(value)
      }

    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const getTotalamountNSF = (data_save) => {
    var result = [];
    data_save.reduce(function (res, value) {
      if (!res[value.F03B13_ICUT]) {
        res[value.F03B13_ICUT] = { F03B13_ICUT: value.F03B13_ICUT, F03B13_CKAM: 0 };
        result.push(res[value.F03B13_ICUT])
      }
      res[value.F03B13_ICUT].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});

    setSortResult(result)
    result.map((values) => {
      if (values.F03B13_ICUT === "DB") {
        const value = values.F03B13_CKAM.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        setNSF(value)
      }

    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  useEffect(() => {
    getCustomerList();

  }, []);


  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem] textAlign">Draft Receipts:<span className='fontSmaller text-rgb(17 24 39)-600' >{r1}</span></p>
          </div>
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-slate-700 fontSmaller font-bold leading-loose ml-[0.75rem] textAlign">Void Draft:<span className='fontSmaller text-rgb(17 24 39)-600' >{vod}</span></p>
          </div>
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-slate-700 fontSmaller font-bold leading-loose ml-[0.75rem] textAlign">Error Draft:<span className='fontSmaller text-rgb(17 24 39)-600' >{eck}</span></p>
          </div>
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-slate-700 fontSmaller font-bold leading-loose ml-[0.75rem] textAlign">Non Sufficient Draft:<span className='fontSmaller text-rgb(17 24 39)-600' >{nsf}</span></p>
          </div>
        </div>
      )} />
    </div>

  )
}