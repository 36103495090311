import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../components/LoadingComponent';
import AxiosMain from '../../utilits/const';


import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ArrowDownWard from '@mui/icons-material/ArrowDownwardOutlined';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';

import { tableCustomStyles } from '../TableStyle';


import { SalesYTDDATA } from '../../redux/features/homeslice';

export default function YearToDateSales() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [total, setTotal] = useState(0)
  const curentYear = moment().year();;
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")

  const [se, setSE] = useState(0)
  const [so, setSO] = useState(0)
  const [co, setCO] = useState(0)
  const [sn, setSN] = useState(0)
  const [sortResult, setSortResult] = useState([]);
  const [sumofallvalues, setSumOFAll] = useState(0)

  const [isCPLoadingfy, setIsCPLoadingFY] = useState(false);
  const [customerListfy, setCustomerListFY] = useState([]);
  const [sortResultFY, setSortResultFY] = useState([]);
  const [clickedValue, setclickValue] = useState();


  const [openFY, setOpenFY] = React.useState(false);

  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(SalesYTDDATA(customerdataList)).unwrap();
    setIsCPLoading(false);

  }


  useEffect(() => {
    if (apiData.status === 'succeeded') {
      console.log('')
      if (apiData?.salesytdData) {
        if (apiData.salesytdData === undefined) {
          if (apiData.salesytdData.fs_DATABROWSE_V554219A == undefined || apiData.salesytdData.fs_DATABROWSE_V554219A == null) {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        } else {
          if (minutes != 60) {
            if (apiData.salesytdData.fs_DATABROWSE_V554219A != null || apiData.salesytdData.fs_DATABROWSE_V554219A != undefined) {
              const data = apiData.salesytdData.fs_DATABROWSE_V554219A.data.gridData.rowset;
              setCustomerList(data)
              getTotalamount(data)
            }
            else {
              (async () => {
                const defaultData = await getCustomerList();
              })()
            }
          }
          else {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        }
      }
      else{
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }else{
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }, [apiData]);


  const sortIcon = <ArrowDownWard />;

  const customerdataList = {
    "token": token,
    "targetName": "V554219A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "100000",
    "returnControlIDs": "F42119.AEXP|F42119.SOQS|F42119.DOCO|F42119.LNID|F42119.DCTO|F42119.IVD",
    "query": {
      "autoFind": true,
      "condition":
        [
          // {
          //   "value" : 
          //   [ {
          //     "content" : "SO",//from Drop Down list of Order types
          //     "specialValueId" : "LITERAL"
          //     } 
          //   ],
          //   "controlId" : "F4211.DCTO",
          //   "operator" : "EQUAL"
          // },
          // {
          //   "value" : 
          //   [ {
          //     "content" : "620",
          //     "specialValueId" : "LITERAL"
          //     } 
          //   ],
          //   "controlId" : "F4211.LTTR",
          //   "operator" : "EQUAL"
          // },
          {
            "value":
              [{
                "content": "580",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.NXTR",
            "operator": "GREATER"
          },
          // {
          //   "value" : 
          //   [ {
          //     "content" : "23",//last 2 digits of curretnt Year
          //     "specialValueId" : "LITERAL"
          //     } 
          //   ],
          //   "controlId" : "F4211.FY",
          //   "operator" : "EQUAL"
          // },
          {
            "value":
              [{
                "content": subtractDate,//date less than 1 date example today's date is 15/08/2023 then it should calculate //last month's last date
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.IVD",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": "0",//last 2 digits of curretnt Year
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.LNID",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": nextDate,//date greater than 1 date for today's date plus 1 date
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F42119.IVD",
            "operator": "LESS"
          }
        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F42119.IVD",
        "direction": "ASCE"
      },
        // {
        //   "column": "F4211.DOCO",
        //   "direction": "ASC"
        // }
      ]
    }
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }



  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {

  //         let data_save = res.data.fs_DATABROWSE_V554219A.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }


  const getTotalamount = (data_save) => {
    var result = [];
    var soValue = 0;
    var coValue = 0;
    var seValue = 0;
    var snValue = 0;
    data_save.reduce(function (res, value) {
      if (!res[value.F42119_DCTO]) {
        res[value.F42119_DCTO] = { F42119_DCTO: value.F42119_DCTO, F42119_AEXP: 0 };
        result.push(res[value.F42119_DCTO])
      }
      res[value.F42119_DCTO].F42119_AEXP += value.F42119_AEXP;
      return res;
    }, {});

    setSortResult(result)
    result.map((values) => {
      if (values.F42119_DCTO === "SO") {
        const value = values.F42119_AEXP
        soValue = value;
        setSO(value)
      }
      if (values.F42119_DCTO === "CO") {
        const value2 = values.F42119_AEXP
        coValue = value2;
        setCO(value2)
      }
      if (values.F42119_DCTO === "SE") {
        const value3 = values.F42119_AEXP
        seValue = value3;
        setSE(value3)
      }
      if (values.F42119_DCTO === "SN") {
        const value4 = values.F42119_AEXP
        snValue = value4;
        setSN(value4)
      }

    })

    let summation = (soValue + coValue + seValue + snValue)
    let summation2 = summation.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    setSumOFAll(summation2)
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // se tTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })()
  // }, []);


  const labels = [
    `Local Sales: ${so.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Export Sales: ${se.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Credit Order: ${co.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Service Order: ${sn.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
  ];
  const options = {
    chart: {
      type: 'pie',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          onGraphclick(config.dataPointIndex)
          // console.log(config.w.config.labels[config.dataPointIndex])
        }
      }
    },
    labels: labels,
    colors: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 206, 86, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(153, 102, 255, 0.8)',
    ], // Colors in hexadecimal format
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  const series = [
    parseFloat(so.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(se.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(co.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(sn.toString().replace(/[^0-9.-]+/g, ''))
  ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getCustomerList();
  //   }, [1000]);
  //   return () => clearInterval(interval);
  // }, []);

  const onGraphclick = async (elements, chart) => {
    let clickedValue = elements;
    if (clickedValue === 0) {
      setclickValue("Local Sales")
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V564219A",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F42119.DOCO|F42119.DCTO|F42119.DOC|F42119.DCT|F42119.IVD|F42119.AN8|F0101.ALPH|F42119.LITM|F42119.DSC1|F42119.SOQS|F42119.AEXP|F42119.ECST|F42119.SRP1",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": subtractDate,
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.IVD",
                  "operator": "GREATER"
                },
                {
                  "value":
                    [{
                      "content": "SO", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.DCTO",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": nextDate,
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.IVD",
                  "operator": "LESS"
                },

              ]
          },
          "aggregation": {
            "orderBy": [{
              "column": "F42119.AEXP",
              "direction": "DESC"
            },
            ]
          }
        },
        config)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V564219A.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
            // getTotalamountDB(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
    if (clickedValue === 1) {
      setclickValue("Export Sales")

      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V564219A",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F42119.DOCO|F42119.DCTO|F42119.DOC|F42119.DCT|F42119.IVD|F42119.AN8|F0101.ALPH|F42119.LITM|F42119.DSC1|F42119.SOQS|F42119.AEXP|F42119.ECST|F42119.SRP1",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": subtractDate,
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.IVD",
                  "operator": "GREATER"
                },
                {
                  "value":
                    [{
                      "content": "SE", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.DCTO",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": nextDate,
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.IVD",
                  "operator": "LESS"
                },

              ]
          },
          "aggregation": {
            "orderBy": [{
              "column": "F42119.AEXP",
              "direction": "DESC"
            },
            ]
          }
        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V564219A.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
    if (clickedValue === 2) {
      setclickValue("Credit Order")
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V564219A",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F42119.DOCO|F42119.DCTO|F42119.DOC|F42119.DCT|F42119.IVD|F42119.AN8|F0101.ALPH|F42119.LITM|F42119.DSC1|F42119.SOQS|F42119.AEXP|F42119.ECST|F42119.SRP1",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": subtractDate,
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.IVD",
                  "operator": "GREATER"
                },
                {
                  "value":
                    [{
                      "content": "CO", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.DCTO",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": nextDate,
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.IVD",
                  "operator": "LESS"
                },

              ]
          },
          "aggregation": {
            "orderBy": [{
              "column": "F42119.AEXP",
              "direction": "DESC"
            },
            ]
          }
        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V564219A.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
    if (clickedValue === 3) {
      setclickValue("Service Order")
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V564219A",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F42119.DOCO|F42119.DCTO|F42119.DOC|F42119.DCT|F42119.IVD|F42119.AN8|F0101.ALPH|F42119.LITM|F42119.DSC1|F42119.SOQS|F42119.AEXP|F42119.ECST|F42119.SRP1",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": subtractDate,
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.IVD",
                  "operator": "GREATER"
                },
                {
                  "value":
                    [{
                      "content": "SN", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.DCTO",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": nextDate,
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F42119.IVD",
                  "operator": "LESS"
                },

              ]
          },
          "aggregation": {
            "orderBy": [{
              "column": "F42119.AEXP",
              "direction": "DESC"
            },
            ]
          }
        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V564219A.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
  }

  const columnsLable = [
    {
      id: 'doco',
      name: 'Order Number',
      sortable: true,
      selector: (row) => row.F42119_DOCO,
      cell: (row) => <div>{row.F42119_DOCO}</div>,
    },
    {
      id: 'dcto',
      name: 'Order Type',
      sortable: true,
      selector: (row) => <div>{row.F42119_DCTO}</div>,
      cell: (row) => <div>{row.F42119_DCTO}</div>,
    },
    {
      id: 'doc',
      name: 'Invoice Number',
      sortable: true,
      selector: (row) => <div>{row.F42119_DOC}</div>,
      cell: (row) => <div>{row.F42119_DOC}</div>,
    },
    {
      id: 'dct',
      name: 'Invoice Type',
      sortable: true,
      selector: (row) => <div>{row.F42119_DCT}</div>,
      cell: (row) => <div>{row.F42119_DCT}</div>,
    },
    {
      id: 'ivd',
      name: 'Invoice Date',
      sortable: true,
      selector: (row) => <div>{moment(row.F42119_IVD).format('DD/MM/YYYY')}</div>,
      cell: (row) => <div>{row.F42119_IVD}</div>,
    },
    {
      id: 'an8',
      name: 'Customer No',
      sortable: true,
      selector: (row) => <div>{row.F42119_AN8}</div>,
      cell: (row) => <div>{row.F42119_AN8}</div>,
    },
    {
      id: 'alph',
      name: 'Customer Name',
      sortable: true,
      selector: (row) => <div>{row.F0101_ALPH}</div>,
      cell: (row) => <div>{row.F0101_ALPH}</div>,
    },
    {
      id: 'litm',
      name: 'Item Number',
      sortable: true,
      selector: (row) => <div>{row.F42119_LITM}</div>,
      cell: (row) => <div>{row.F42119_LITM}</div>,
    },
    {
      id: 'dsc1',
      name: 'Item Description',
      sortable: true,
      selector: (row) => <div>{row.F42119_DSC1}</div>,
      cell: (row) => <div>{row.F42119_DSC1}</div>,
    },
    {
      id: 'soqH',
      name: 'Qty Shipped',
      sortable: true,
      selector: (row) => <div>{row.F42119_SOQS}</div>,
      cell: (row) => <div>{row.F42119_SOQS}</div>,
    },
    {
      id: 'aexp',
      name: 'Extended Price',
      sortable: true,
      selector: (row) => <div>{row.F42119_AEXP}</div>,
      cell: (row) => <div>{row.F42119_AEXP}</div>,
    },
    {
      id: 'ecst',
      name: 'Extended Cost',
      sortable: true,
      selector: (row) => <div>{row.F42119_ECST}</div>,
      cell: (row) => <div>{row.F42119_ECST}</div>,
    },
    {
      id: 'srp1',
      name: 'Item Group',
      sortable: true,
      selector: (row) => <div>{row.F42119_SRP1}</div>,
      cell: (row) => <div>{row.F42119_SRP1}</div>,
    },

  ]

  const handleCloseFY = () => {
    setOpenFY(false);
  };


  return (
    <div >
      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFY}
        onClose={handleCloseFY}
        id="FyModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Yearly Sales Summary - {clickedValue}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFY}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfy} component={(
                <DataTableExtensions columns={columnsLable}
                  data={customerListfy}
                  export={true}
                  print={false}
                  filterPlaceholder="Search Here"
                >
                  <DataTable
                    className="cm-category-admin-table"
                    defaultSortFieldId="orderNumber"
                    sortable={true}
                    columns={columnsLable}
                    data={customerListfy}
                    striped
                    pagination
                    customStyles={tableCustomStyles}
                    expandableRows={false}
                    sortIcon={sortIcon}
                    fixedHeader={false}
                    pointerOnHover={true}
                    selectableRowsHighlight={true}
                    selectableRowsSingle={true}
                    selectableRowsVisibleOnly={true}
                    style={{ width: '100px' }}
                    overflowY={true}
                    progressPending={isCPLoadingfy}

                  // subHeaderComponent={subHeaderComponentMemo}
                  // subHeader={true}
                  />
                </DataTableExtensions>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-gray-500 fontSmaller font-bold leading-loose " style={{ color: 'rgb(30, 74, 109,0.6)' }}>Sales YTD </p>
          </div>
          <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
            <span style={{ color: 'rgb(17 24 39)', fontSize: 22 }}>{sumofallvalues}</span>
          </div>

          <div className="items-center justify-center my-5 m-0" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <ReactApexChart options={options} series={series} type="pie" width={350} height={300} />
          </div>
        </div>
      )} />
    </div>

  )
}