import { createSlice } from "@reduxjs/toolkit";


const initialState= {
  data: {},
  profiledata: {},
  
};

export const authStateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.data = action.payload;
    },
    setProfileState: (state, action) => {
      state.profiledata = action.payload;
    },
    removeAuthState: (state) => {
      state.data = {};
      state.profiledata = {};
    },
  }
});

export const {
  setAuthState,
  removeAuthState,
  setProfileState,
} = authStateSlice.actions;

export default authStateSlice.reducer;