import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../redux/components/LoadingComponent';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import "chartjs-plugin-datalabels";

import { Chart } from "chart.js";
import { PurchaseTop10ItemByAmountDATA } from '../../redux/features/homeslice';

export default function Top10ItemByAmount() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [top10Result, setTop10Result] = useState([]);
  const [sortResult, setSortResult] = useState([]);
  const [total, setTotal] = useState(0)
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastmonthlastdate=moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY')
  const lastDate =moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  Chart.register(ChartDataLabels);


  const customerdataList = {
     
  "token" : token,
  "targetName" : "V5543121",
  "targetType" : "view",
  "dataServiceType" : "BROWSE", 
  "maxPageSize": "100000", 
  "returnControlIDs" : "F43121.AREC|F43121.LITM|F4101.DSC1",
  "query" : {
    "autoFind" : true,
    "condition" : 
    [ {
      "value" : 
      [ {
        "content" : subtractDate,//reciept date
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F43121.RCDJ",//reciept date
      "operator" : "GREATER"
     },
     {
      "value" : 
      [ {
        "content" : nextDate,//reciept date
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F43121.RCDJ",//reciept date
      "operator" : "LESS"
     },
     {
      "value":
        [{
          "content": "1",//reciept date
          "specialValueId": "LITERAL"
        }
        ],
      "controlId": "F43121.MATC",//reciept date
      "operator": "EQUAL"
    },
  ]
  },
  "aggregation" : {
    "orderBy" : [ {
      "column" : "F43121.AREC",
      "direction" : "DESC"
    }
    ],
    "groupBy":[
        {
           "column" : "F43121.LITM"
        }
    ]
  }
  }


  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V5543121.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }

  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(PurchaseTop10ItemByAmountDATA(customerdataList)).unwrap();
    setIsCPLoading(false);

  }


  useEffect(() => {
    if (apiData.status === 'succeeded') {
      if (apiData?.purchaseTop10ItemByAmountData) {
        if (apiData.purchaseTop10ItemByAmountData === undefined) {
          if (apiData.purchaseTop10ItemByAmountData.fs_DATABROWSE_V5543121 == undefined || apiData.purchaseTop10ItemByAmountData.fs_DATABROWSE_V5543121 == null) {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        } else {
          if (minutes != 60) {
            if (apiData.purchaseTop10ItemByAmountData.fs_DATABROWSE_V5543121 != null || apiData.purchaseTop10ItemByAmountData.fs_DATABROWSE_V5543121 != undefined) {
              const data = apiData.purchaseTop10ItemByAmountData.fs_DATABROWSE_V5543121.data.gridData.rowset;
              setCustomerList(data)
              getTotalamount(data)
            }
            else {
              (async () => {
                const defaultData = await getCustomerList();
              })()
            }
          }
          else {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        }
      }
      else{
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }else{
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }, [apiData]);

  const getTotalamount = (data_save) => {
    var result = [];
    data_save.reduce(function(res, value) {
      if (!res[value.F43121_LITM]) {
        res[value.F43121_LITM] = { F43121_LITM: value.F43121_LITM, F43121_AREC: 0,F4101_DSC1:value.F4101_DSC1 };
        result.push(res[value.F43121_LITM])
      }
      res[value.F43121_LITM].F43121_AREC += value.F43121_AREC;
      return res;
    }, {});
    setSortResult(result)
    const  sorted = result.sort((a,b) => b.F43121_AREC - a.F43121_AREC);
    const firstTen = sorted.slice(0, 10);
    setTop10Result(firstTen)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  // useEffect(() => {
  //   getCustomerList();

  // }, []);

  const state = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: top10Result.map(o => o.F4101_DSC1),
    datasets: [
      {
        label: 'Top 10 Items By Amount',
        backgroundColor: [
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(201, 203, 207, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
        
        ],
        // borderColor: [
        //   'rgb(255, 99, 132)',
        //   'rgb(255, 159, 64)',
        //   'rgb(255, 205, 86)',
        //   'rgb(75, 192, 192)',
        //   'rgb(54, 162, 235)',
        //   'rgb(153, 102, 255)',
        //   'rgb(201, 203, 207)'
        // ],
        // backgroundColor:'rgb(47, 117, 181)',
        borderColor:'#fff',
        data: top10Result.map(o => parseFloat(o.F43121_AREC).toFixed(2)),
      },
    ],
  }

  const options = {
    indexAxis: "y",
  scales: {
    x: {
      stacked: true,
      display: false
    },
    y: {
      stacked: true
    }
  },
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom"
    },
    title: {
      display: true,
      text: "Top 10 Item By Amount"
    }
  }
  };

  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="grid-container4">
          <div className='grid-item'>
              
              <Bar

                data={state}  
                // options={{
                //   responsive: true,
                //   indexAxis: 'y',
                //   plugins: {
                //     legend: {
                //       position: 'right',
                //     },
                //     title: {
                //       display: true,
                //       text: 'Top 10 Item By Amount',
                //       font: {
                //         size: 16,
                        
                //       }
                //     }
                //   },
                
                //}}
                options={options}
                plugins={[ChartDataLabels]} 
              />
          </div>

          
        </div>
      )} />
    </div>

  )
}