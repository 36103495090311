import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select, { createFilter } from 'react-select';
import Swal from 'sweetalert2';
import LoadingComponent from '../../components/LoadingComponent';
import AxiosMain from '../../utilits/const';


export default function CustBal() {
  const userauth = useSelector((state) => state.auth);
const [isCPLoading, setIsCPLoading] = useState(false);
const [responseData, setResponseData] = useState([]);
const token = userauth.data.data.userInfo.token
const [customerList, setCustomerList] = useState([]);
const [dropDownValue, setDropDrownValue] = useState();
const [total, setTotal] = useState(0);
const curentDate = moment(new Date()).format("DD/MM/YYYY");


const customerdataList = {
  "token": token,
  "targetName": "V0101C",
  "targetType": "view",
  "dataServiceType": "BROWSE",
  // "enableNextPageProcessing" : true,
  "maxPageSize": "10000",
  "returnControlIDs": "F0101.AN8|F0101.ALPH",
  "query": {
    "autoFind": true,
    "condition":
      [
        {
          "value":
            [{
              "content": "0",//Address Number Sold To
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F0101.AN8",
          "operator": "GREATER"
        }
      ]
  }
}

const config = {
  headers: {
    "Content-Type": "application/json",
  },
}

const getCustomerList = async () => {
  setIsCPLoading(true);
  await AxiosMain.post(`/jderest/v2/dataservice`,
    customerdataList,
    config)
    .then((res) => {
      if (res.status === 200) {
        setCustomerList(res.data.fs_DATABROWSE_V0101C.data.gridData.rowset)
        setIsCPLoading(false);
      }
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      setIsCPLoading(false);
    })
}

const renderCustomerList = () => {
  return (customerList.map(data => ({ label: data.F0101_ALPH, value: data.F0101_AN8 })))
}

useEffect(() => {
  getCustomerList();
}, []);

const setCustomValue = (selectedOption) => {
  setDropDrownValue(selectedOption.value)
  setIsCPLoading(true);
    AxiosMain.post(`/jderest/v2/dataservice`,
    {
      "token" : token,
  "targetName" : "V03B11PMF",
  "targetType" : "view",
  "dataServiceType" : "BROWSE",  
  "maxPageSize" : "100000",
  "returnControlIDs" : "F03B11.AN8|F03B11.ALPH|F03B11.AAP|F03B11.DDJ",
  "query" : {
    "autoFind" : true,
    "condition" : 
    [ 
    {
      "value" : 
      [ {
        "content" : selectedOption.value,
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F03B11.AN8",
      "operator" : "EQUAl"
    },
    {
        "value" : 
      [ {
        "content" : curentDate,
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F03B11.DDJ",
      "operator" : "GREATER"
    },
    {
        "value" : 
      [ {
        "content" : "20",
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F03B11.CTRY",
      "operator" : "EQUAL"
    },
    {
      "value" : 
      [ {
        "content" : "D",
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F03B11.POST",
      "operator" : "EQUAl"
    },
    {
      "value" : 
      [ {
        "content" : "23",
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F03B11.FY",
      "operator" : "EQUAl"
    }
     ]
  }
    },
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_Save = res.data.fs_DATABROWSE_V03B11PMF.data.gridData.rowset
          setResponseData(data_Save)
          setIsCPLoading(false);
          getTotalamount(data_Save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })

}


const getTotalamount = (data_save) => {
  let settotal = (data_save.reduce((a, v) => a = a + v.F03B11_AAP, 0))
  setTotal(settotal.toFixed(2))
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: 'none',
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "yellow" : "rgb(17 24 39)",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "rgb(17 24 39)" : "blue"
    }
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0
  })
};

return (
  <div>
  <LoadingComponent isLoading={isCPLoading} component={(
    <div className="flex flex-wrap items-center justify-center my-5 m-0">
      <div className='flex flex-col gap-y-4 w-full max-w-md mb-0'>
      <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Customer Balance </p>
      </div>
      <div className='flex flex-col gap-y-4 w-[80%] max-w-md mb-0'>
          {/* <Label>Supplier Name</Label> */}
          <Select
            options={renderCustomerList()}
            className='border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base'
            isSearchable={true}
            placeholder="Customer Name"
            onChange={(e) => setCustomValue(e)}
            filterOption={createFilter({ ignoreAccents: false })}
            isClearable={false}
            backspaceRemovesValue={true}
            escapeClearsValue={true}
            styles={customStyles}
            />
      </div>
      <div className='flex flex-col gap-y-4 w-full max-w-md mb-0'>
        <span style={{color:'rgb(17 24 39)',fontSize:22}}>{total.toFixed(2)}</span>
      </div>
    </div>
  )} />
</div>
)
}