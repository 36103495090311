import { Chart, registerables } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { removeAuthState } from '../../redux/features/authStateSlice';
import AxiosMain from '../../utilits/const';
import NetApAsOnDate from './netAP/netAPAsOnDate/netAPAsOnDate';
import PaymentYTD from './netAP/paymentYTD/paymentYTD';
import PaymentMTD from './netAP/paymentMTD/paymentMonthly'
import PaymentDaily from './paymentDaily';
Chart.register(...registerables)


export default function FinanceAP() {

  const dispatch = useDispatch();
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token
  const navigate = useNavigate();
  const [isCPLoading, setIsCPLoading] = useState(false);


 

  

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const validTokenData ={
    "token": token,
  }
  const checktokenValid = async () =>{
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/tokenrequest/validate`,
    validTokenData,
      config)
      .then((res) => {
        if (res.isValidSession == false) {
          Swal.fire({
            title: 'Warning',
            text: 'Token Expired.',
            icon: "warning",
          });          
          setIsCPLoading(false);
          dispatch(removeAuthState());
          navigate("/");
        }else{
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
      setIsCPLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checktokenValid();
    }, [3600000]);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div className="bg-grey py-1 ">
        <div className='md:w-full mb-3 w-full '>
          <div className="rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'></div>
              {/* <!-- Content Row --> */}
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '10px', maxWidth: '100%', margin: '0 auto' }}>
    <div className="grid-item bg-white shadow" style={{ flex: '1' }}><NetApAsOnDate /></div>
    <div className="grid-item bg-white shadow" style={{ flex: '1' }}><PaymentYTD /></div>
    <div className="grid-item bg-white shadow" style={{ flex: '1' }}><PaymentMTD /></div>
    {/* <div className="grid-item bg-white shadow" style={{ flex: '1' }}><PaymentDaily /></div> */}
</div>


            </div>
          </div>
        </div>
      </div>

      <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
               <div className="grid-item bg-white shadow"><PaymentDaily /></div>
                {/* <div className="grid-item bg-white shadow"><PayemntType /></div>
                <div className="grid-item bg-white shadow"><PaymentMTDType /></div> */}
                {/* <div className="grid-item bg-white shadow"><YTDGraphReciptDetail /></div>
                <div className="grid-item bg-white shadow"><MTDGraphReciptDetail /></div> */}
                {/* <div className="grid-item bg-white shadow"><DailyReceipts /></div> */}
               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
                <div className="grid-item bg-white shadow"><NetAPByDCT /></div>
                <div className="grid-item bg-white shadow"><PayemntType /></div>
                <div className="grid-item bg-white shadow"><PaymentMTDType /></div>
                
               
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
                <div className="grid-item bg-white shadow"><NetPD /></div>
                <div className="grid-item bg-white shadow"><PayemntDraft /></div>
                <div className="grid-item bg-white shadow"><PaymentMTDDraft /></div>
                
               
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
                <div className="grid-item bg-white shadow"><NetAPADV /></div>
                <div className="grid-item bg-white shadow"><PayemntReturn /></div>
                <div className="grid-item bg-white shadow"><PayemntMTDReturn /></div>
              
               
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey py-1">
        <div className='md:w-ful mb-3 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0">

             
            <div className="grid-container3">
                <div className="grid-item bg-white shadow"><NetDN /></div>
             
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey px-3">
        <div className='md:w-full mb-10 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

             
              <div className="grid-container7">
              <div className="grid-item bg-white shadow"> <TopTenReciptsAP/> </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      
    </div>
  )
}