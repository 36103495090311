import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Swal from "sweetalert2";
import AxiosMain from "../../utilits/const";

const initialState = {
  //NETAP
  netapytdData: {},
  netapmtdData: {},
  netapasondDateData: {},
  netapidailyPaymentData: {},

  //NETAR
  netarasondDateData: {},
  netarmtdData: {},
  netarytdData: {},
  profiledata: {},

  //ytd
  //receipt
  netareceiptcashytdData: {},
  netareceiptviodytdData: {},
  netareceipterrorytdData: {},

  //mtd
  //receipt
  netareceiptcashmtdData: {},
  netareceiptviodmtdData: {},
  netareceipterrormtdData: {},


  //ytd
  //draftreceipt
  netardraftreceiptnonsufficientytdData: {},
  netardraftreceiptviodytdData: {},
  netardraftreceipterrorytdData: {},

  //mtd
  //draftreceipt
  netardraftreceiptufficientmtdData: {},
  netardraftreceiptviodmtdData: {},
  netardraftreceipterrormtdData: {},

  //sales
  //YTD
  salesytdData: {},
  //MTD
  salesmtdData: {},
  //yesterdaysale
  salesyesterdayData: {},
  //todaySale
  todaySaleData: {},
  //salesgrossprofitytd
  salesgrossprofitytdData: {},
  //salesgrossprofitmtd
  salesgrossprofitmtdData: {},
  //salesTop10customerdData
  salesTop10customerdData: {},
  //salesTop10CountryWiseData
  salesTop10CountryWiseData: {},

  //purchase
  purchaseytdData: {},
  //MTD
  purchasemtdData: {},
  //todayPurchase
  todayPurchaseData: {},
  //purchaseTop10ItemByAmountData
  purchaseTop10ItemByAmountData: {},
  //purchaseTop10ItemByQuantityData
  purchaseTop10ItemByQuantityData: {},
  //purchaseTop10SupplierByQuantityData
  purchaseTop10SupplierByQuantityData: {},
  //purchaseTop10ItemByAmountData
  purchaseTop10SupplierByAmountData: {},

  //production
  productionYTD: {},
  productionMTD: {},
  productionDaily: {},


  //inventory
  finishGood: {},
  serviceMaterial: {},
  rawMaterial: {},
  otherMaterials: {},
  totalInventoryValue: {},
};

const config = {
  headers: {
    "Content-Type": "application/json",
  },
}

export const homeSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    //NETAP
    setNETAPYTDDataState: (state, action) => {
      state.netapytdData = action.payload;
    },
    setNETAPMTDDataState: (state, action) => {
      state.netapmtdData = action.payload;
    },
    setNETAPASONDateDataState: (state, action) => {
      state.netapasondDateData = action.payload;
    },
    setNETAPDailyPaymentData: (state, action) => {
      state.netapidailyPaymentData = action.payload;
    },

    //NETAR
    setNETARASONDateDataState: (state, action) => {
      state.netarasondDateData = action.payload;
    },
    setNETARMTDDataState: (state, action) => {
      state.netarmtdData = action.payload;
    },
    setNETARYTDDataState: (state, action) => {
      state.netarytdData = action.payload;
    },

    //Receipt YTD
    setNETARReceiptCashYTDDataState: (state, action) => {
      state.netareceiptcashytdData = action.payload;
    },
    setNETARvoidReceiptYTDDataState: (state, action) => {
      state.netareceiptviodytdData = action.payload;
    },
    setNETARErrorReceiptYTDDataState: (state, action) => {
      state.netareceipterrorytdData = action.payload;
    },

    //Receipt MTD
    setNETARReceiptCashMTDDataState: (state, action) => {
      state.netareceiptcashmtdData = action.payload;
    },
    setNETARvoidReceiptMTDDataState: (state, action) => {
      state.netareceiptviodmtdData = action.payload;
    },
    setNETARErrorReceiptMTDDataState: (state, action) => {
      state.netareceipterrormtdData = action.payload;
    },

    //DraftReceipt YTD
    setNETARDRaftyReceiptNonSufficientYTDDataState: (state, action) => {
      state.netardraftreceiptnonsufficientytdData = action.payload;
    },
    setNETARvoidDraftReceiptYTDDataState: (state, action) => {
      state.netardraftreceiptviodytdData = action.payload;
    },
    setNETARErrorDraftReceiptYTDDataState: (state, action) => {
      state.netardraftreceipterrorytdData = action.payload;
    },

    //DraftReceipt MTD
    setNETARDraftReceiptCashMTDDataState: (state, action) => {
      state.netardraftreceiptufficientmtdData = action.payload;
    },
    setNETARvoidDraftReceiptMTDDataState: (state, action) => {
      state.netardraftreceiptviodmtdData = action.payload;
    },
    setNETARErrorDraftyReceiptMTDDataState: (state, action) => {
      state.netardraftreceipterrormtdData = action.payload;
    },

    //salesYTD
    setSalesYTDDataState: (state, action) => {
      state.salesytdData = action.payload;
    },

    //salesMTD
    setSalesMTDDataState: (state, action) => {
      state.salesmtdData = action.payload;
    },

    //salesYesterday
    setSalesYesterdayDataState: (state, action) => {
      state.salesyesterdayData = action.payload;
    },
    //salesToday
    setSalesTodaysDataState: (state, action) => {
      state.todaySaleData = action.payload;
    },

    //salesGrossProfitYTD
    setSalesGrossProfitYTDDataState: (state, action) => {
      state.salesgrossprofitytdData = action.payload;
    },

    //salesGrossProfitYTD
    setSalesGrossProfitMTDDataState: (state, action) => {
      state.salesgrossprofitmtdData = action.payload;
    },

    //salesTop10customerdData
    setSalesTop10CustomerDataState: (state, action) => {
      state.salesTop10customerdData = action.payload;
    },

    //salesTop10CountryWiseData
    setSalesTop10CountryWiseDataState: (state, action) => {
      state.salesTop10CountryWiseData = action.payload;
    },

    //purchase
    //purchaseYTD
    setPurchaseYTDDataState: (state, action) => {
      state.purchaseytdData = action.payload;
    },

    //purchaseMTD
    setPurchaseMTDDataState: (state, action) => {
      state.purchasemtdData = action.payload;
    },

    //purchaseToday
    setPurchaseTodaysDataState: (state, action) => {
      state.todayPurchaseData = action.payload;
    },

    //salesTop10customerdData
    setSalesTop10CustomerDataState: (state, action) => {
      state.salesTop10customerdData = action.payload;
    },

    //salesTop10CountryWiseData
    setSalesTop10CountryWiseDataState: (state, action) => {
      state.salesTop10CountryWiseData = action.payload;
    },


    //salesTop10customerdData
    setPurchaseTop10ItemByAmountData: (state, action) => {
      state.purchaseTop10ItemByAmountData = action.payload;
    },

    //salesTop10CountryWiseData
    setPurchaseTop10SupplierByAmountData: (state, action) => {
      state.purchaseTop10SupplierByAmountData = action.payload;
    },



    //salesTop10customerdData
    setPurchaseTop10ItemByQantityData: (state, action) => {
      state.purchaseTop10ItemByQuantityData = action.payload;
    },

    //salesTop10CountryWiseData
    setPurchaseTop10SupplierByQantityData: (state, action) => {
      state.purchaseTop10SupplierByQuantityData = action.payload;
    },


    //productionYTD
    setProductionYTDData: (state, action) => {
      state.productionYTD = action.payload;
    },

    //productionMTD
    setProductionMTDData: (state, action) => {
      state.productionMTD = action.payload;
    },

    //productionDaily
    setProductionDailyData: (state, action) => {
      state.productionDaily = action.payload;
    },

    //InventoryFinishGood 
    setInventoryFinishGoodData: (state, action) => {
      state.finishGood = action.payload;
    },

    //InventoryServiceMaterial 
    setInventoryServiceMaterialData: (state, action) => {
      state.serviceMaterial = action.payload;
    },

    //InventoryRawMaterial
    setInventoryRawMaterialData: (state, action) => {
      state.rawMaterial = action.payload;
    },

    //InventoryOtherMaterials
    setInventoryOtherMaterialsData: (state, action) => {
      state.otherMaterials = action.payload;
    },

      //InventoryTotalInventoryValue
      setInventoryTotalInventoryValueData: (state, action) => {
        state.totalInventoryValue = action.payload;
      },

    setProfileState: (state, action) => {
      state.profiledata = action.payload;
    },

    removeHomeSliceState: (state) => {

      //NETAP
      state.netapytdData = {};
      state.netapmtdData = {};
      state.netapasondDateData = {};
      state.netapidailyPaymentData = {};

      //NETAR
      state.netarasondDateData = {};
      state.netarmtdData = {};
      state.netarytdData = {};
      state.profiledata = {};

      //ytd
      //receipt
      state.netareceiptcashytdData = {};
      state.netareceiptviodytdData = {};
      state.netareceipterrorytdData = {};

      //mtd
      //receipt
      state.netareceiptcashmtdData = {};
      state.netareceiptviodmtdData = {};
      state.netareceipterrormtdData = {};


      //ytd
      //draftreceipt
      state.netardraftreceiptnonsufficientytdData = {};
      state.netardraftreceiptviodytdData = {};
      state.netardraftreceipterrorytdData = {};

      //mtd
      //draftreceipt
      state.netardraftreceiptufficientmtdData = {};
      state.netardraftreceiptviodmtdData = {};
      state.netardraftreceipterrormtdData = {};

      //salesYtd
      state.salesytdData = {};
      //salesMtd
      state.salesmtdData = {};
      //salesyesterday
      state.salesyesterdayData = {};
      //salesToday
      state.todaySaleData = {};

      //salesgrossprofitytd
      state.salesgrossprofitytdData = {};

      //salesgrossprofitmtd
      state.salesgrossprofitmtdData = {};
      //salesTop10customerdData
      state.salesTop10customerdData = {};
      //salesTop10CountryWiseData
      state.salesTop10CountryWiseData = {};

      //purchaseytd
      state.purchaseTop10ItemByAmountData = {};
      state.purchaseTop10ItemByQuantityData = {};
      state.purchaseTop10SupplierByAmountData = {};
      state.purchaseytdData = {};
      state.purchasemtdData = {};
      state.purchaseTop10SupplierByQuantityData = {};
      state.todayPurchaseData = {};

      //production
      state.productionDaily = {};
      state.productionMTD = {};
      state.productionYTD = {};


      //inventory
      state.finishGood= {};
      state.serviceMaterial= {};
      state.rawMaterial= {};
      state.otherMaterials= {};
      state.totalInventoryValue= {};
    },
  },
  extraReducers(builder) {

    //NETAPYTD
    builder
      .addCase(NetAPYTD.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetAPYTD.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)

        state.netapytdData = action.payload
      })
      .addCase(NetAPYTD.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //NETAPMTD
    builder
      .addCase(NetAPMTD.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetAPMTD.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)

        state.netapmtdData = action.payload
      })
      .addCase(NetAPMTD.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //NETAPASONDATE
    builder
      .addCase(NetAPASONDATE.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetAPASONDATE.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)

        state.netapasondDateData = action.payload
      })
      .addCase(NetAPASONDATE.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //NETAPDailyPaymentData
    builder
      .addCase(NetAPDailyPaymentData.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetAPDailyPaymentData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)

        state.netapidailyPaymentData = action.payload
      })
      .addCase(NetAPDailyPaymentData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })


    //NETAR
    builder
      .addCase(NetARASONDATE.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetARASONDATE.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.netarasondDateData = action.payload
      })
      .addCase(NetARASONDATE.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //NETARMTD
    builder
      .addCase(NetARMTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetARMTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.netarmtdData = action.payload
      })
      .addCase(NetARMTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //NETARYTD
    builder
      .addCase(NetARYTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetARYTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.netarytdData = action.payload
      })
      .addCase(NetARYTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //NETARReceiptCashYTD
    builder
      .addCase(NetARReceiptCashYTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetARReceiptCashYTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.netareceiptcashytdData = action.payload
      })
      .addCase(NetARReceiptCashYTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //NETARVoidReceiptYTD
    builder
      .addCase(NetARVoidReceiptYTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(NetARVoidReceiptYTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.netareceiptviodytdData = action.payload
      })
      .addCase(NetARVoidReceiptYTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //SalesYTD
    builder
      .addCase(SalesYTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(SalesYTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.salesytdData = action.payload
      })
      .addCase(SalesYTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //SalesMTD
    builder
      .addCase(SalesMTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(SalesMTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.salesmtdData = action.payload
      })
      .addCase(SalesMTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //SalesYesterday
    builder
      .addCase(SalesYesterdayDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(SalesYesterdayDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.salesyesterdayData = action.payload
      })
      .addCase(SalesYesterdayDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //salesToday
    builder
      .addCase(SalesTodayDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(SalesTodayDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.todaySaleData = action.payload
      })
      .addCase(SalesTodayDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //salesGrossProfitYTD
    builder
      .addCase(SalesGrossProfitYTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(SalesGrossProfitYTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.salesgrossprofitytdData = action.payload
      })
      .addCase(SalesGrossProfitYTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })


    //salesGrossProfitMTD
    builder
      .addCase(SalesGrossProfitMTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(SalesGrossProfitMTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.salesgrossprofitmtdData = action.payload
      })
      .addCase(SalesGrossProfitMTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })


    //salesTop10Customer
    builder
      .addCase(SalesTop10customerDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(SalesTop10customerDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.salesTop10customerdData = action.payload
      })
      .addCase(SalesTop10customerDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //salesTop10CountryWiseData
    builder
      .addCase(SalesTop10CountryWiseDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(SalesTop10CountryWiseDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.salesTop10CountryWiseData = action.payload
      })
      .addCase(SalesTop10CountryWiseDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //PurchaseYTD
    builder
      .addCase(PurchaseYTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(PurchaseYTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.purchaseytdData = action.payload
      })
      .addCase(PurchaseYTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //PurchaseMTD
    builder
      .addCase(PurchaseMTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(PurchaseMTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.purchasemtdData = action.payload
      })
      .addCase(PurchaseMTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //PurchaseToday
    builder
      .addCase(PurchaseTodayDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(PurchaseTodayDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.todayPurchaseData = action.payload
      })
      .addCase(PurchaseTodayDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })


    //purchaseTop10ItemByAmount
    builder
      .addCase(PurchaseTop10ItemByAmountDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(PurchaseTop10ItemByAmountDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.purchaseTop10ItemByAmountData = action.payload
      })
      .addCase(PurchaseTop10ItemByAmountDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //purchaseTop10ItemByQuantity
    builder
      .addCase(PurchaseTop10ItemByQuantityDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(PurchaseTop10ItemByQuantityDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.purchaseTop10ItemByQuantityData = action.payload
      })
      .addCase(PurchaseTop10ItemByQuantityDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })


    //purchaseTop10SupplierByAmount
    builder
      .addCase(PurchaseTop10SupplierByAmountDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(PurchaseTop10SupplierByAmountDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.purchaseTop10SupplierByAmountData = action.payload
      })
      .addCase(PurchaseTop10SupplierByAmountDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //purchaseTop10SupplierByQuantity
    builder
      .addCase(PurchaseTop10SupplierByQuantityDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(PurchaseTop10SupplierByQuantityDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.purchaseTop10SupplierByQuantityData = action.payload
      })
      .addCase(PurchaseTop10SupplierByQuantityDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })


    //ProductionYTD
    builder
      .addCase(ProductionYTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(ProductionYTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.productionYTD = action.payload
      })
      .addCase(ProductionYTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })


    //ProductionMTD
    builder
      .addCase(ProductionMTDDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(ProductionMTDDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.productionMTD = action.payload
      })
      .addCase(ProductionMTDDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

    //ProductionDaily
    builder
      .addCase(ProductionDailyDATA.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(ProductionDailyDATA.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.productionDaily = action.payload
      })
      .addCase(ProductionDailyDATA.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

       //InventoryFinishGoods
    builder
    .addCase(InventoryFinishGoodsData.pending, (state, action) => {
      state.status = 'loading'
    })
    .addCase(InventoryFinishGoodsData.fulfilled, (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      // state.posts = state.posts.concat(action.payload)
      state.finishGood = action.payload
    })
    .addCase(InventoryFinishGoodsData.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    })

     //InventoryServiceMaterial
     builder
     .addCase(InventoryServiceMaterialData.pending, (state, action) => {
       state.status = 'loading'
     })
     .addCase(InventoryServiceMaterialData.fulfilled, (state, action) => {
       state.status = 'succeeded'
       // Add any fetched posts to the array
       // state.posts = state.posts.concat(action.payload)
       state.serviceMaterial = action.payload
     })
     .addCase(InventoryServiceMaterialData.rejected, (state, action) => {
       state.status = 'failed'
       state.error = action.error.message
     })

      //InventoryRawMaterial
      builder
      .addCase(InventoryRawMaterialData.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(InventoryRawMaterialData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.rawMaterial = action.payload
      })
      .addCase(InventoryRawMaterialData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

      //InventoryOtherMaterial
      builder
      .addCase(InventoryOtherMaterialData.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(InventoryOtherMaterialData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.otherMaterials = action.payload
      })
      .addCase(InventoryOtherMaterialData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

      //InventoryTotalValuation
      builder
      .addCase(InventoryTotalValuationData.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(InventoryTotalValuationData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        state.totalInventoryValue = action.payload
      })
      .addCase(InventoryTotalValuationData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
});

export const NetAPYTD = createAsyncThunk('NETAPYTD', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const NetAPMTD = createAsyncThunk('NETAPMTD', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const NetAPASONDATE = createAsyncThunk('NetAPASONDATE', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const NetAPDailyPaymentData = createAsyncThunk('NetAPDailyPaymentData', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      console.log('res netapDailyPaymentData==', res)
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const NetARASONDATE = createAsyncThunk('NetARASONDATE', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const NetARMTDDATA = createAsyncThunk('NetARMTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const NetARYTDDATA = createAsyncThunk('NetARYTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const NetARReceiptCashYTDDATA = createAsyncThunk('NetARReceiptCashYTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const NetARVoidReceiptYTDDATA = createAsyncThunk('NetARVoidReceiptYTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const SalesYTDDATA = createAsyncThunk('SalesYTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const SalesMTDDATA = createAsyncThunk('SalesMTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const SalesYesterdayDATA = createAsyncThunk('SalesYesterdayDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const SalesTodayDATA = createAsyncThunk('SalesTodayDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const SalesGrossProfitYTDDATA = createAsyncThunk('SalesGrossProfitYTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const SalesGrossProfitMTDDATA = createAsyncThunk('SalesGrossProfitMTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const SalesTop10customerDATA = createAsyncThunk('SalesTop10customerDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const SalesTop10CountryWiseDATA = createAsyncThunk('SalesTop10CountryWiseDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const PurchaseYTDDATA = createAsyncThunk('PurchaseYTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const PurchaseMTDDATA = createAsyncThunk('PurchaseMTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const PurchaseTodayDATA = createAsyncThunk('PurchaseTodayDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const PurchaseTop10ItemByAmountDATA = createAsyncThunk('PurchaseTop10ItemByAmountDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const PurchaseTop10ItemByQuantityDATA = createAsyncThunk('PurchaseTop10ItemByQuantityDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const PurchaseTop10SupplierByAmountDATA = createAsyncThunk('PurchaseTop10SupplierByAmountDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})


export const PurchaseTop10SupplierByQuantityDATA = createAsyncThunk('PurchaseTop10SupplierByQuantityDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const ProductionYTDDATA = createAsyncThunk('ProductionYTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const ProductionMTDDATA = createAsyncThunk('ProductionMTDDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const ProductionDailyDATA = createAsyncThunk('ProductionDailyDATA', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/dataservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const InventoryFinishGoodsData = createAsyncThunk('InventoryFinishGoodsData', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/bsfnservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const InventoryServiceMaterialData = createAsyncThunk('InventoryServiceMaterialData', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/bsfnservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const InventoryRawMaterialData = createAsyncThunk('InventoryRawMaterialData', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/bsfnservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const InventoryOtherMaterialData = createAsyncThunk('InventoryOtherMaterialData', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/bsfnservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})

export const InventoryTotalValuationData = createAsyncThunk('InventoryTotalValuationData', async (payload) => {
  const response = await AxiosMain.post(`/jderest/v2/bsfnservice`,
    payload,
    config)
    .then((res) => {
      return res
    }).catch((e) => {
      Swal.fire({
        title: 'Warning',
        text: e,
        icon: "warning",
      })
      console.log("Error While getting Kit user", e);
      // setIsCPLoading(false);
    })
  return response.data
})
export const selectAllPosts = state => state.data

export const {
  //NETAP
  setNETAPYTDDataState,
  setNETAPMTDDataState,
  setNETAPASONDateDataState,
  setNETAPDailyPaymentData,

  //NETAR
  setNETARASONDateDataState,
  setNETARMTDDataState,
  setNETARYTDDataState,


  //NETARRECEIPTYTD
  setNETARReceiptCashYTDDataState,
  setNETARvoidReceiptYTDDataState,
  setNETARErrorReceiptYTDDataState,

  //NETARRECEIPTMTD
  setNETARReceiptCashMTDDataState,
  setNETARvoidReceiptMTDDataState,
  setNETARErrorReceiptMTDDataState,

  //NETARDRAFTRECEIPTYTD
  setNETARDRaftyReceiptNonSufficientYTDDataState,
  setNETARvoidDraftReceiptYTDDataState,
  setNETARErrorDraftReceiptYTDDataState,

  //NETARDRAFTRECEIPTMTD
  setNETARDraftReceiptCashMTDDataState,
  setNETARvoidDraftReceiptMTDDataState,
  setNETARErrorDraftyReceiptMTDDataState,

  //SALESYTD
  setSalesYTDDataState,
  //SALESMTD
  setSalesMTDDataState,
  //salesyesertday
  setSalesYesterdayDataState,
  //salestoday
  setSalesTodaysDataState,

  //salesgrossprofitytd
  setSalesGrossProfitYTDDataState,
  //salesgrossprofitmtd
  setSalesGrossProfitMTDDataState,
  //salesTop10customerdData
  setSalesTop10CustomerDataState,
  //salesTop10CountryWiseData
  setSalesTop10CountryWiseDataState,

  //purchase
  //ytd
  setPurchaseYTDDataState,
  //mtd
  setPurchaseMTDDataState,
  //dailyPurchase
  setPurchaseTodaysDataState,

  //purchaseTop10ItemByAmountData
  setPurchaseTop10ItemByAmountData,
  //purchaseTop10ItemByQuantityData
  setPurchaseTop10ItemByQantityData,
  //purchaseTop10SupplierByAmountData
  setPurchaseTop10SupplierByAmountData,
  //purchaseTop10SupplierByQuantityData
  setPurchaseTop10SupplierByQantityData,


  //production
  setProductionDailyData,
  setProductionMTDData,
  setProductionYTDData,
  removeHomeSliceState,

//inventory
setInventoryFinishGoodData,
setInventoryOtherMaterialsData,
setInventoryRawMaterialData,
setInventoryServiceMaterialData,
setInventoryTotalInventoryValueData,


  setProfileState,
} = homeSlice.actions;

export default homeSlice.reducer;