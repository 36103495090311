import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../../../components/LoadingComponent';
import AxiosMain from '../../../../utilits/const';

import "chartjs-plugin-datalabels";

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


import ArrowDownWard from '@mui/icons-material/ArrowDownwardOutlined';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';

import { NetAPMTD } from '../../../../redux/features/homeslice';
import { tableCustomStyles } from "../../../TableStyle";



import 'react-data-table-component-extensions/dist/index.css';

export default function PaymentMTD() {
const userauth = useSelector((state) => state.auth);
const [isCPLoading, setIsCPLoading] = useState(false);
const apiData = useSelector((state) => state.home.netapmtdData);
const token = userauth.data.data.userInfo.token
const [customerList, setCustomerList] = useState([]);
const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
const lastDate =moment().startOf('year').format('DD/MM/YYYY');
const lastmonthlastdate=moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY')
const [po, setPO] = useState(0)
const [p1, setP1] = useState(0)
const [pn, setPN] = useState(0)
const [sumofallvalues, setSumOFAll] = useState(0)
const [sortResult, setSortResult] = useState([]);
const [openFY, setOpenFY] = React.useState(false);


const [customerListfy, setCustomerListFY] = useState([]);
const [isCPLoadingfy, setIsCPLoadingFY] = useState(false);

const [sortResultFY, setSortResultFY] = useState([]);
const dispatch = useDispatch();

const customerdataList = {
  "token" : token,
  "targetName" : "V0413B",
  "targetType" : "view",
  "dataServiceType" : "BROWSE",  
  "maxPageSize" : "100000",
  "returnControlIDs": "F0413.PAAP|F0413.DCTM",
  "query" : {
    "autoFind" : true,
    "condition" : 
    [ 
    {
      "value" : 
      [ {
        "content" : lastmonthlastdate,
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F0413.DMTJ",
      "operator" : "GREATER"
    },
    {
      "value" : 
      [ {
        "content" : nextDate,
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F0413.DMTJ",
      "operator" : "LESS"
    },
    
   ]
  }
}

const [minutes, setMinutes] = useState(0);

  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);



  

const labels = [
  `Drafts: ${p1.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
  `CHQ RTN: ${po.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
  `Payments: ${pn.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
];
const options = {
  chart: {
    type: 'pie',
    events: {
      dataPointSelection: (event, chartContext, config) => { 
        onBarclickFY(config.dataPointIndex)
        // console.log(config.w.config.labels[config.dataPointIndex])
      }
      }
  },
  labels: labels,
  colors: [
    'rgba(255, 159, 64, 0.8)',
    'rgba(75, 192, 192, 0.8)',
    'rgba(153, 102, 255, 0.8)',
    'rgba(255, 99, 132, 0.8)',
    'rgba(255, 206, 86, 0.8)',
    'rgba(54, 162, 235, 0.8)',
  ], // Colors in hexadecimal format
  responsive: [
    {
      breakpoint: 2000,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
};
const series = [
  parseFloat(p1.toString().replace(/[^0-9.-]+/g, '')),
  parseFloat(po.toString().replace(/[^0-9.-]+/g, '')),
  parseFloat(pn.toString().replace(/[^0-9.-]+/g, '')),
];

const config = {
  headers: {
    "Content-Type": "application/json",
  },
}

// const getCustomerList = async () => {
//   setIsCPLoading(true);
//   await AxiosMain.post(`/jderest/v2/dataservice`,
//     customerdataList,
//     config)  
//     .then((res) => {
//       if (res.status === 200) {
//         let data_save = res.data.fs_DATABROWSE_V0413B.data.gridData.rowset;
//         setCustomerList(data_save)
//         setIsCPLoading(false);
//         getTotalamount(data_save)
//       }
//     }).catch((e) => {
//       Swal.fire({
//         title: 'Warning',
//         text: e,
//         icon: "warning",
//       })
//       console.log("Error While getting Kit user", e);
//       setIsCPLoading(false);
//     })
// }

const getCustomerList = async () => {
  setIsCPLoading(true);
  await dispatch(NetAPMTD(customerdataList)).unwrap();
  setIsCPLoading(false);
  
}


useEffect(() => {
  if (apiData === undefined) {
    if (apiData.fs_DATABROWSE_V0413B == undefined || apiData.fs_DATABROWSE_V0413 == null) {
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  } else {
    if (minutes != 60) {
      if (apiData.fs_DATABROWSE_V0413B != null) {
        const data = apiData.fs_DATABROWSE_V0413B.data.gridData.rowset;
        setCustomerList(data)
        getTotalamount(data)
      }
      else {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }
    else {
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }
}, [apiData]);

const getTotalamount = (data_save) =>{
  var result = [];
  var poValue = 0;
  var p1Value = 0;
  var pnValue = 0;
  data_save.reduce(function (res, value) {
    if (!res[value.F0413_DCTM]) {  
      res[value.F0413_DCTM] = { F0413_DCTM: value.F0413_DCTM, F0413_PAAP: 0 };
      result.push(res[value.F0413_DCTM])
    }
    res[value.F0413_DCTM].F0413_PAAP += value.F0413_PAAP;
    return res;
  }, {});

  setSortResult(result)
  result.map((values) => {
    if (values.F0413_DCTM === "PO") {
      const value = values.F0413_PAAP*-1
      poValue =value;
      setPO(value)
    }
    if (values.F0413_DCTM === "P1") {
      const value = values.F0413_PAAP*-1
      p1Value = value;
      setP1(value)
    }
    if (values.F0413_DCTM === "PN") {
      const value = values.F0413_PAAP*-1
      pnValue = value;
      setPN(value)
    }
  })  
  let summation = (poValue+p1Value+pnValue)
  let summation2 = summation.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
  setSumOFAll(summation2)
}

// useEffect(() => {
//   (async () => {
//     const defaultData = await getCustomerList();
//   })()  
// },[]);

const columnsLable = [

  {
    id: "ALPH",
    name: "Supplier Name",
    sortable: true,
    selector: (row) => row.F0101_ALPH,
    cell: (row) => <div>{row.F0101_ALPH}</div>,
  },
  {
    id: 'AN8',
    name: 'Supplier Number',
    sortable: true,
    cell: (row) => <div>{row.F0101_AN8}</div>,
    selector: (row) => row.F0101_AN8,
  },
  {
    id: 'PAAP',
    name: 'Payment Amount',
    sortable: true,
    selector: (row) => row.F0413_PAAP,
    cell: (row) => <div>{row.F0413_PAAP}</div>,
  },
  {
    id: "DMTJ",
    name: "Payment Date",
    sortable: true,
    cell: (row) => <div>{moment(row.F0413_DMTJ).format('DD/MM/YYYY')}</div>,
    selector: (row) => row.F0413_DMTJ,
  },
  {
    id: 'ISTP',
    name: 'Post Status',
    sortable: true,
    selector: (row) => <div>{row.F0413_ISTP}</div>,
    cell: (row) => <div>{row.F0413_ISTP}</div>,
  },
  
  {
    id: "DCTM",
    name: "Payment Document Type",
    sortable: true,
    cell: (row) => <div>{row.F0413_DCTM}</div>,
    // sortType: (a, b) => {
    //     return new Date(b.date) - new Date(a.date);
    // },
    selector: row => row.F0413_DCTM,
  },
  
]

  
const sortIcon = <ArrowDownWard />;


const handleCloseFY = () => {
  setOpenFY(false);
};

const onBarclickFY = async (elements, chart) => {
  let clickedValue = elements;
  if (clickedValue === 0 ) {
    setIsCPLoadingFY(true);
    setOpenFY(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      {
        "token": token,
        "targetName": "V550413B",
        "targetType": "view",
        "dataServiceType": "BROWSE",
        "maxPageSize": "1000000",
        "returnControlIDs": "F0413.PAAP|F0413.DCTM|F0101.ALPH|F0413.PYE|F0413.DMTJ|F0413.ISTP|F0101.AN8",
        "query": {
          "autoFind": true,
          "condition":
            [
              {
                "value":
                  [{
                    "content": "P1", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                    "specialValueId": "LITERAL"
                  }
                  ],
                "controlId": "F0413.DCTM",
                "operator": "EQUAL"
              },
              {
                "value" : 
                [ {
                  "content" : lastmonthlastdate,
                  "specialValueId" : "LITERAL"
                  } 
                ],
                "controlId" : "F0413.DMTJ",
                "operator" : "GREATER"
              },
              {
                "value" : 
                [ {
                  "content" : nextDate,
                  "specialValueId" : "LITERAL"
                  } 
                ],
                "controlId" : "F0413.DMTJ",
                "operator" : "LESS"
              },
            ]
        },
        
      },
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V550413B.data.gridData.rowset;
          setCustomerListFY(data_save)
          setIsCPLoadingFY(false);
          // getTotalamountFY(data_save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoadingFY(false);
      })
  }
  if (clickedValue === 1 ) {
    setIsCPLoadingFY(true);
    setOpenFY(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      {
        "token": token,
        "targetName": "V550413B",
        "targetType": "view",
        "dataServiceType": "BROWSE",
        "maxPageSize": "1000000",
        "returnControlIDs": "F0413.PAAP|F0413.DCTM|F0101.ALPH|F0413.PYE|F0413.DMTJ|F0413.ISTP|F0101.AN8",
        "query": {
          "autoFind": true,
          "condition":
            [
              {
                "value":
                  [{
                    "content": "PO", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                    "specialValueId": "LITERAL"
                  }
                  ],
                "controlId": "F0413.DCTM",
                "operator": "EQUAL"
              },
              {
                "value" : 
                [ {
                  "content" : lastmonthlastdate,
                  "specialValueId" : "LITERAL"
                  } 
                ],
                "controlId" : "F0413.DMTJ",
                "operator" : "GREATER"
              },
              {
                "value" : 
                [ {
                  "content" : nextDate,
                  "specialValueId" : "LITERAL"
                  } 
                ],
                "controlId" : "F0413.DMTJ",
                "operator" : "LESS"
              },
            ]
        },
        
      },
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V550413B.data.gridData.rowset;
          setCustomerListFY(data_save)
          setIsCPLoadingFY(false);
          // getTotalamountFY(data_save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoadingFY(false);
      })
  }
  if (clickedValue === 2 ) {
    setIsCPLoadingFY(true);
    setOpenFY(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      {
        "token": token,
        "targetName": "V550413B",
        "targetType": "view",
        "dataServiceType": "BROWSE",
        "maxPageSize": "1000000",
        "returnControlIDs": "F0413.PAAP|F0413.DCTM|F0101.ALPH|F0413.PYE|F0413.DMTJ|F0413.ISTP|F0101.AN8",
        "query": {
          "autoFind": true,
          "condition":
            [
              {
                "value":
                  [{
                    "content": "PN", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                    "specialValueId": "LITERAL"
                  }
                  ],
                "controlId": "F0413.DCTM",
                "operator": "EQUAL"
              },
              {
                "value" : 
                [ {
                  "content" : lastmonthlastdate,
                  "specialValueId" : "LITERAL"
                  } 
                ],
                "controlId" : "F0413.DMTJ",
                "operator" : "GREATER"
              },
              {
                "value" : 
                [ {
                  "content" : nextDate,
                  "specialValueId" : "LITERAL"
                  } 
                ],
                "controlId" : "F0413.DMTJ",
                "operator" : "LESS"
              },
            ]
        },
        
      },
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V550413B.data.gridData.rowset;
          setCustomerListFY(data_save)
          setIsCPLoadingFY(false);
          // getTotalamountFY(data_save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoadingFY(false);
      })
  }
}

// useEffect(() => {
//   const interval = setInterval(() => {
//     getCustomerList();
//   }, [1000]);
//   return () => clearInterval(interval);
// }, []);

return (
  <div >
     <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFY}
        onClose={handleCloseFY}
        id="FyModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Open AP by Month
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFY}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfy} component={(
                <div className='flex flex-wrap items-center justify-center w-5/5 m-0'>
                  <DataTableExtensions columns={columnsLable}  
                data={customerListfy}
                export={true}
                print={false}
                filterPlaceholder="Search Here"
                exportHeaders={true}
                >
                <DataTable
                  className="cm-category-admin-table"
                  defaultSortFieldId="AN8"
                  sortable={true}
                  columns={columnsLable}  
                  data={customerListfy}
                  striped
                  pagination
                  customStyles={tableCustomStyles}
                  expandableRows={false}
                  sortIcon={sortIcon}
                  fixedHeader={false}
                  pointerOnHover={true}
                  selectableRowsHighlight={true}
                  selectableRowsSingle={true}
                  selectableRowsVisibleOnly={true}
                  style={{ width: '100px' }}
                  overflowY={true}
                  progressPending={isCPLoadingfy}
                  
                  // subHeaderComponent={subHeaderComponentMemo}
                  // subHeader={true}
                />
                </DataTableExtensions>
                </div>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>
    <LoadingComponent isLoading={isCPLoading} component={(
              <div className="flex flex-wrap items-center justify-center my-5 m-0">
              <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Payment MTD </p>
              </div>
              <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
              <span style={{color:'rgb(17 24 39)',fontSize:22}}>{sumofallvalues}</span>
              </div>
              <div className="items-center justify-center my-5 m-0"style={{ width: '100%', height: '100%', overflow: 'hidden'}}>            
              <ReactApexChart options={options} series={series} type="pie" width={350} height={300} />
            </div>
            </div>
            )} />
  </div>
  
)
}