import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../redux/components/LoadingComponent';
import { SalesTop10CountryWiseDATA } from '../../redux/features/homeslice';

export default function Top10Countrywise() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [top10Result, setTop10Result] = useState([]);
  const [sortResult, setSortResult] = useState([]); 
  const [total, setTotal] = useState(0)
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastmonthlastdate=moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY')
  const lastDate =moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")
  const [sortResultFY, setSortResultFY] = useState([]);

  const [ae, setAE] = useState(0)
  const [sa, setSA] = useState(0)
  const [ly, setLY] = useState(0)
  const [bd, setBD] = useState(0)
  const [om, setOM] = useState(0)
  const [iq, setIQ] = useState(0)
  const [kw, setKW] = useState(0)
  const [pk, setPK] = useState(0)
  const [et, setET] = useState(0)
  const [qa, setQA] = useState(0)


  const customerdataList = {
    "token":token,
  "targetName" : "V55CTRS",
  "targetType" : "view",
  "dataServiceType" : "BROWSE",  
  "maxPageSize" : "1000000",
  "returnControlIDs" : "F42119.AEXP|F0116.CTR|F0116.CTY1",
  "query" : {
    "autoFind" : true,
    "condition" : 
    [ 
    {
      "value" : 
      [ {
        "content" : "580",
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F42119.NXTR",
      "operator" : "GREATER"
    },
   
    {
      "value" : 
      [ {
        "content" : subtractDate,//last month's last date
        "specialValueId" : "LITERAL"   
        } 
      ],
      "controlId" : "F42119.IVD",
      "operator" : "GREATER"
    },
    {
      "value" : 
      [ {
        "content" : nextDate,//first date of next month
        "specialValueId" : "LITERAL"
        } 
      ],
      "controlId" : "F42119.IVD",
      "operator" : "LESS"
    },
     ]
  }
  }



  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V55CTRS.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }

  const dispatch = useDispatch();
  const [minutes, setMinutes] = useState(0);


  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(SalesTop10CountryWiseDATA(customerdataList)).unwrap();
    setIsCPLoading(false);
    
  }
  
  
  useEffect(() => {
    if (apiData.status === 'succeeded') {
      console.log('')
      if (apiData?.salesTop10CountryWiseData) {
        if (apiData.salesTop10CountryWiseData === undefined) {
          if (apiData.salesTop10CountryWiseData.fs_DATABROWSE_V55CTRS == undefined || apiData.salesTop10CountryWiseData.fs_DATABROWSE_V55CTRS == null) {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        } else {
          if (minutes != 60) {
            if (apiData.salesTop10CountryWiseData.fs_DATABROWSE_V55CTRS != null || apiData.salesTop10CountryWiseData.fs_DATABROWSE_V55CTRS != undefined) {
              const data = apiData.salesTop10CountryWiseData.fs_DATABROWSE_V55CTRS.data.gridData.rowset;
              setCustomerList(data)
              getTotalamount(data)
            }
            else {
              (async () => {
                const defaultData = await getCustomerList();
              })()
            }
          }
          else {
            (async () => {
              const defaultData = await getCustomerList();
            })()
          }
        }
      }
      else{
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }else{
      (async () => {
        const defaultData = await getCustomerList();
      })()
    }
  }, [apiData]);


  const getTotalamount = (data_save) => {
    var result = [];
    data_save.reduce(function(res, value) {
      if (!res[value.F0116_CTR]) {
        res[value.F0116_CTR] = { F0116_CTR: value.F0116_CTR, F42119_AEXP: 0 };
        result.push(res[value.F0116_CTR])
      }
      res[value.F0116_CTR].F42119_AEXP += value.F42119_AEXP;
      return res;
    }, {});

    setSortResult(result)
    const  sorted = result.sort((a,b) => b.F42119_AEXP - a.F42119_AEXP);
    const firstTen = sorted.slice(0, 10);
    const top10 = sorted.filter((idx) => idx < 10)
    setTop10Result(firstTen)

    result.map((values) => {
      if (values.F0116_CTR === "AE") {
        const value = values.F42119_AEXP
        setAE(value)
      }
      if (values.F0116_CTR === "SA") {
        const value2 = values.F42119_AEXP
        setSA(value2)
      }
      if (values.F0116_CTR === "LY") {
        const value3 = values.F42119_AEXP
        setLY(value3)
      }
      if (values.F0116_CTR === "BD") {
        const value4 = values.F42119_AEXP
        setBD(value4)
      }
      if (values.F0116_CTR === "OM") {
        const value5 = values.F42119_AEXP
        setOM(value5)
      }
      if (values.F0116_CTR === "IQ") {
        const value6 = values.F42119_AEXP
        setIQ(value6)
      }
      if (values.F0116_CTR === "KW") {
        const value7 = values.F42119_AEXP
        setKW(value7)
      }
      if (values.F0116_CTR === "PK") {
        const value8 = values.F42119_AEXP
        setPK(value8)
      }
      if (values.F0116_CTR === "ET") {
        const value9 = values.F42119_AEXP
        setET(value9)
      }
      if (values.F0116_CTR === "QA") {
        const value10 = values.F42119_AEXP
        setQA(value10)
      } 
    })
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const labels = [
    `United Arab Emirates (UAE): ${ae.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Saudi Arabia: ${sa.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Libya: ${ly.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Bangladesh: ${bd.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Oman: ${om.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Iraq: ${iq.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Kuwait: ${kw.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Pakistan: ${pk.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Ethiopia: ${et.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Qatar: ${qa.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
  ];
  const options = {
    chart: {
      type: 'pie',
    },
    labels: labels,
    colors: [
      'rgba(153, 102, 255, 0.8)',
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(201, 203, 207, 0.8)',
     ], // Colors in hexadecimal format
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    title: {
      text: 'Top 10 Countries',
      align: 'center',
      margin: 0,
      offsetX: 0,
      offsetY: 0,
      floating: true,
      style: {
        fontSize:  '16px',
        fontFamily:  undefined,
      },
  }
  
  };
  const series = [
    parseFloat(ae.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(sa.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(ly.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(bd.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(om.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(iq.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(kw.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(pk.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(et.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(qa.toString().replace(/[^0-9.-]+/g, '')),
  ];

  const stateFY = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: sortResultFY.map(o => o.F03B11_FY),
    datasets: [
      {
        label: 'Top 10 Countries',
        backgroundColor: [

          // 'rgb(155, 194, 230)',
          // 'rgb(132, 151, 176)',
          // 'rgb(47, 117, 181)',
          // 'rgb(91, 155, 213)',
          // 'rgb(0, 112, 192)',

          //       'rgb(10,165,255)',
          // 'rgb(218,239,255)',
          // 'rgb(184,224,255)',
          // 'rgb(151,210,255)',
          // 'rgb(119,197,255)',
          // 'rgb(87,183,255)',
          // 'rgb(57,169,255)',
          // 'rgb(15,150,255)',
          'rgb(30, 74, 109,0.3)',
          'rgb(30, 74, 109,0.4)',
          'rgb(30, 74, 109,0.5)',
          'rgb(30, 74, 109,0.6)',
          'rgb(30, 74, 109,0.7)',
          'rgb(30, 74, 109,0.8)',
          'rgb(30, 74, 109,0.9)',
          'rgb(30, 74, 109,1)',
          // 'rgb(30, 74, 109,1)',
        ],
        // borderColor: [
        //   'rgb(255, 99, 132)',
        //   'rgb(255, 159, 64)',
        //   'rgb(255, 205, 86)',
        //   'rgb(75, 192, 192)',
        //   'rgb(54, 162, 235)',
        //   'rgb(153, 102, 255)',
        //   'rgb(201, 203, 207)'
        // ],

        // borderWidth: 1,
        borderColor: '#fff',
        data: sortResultFY.map(o => parseFloat(o.F03B11_AAP)),
      },
    ],
  }
 

  // useEffect(() => {
  //   getCustomerList();

  // }, []);

  const state = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: top10Result.map(o => o.F0116_CTR),
    datasets: [
      {
        label: 'Top 10 Countries By Amount',
        backgroundColor: [
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(201, 203, 207, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(75, 192, 192, 0.8)',
        
        
        ],
        // borderColor: [
        //   'rgb(255, 99, 132)',
        //   'rgb(255, 159, 64)',
        //   'rgb(255, 205, 86)',
        //   'rgb(75, 192, 192)',
        //   'rgb(54, 162, 235)',
        //   'rgb(153, 102, 255)',
        //   'rgb(201, 203, 207)'
        // ],
        
        // borderWidth: 1,
        borderColor:'#fff',
        data: top10Result.map(o => parseFloat(o.F42119_AEXP)),
      },
    ],
  }

  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        

          <div className='flex flex-wrap m-7' >
            
            {/* <div className='flex flex-wrap items-center justify-center w-3/5 m-0' style={{alignItems:'center',paddingLeft:'10%'}}>
              
              <Pie
                data={state}
                
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'left',
                    },
                    title: {
                      display: true,
                      text: 'Top 10 Countries By Amount',
                     
                    }
                  },
                }}
              />
            </div> */}
             <div className="items-center justify-center my-5 m-0"style={{ width: '100%', height: '100%', overflow: 'auto'}}>            
              <ReactApexChart options={options} series={series} type="pie" width={650} height={300} />
            </div>
          </div>

          
      )} />
    </div>

  )
}