import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../../../components/LoadingComponent';
import AxiosMain from '../../../../utilits/const';


import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ArrowDownWard from '@mui/icons-material/ArrowDownwardOutlined';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { tableCustomStyles } from '../../../TableStyle';

import { NetARYTDDATA } from '../../../../redux/features/homeslice';


export default function ReceiptYTD() {
  const userauth = useSelector((state) => state.auth);
  const apiData = useSelector((state) => state.home.netarytdData);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [orcasteratorDataList, setOrcasteratorDataList] = useState([]);
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")
  const [rb, setRB] = useState(0)
  const [db, setDB] = useState(0)
  const [sumofallvalues, setSumOFAll] = useState(0)
  const [sortResult, setSortResult] = useState([]);
  const dispatch = useDispatch();
  const [customerList1, setCustomerList1] = useState([]);

  const [isCPLoadingfyClicked, setIsCPLoadingFYClicked] = useState(false);
  const [customerListfyClicked, setCustomerListFYClicked] = useState([]);
  const [sortResultFYClicked, setSortResultFYClicked] = useState([]);

  const [isCPLoadingfy, setIsCPLoadingFY] = useState(false);
  const [customerListfy, setCustomerListFY] = useState([]);
  const [sortResultFY, setSortResultFY] = useState([]);

  const [openFY, setOpenFY] = React.useState(false);
  const [openFYClicked, setOpenFYClicked] = React.useState(false);
  const [clickedValue, setclickValue]=useState();


  const [minutes, setMinutes] = useState(0);

  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(NetARYTDDATA(customerdataList)).unwrap();
    setIsCPLoading(false);
    
  }
  
  
  useEffect(() => {
    if (apiData === undefined) {
      if (apiData.fs_DATABROWSE_V03B13A == undefined || apiData.fs_DATABROWSE_V03B13A == null) {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    } else {
      if (minutes != 60) {
        if (apiData.fs_DATABROWSE_V03B13A != null) {
          const data = apiData.fs_DATABROWSE_V03B13A.data.gridData.rowset;
          setCustomerList(data)
          getTotalamount(data)
        }
        else {
          (async () => {
            const defaultData = await getCustomerList();
          })()
        }
      }
      else {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }
  }, [apiData]);



  const stateFY = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: sortResultFY.map(o => o.F03B13_PN + " " + o.F03B13_ICUT),
    datasets: [
      {
        label: 'Monthly Receipts',
        backgroundColor: [
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(201, 203, 207, 0.8)',
          
         ],

        // borderWidth: 1,
        borderColor: '#fff',
        data: sortResultFY.map(o => parseFloat(o.F03B13_CKAM).toFixed(2)),
      },
    ],
  }

  const handleCloseFY = () => {
    setOpenFY(false);
  };

  const handleCloseFYClicked = () => {
    setOpenFYClicked(false);
  };

  const onGraphclick = async (elements, chart) => {
    let clickedValue = elements;
    if (clickedValue === 0 ) {
      setclickValue("Receipts")

      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.ICUT|F03B13.CKAM|F03B13.DMTJ|F03B13.VLDT|F03B13.PN",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": subtractDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": "RB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": nextDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "LESS"
          },
          {
            "value":
              [{
                "content": "0", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.POST",
            "operator": "NOT_EQUAL"
          },
  
        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B13.CKAM",
        "direction": "DESC"
      },
      ]
    }
        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
            getTotalamountDB(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
    if (clickedValue === 1 ) {
      setclickValue("Draft Receipts")

      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.ICUT|F03B13.CKAM|F03B13.DMTJ|F03B13.VLDT|F03B13.PN",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": subtractDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": "DB", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": nextDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "LESS"
          },

        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B13.CKAM",
        "direction": "DESC"
      },
      ]
    }
        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
            getTotalamountDB(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }
  }

  
  
  const labels = [
    `Receipts: ${rb.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
    `Draft Receipts: ${db.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}`,
  ];
  const option1 = {
    chart: {
      type: 'pie',
      events: {
        dataPointSelection: (event, chartContext, config) => { 
          onGraphclick(config.dataPointIndex)
          // console.log(config.w.config.labels[config.dataPointIndex])
        }
        }
    },
    labels: labels,
    colors: [
     
      'rgba(255, 206, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 99, 132, 0.8)',
      'rgba(54, 162, 235, 0.8)',],
     // Colors in hexadecimal format

    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const series = [
    parseFloat(rb.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(db.toString().replace(/[^0-9.-]+/g, '')),
  ];
  const customerdataList = {
    "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.ICUT|F03B13.CKAM|F03B13.DMTJ|F03B13.VLDT",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": subtractDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": "0", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "NOT_EQUAL"
          },
          {
            "value":
              [{
                "content": nextDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "LESS"
          },

        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B13.CKAM",
        "direction": "DESC"
      },
      ]
    }
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //         // getCustomerList1();
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }

  const getTotalamount = (data_save) => {
    var result = [];
    var rbValue = 0;
    var dbValue = 0;
    data_save.reduce(function (res, value) {
      if (!res[value.F03B13_ICUT]) {
        res[value.F03B13_ICUT] = { F03B13_ICUT: value.F03B13_ICUT, F03B13_CKAM: 0 };
        result.push(res[value.F03B13_ICUT])
      }
      res[value.F03B13_ICUT].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});
    setSortResult(result)
    result.map((values) => {
      if (values.F03B13_ICUT === "RB") {
        const value = values.F03B13_CKAM
        rbValue = value;
        setRB(value)
      }
      if (values.F03B13_ICUT === "DB") {
        const value = values.F03B13_CKAM
        dbValue = value;
        setDB(value)
      }
      let summation = (rbValue+dbValue)
      let summation2 = summation.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setSumOFAll(summation2)
    })
    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F03B11_AAP - a.F03B11_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const getTotalamountDB = (data_save) => {
    var result = [];
   
    data_save.reduce(function (res, value) {
      if (!res[value.F03B13_PN]) {
        res[value.F03B13_PN] = { F03B13_PN: value.F03B13_PN, F03B13_CKAM: 0,F03B13_ICUT:value.F03B13_ICUT };
        result.push(res[value.F03B13_PN])
      }
      res[value.F03B13_PN].F03B13_CKAM += value.F03B13_CKAM;
      return res;
    }, {});
    const  sorted = result.sort((a,b) => b.F03B13_PN - a.F03B13_PN);
    setSortResultFY(sorted)
    
  }

  const onBarclickFY = async (elements, chart) => {
    let clickedValue = 0;

    if (elements[0]) {
      const i = elements[0].index;
      clickedValue = chart.data.labels[i]
      const splited_value  = clickedValue.split(" ")
      
      const clickedValue_0 = splited_value[0]
      const clickedValue_1 = splited_value[1]
      setIsCPLoadingFYClicked(true);
      setOpenFYClicked(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
    "targetName": "V03B13A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F03B13.ICUT|F03B13.CKAM|F03B13.DMTJ|F03B13.VRE|F03B13.ALPH|F03B13.DGJ|F03B13.DCTG",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": subtractDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "GREATER"
          },
          {
            "value":
              [{
                "content": clickedValue_0, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.PN",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": clickedValue_1, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.ICUT",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": nextDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F03B13.DMTJ",
            "operator": "LESS"
          },

        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F03B13.CKAM",
        "direction": "DESC"
      },
      ]
    }
        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V03B13A.data.gridData.rowset;
            setCustomerListFYClicked(data_save)
            setIsCPLoadingFYClicked(false);
            // getTotalamountFYClicked(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFYClicked(false);
        })
    }

  }

  // useEffect(() => {
  //   (async () => {
  //     const defaultData = await getCustomerList();
  //   })()
  // }, []);

  const columnsLable = [
    {
      id: 'alph',
      name: 'Customer Name',
      sortable: true,
      selector: (row) => row.F03B13_ALPH,
      cell: (row) => <div>{row.F03B13_ALPH}</div>,
    },
    {
      id: 'dmtj',
      name: 'Recepit Date ',
      sortable: true,
      selector: (row) => <div>{moment(row.F03B13_DMTJ).format('DD/MM/YYYY')}</div>,
      cell: (row) => <div>{row.F03B13_DMTJ}</div>,
    },
    {
      id: 'dgj',
      name: 'G/L Date',
      sortable: true,
      cell: (row) => <div>{moment(row.F03B13_DGJ).format('DD/MM/YYYY')}</div>,
      selector: (row) => row.F03B13_DGJ,
    },

    {
      id: "icut",
      name: "Batch Type",
      sortable: true,
      selector: (row) => row.F03B13_ICUT,
      cell: (row) => <div>{row.F03B13_ICUT}</div>,
    },
    {
      id: "ckam",
      name: "Amount",
      sortable: true,
      cell: (row) => <div>{row.F03B13_CKAM}</div>,
      selector: (row) => row.F03B13_CKAM,
    },
    {
      id: "dctg",
      name: "Receipt Type",
      sortable: true,
      selector: (row) => row.F03B13_DCTG,
      cell: (row) => <div>{row.F03B13_DCTG}</div>,
    },
    {
      id: "vre",
      name: "COD",
      sortable: true,
      cell: (row) => <div>{row.F03B13_VRE}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F03B13_VRE,
    },
  ]

  const sortIcon = <ArrowDownWard />;


  return (
    <div >

      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFY}
        onClose={handleCloseFY}
        id="FyModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Yearly Receipts - {clickedValue}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFY}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfy} component={(
                <div className='flex flex-wrap items-center justify-center w-5/5 m-0'>
                  <Bar
                    data={stateFY}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: true,
                          text: 'Monthly Receipts',
                          font: {
                            size: 16,

                          }
                        }
                      },
                      onClick: (event, elements, chart) => {
                        onBarclickFY(elements, chart)
                      }
                    }}
                  />
                </div>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFYClicked}
        onClose={handleCloseFYClicked}
        id="FyModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Receipt Yearly
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFYClicked}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfyClicked} component={(
                <DataTableExtensions columns={columnsLable}  
                data={customerListfyClicked}
                export={true}
                print={false}
                filterPlaceholder="Search Here"
                exportHeaders={true}
                >
                <DataTable
                  className="cm-category-admin-table"
                  defaultSortFieldId="orderNumber"
                  sortable={true}
                  columns={columnsLable}  
                  data={customerListfyClicked}
                  striped
                  pagination
                  customStyles={tableCustomStyles}
                  expandableRows={false}
                  sortIcon={sortIcon}
                  fixedHeader={false}
                  pointerOnHover={true}
                  selectableRowsHighlight={true}
                  selectableRowsSingle={true}
                  selectableRowsVisibleOnly={true}
                  style={{ width: '100px' }}
                  overflowY={true}
                  progressPending={isCPLoadingfyClicked}
                  
                  // subHeaderComponent={subHeaderComponentMemo}
                  // subHeader={true}
                />
                </DataTableExtensions>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>


      <LoadingComponent isLoading={isCPLoading} component={(


        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Receipt YTD </p>
          </div>
          <div className='flex flex-col gap-y-5 w-full max-w-md mb-0'>
            <span style={{ color: 'rgb(17 24 39)', fontSize: 22 }} >{sumofallvalues}</span>
          </div>    
          <div className="items-center justify-center my-5 m-0" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <ReactApexChart options={option1} series={series} type="pie" width={350} height={300} />
          </div>
        </div>
      )} />
    </div>

  )
}