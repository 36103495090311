import { Chart, registerables } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { removeAuthState } from '../../redux/features/authStateSlice';
import AxiosMain from '../../utilits/const';
import CurrentMonthPurchase from './currentMonthPurchase';
import DailyPurchaseReceipt from './dailyPurchaseRecipts';
import Top10ItemByAmount from './top10ItembyAmount';
import Top10ItemByQty from './top10ItembyQty';
import Top10SupplierByAmount from './top10SupplierByAmount';
import Top10SupplierByQty from './top10SupplierByQty';
import YearToDatePurchase from './yearToDatePurchase';
Chart.register(...registerables)


export default function PurchaseSummaryDashboard() {

  const dispatch = useDispatch();
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token
  const navigate = useNavigate();
  const [isCPLoading, setIsCPLoading] = useState(false);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const validTokenData ={
    "token": token,
  }
  const checktokenValid = async() =>{
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/tokenrequest/validate`,
    validTokenData,
      config)
      .then((res) => {
        if (res.isValidSession == false) {
          Swal.fire({
            title: 'Warning',
            text: 'Token Expired.',
            icon: "warning",
          });          
          setIsCPLoading(false);
          dispatch(removeAuthState());
          navigate("/");
        }else{
          setIsCPLoading(false);
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
      setIsCPLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checktokenValid();
    }, [3600000]);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div className="bg-grey py-2 ">
        <div className='md:w-full w-full '>
          <div className="rounded-[10px] p-1 ">
            <div className="cm-admin-category-table-wrapper ">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'></div>
              {/* <!-- Content Row --> */}
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '10px', maxWidth: '100%', margin: '0 auto' }}>
                <div className="grid-item bg-white shadow"><YearToDatePurchase /></div>
                <div className="grid-item bg-white shadow"><CurrentMonthPurchase /></div>
                <div className="grid-item bg-white shadow"><DailyPurchaseReceipt/></div>  
               
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-grey">
        <div className='md:w-full w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper">

             
              <div className="grid-container3">
              <div className="grid-item bg-white shadow"><OpenReceiptPurchase/></div>
             
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-grey">
        <div className='md:w-full w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper">

             
              <div className="grid-container3">
              <div className="grid-item bg-white shadow"> <PurchaseYTDByDocumt /> </div>
              <div className="grid-item bg-white shadow"> <PurchaseMTDByDocumt /> </div>
              <div className="grid-item bg-white shadow"> <PurchaseDailyByDocumt /> </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="bg-grey">
        <div className='md:w-full w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper">

             
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '10px', maxWidth: '100%', margin: '0 auto' }}>
              <div className="grid-item bg-white shadow"> <Top10ItemByAmount /> </div>
              <div className="grid-item bg-white shadow"> <Top10ItemByQty /> </div>
              {/* <div className="grid-item bg-white shadow"> <Countrywise /> </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-grey ">
        <div className='md:w-full mb-10 w-full '>
          <div className=" rounded-[10px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

             
              <div className="grid-container2">
              <div className="grid-item bg-white shadow"> <Top10SupplierByAmount /> </div>
              <div className="grid-item bg-white shadow"> <Top10SupplierByQty /> </div>
              {/* <div className="grid-item bg-white shadow"> <Countrywise /> </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-white px-3">
        <div className='md:w-full mb-3 w-full '>
          <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'><span style={{ fontSize: 24, fontWeight: 'bold' }}>Graphs</span></div>
              <div>
                <TopTenCustomer />
                
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="bg-white  px-3">
        <div className='md:w-full mb-3 w-full '>
          <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
            <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">

              <div className='flex flex-wrap items-center justify-center my-5 m-0'><span style={{ fontSize: 24, fontWeight: 'bold' }}>Graphs</span></div>
              <div>
        <Line
          data={state1}
          options={{
            title: {
              display: true,
              text: 'Class Strength',
              fontSize: 20,
            },
            legend: {
              display: true,
              position: 'right',
            },
          }}
        />
      </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}