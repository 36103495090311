import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../../../components/LoadingComponent';
import AxiosMain from '../../../../utilits/const';

import 'react-data-table-component-extensions/dist/index.css';

import "chartjs-plugin-datalabels";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


import ArrowDownWard from '@mui/icons-material/ArrowDownwardOutlined';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';

import moment from 'moment';
import { tableCustomStyles } from "../../../TableStyle";

import { NetAPASONDATE } from '../../../../redux/features/homeslice';




export default function NetApAsOnDate() {
  const userauth = useSelector((state) => state.auth);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const apiData = useSelector((state) => state.home.netapasondDateData);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [sortResult, setSortResult] = useState([]);
  const [dn, setDN] = useState(0)
  const [p8, setP8] = useState(0)
  const [pv, setPV] = useState(0)
  const [pd, setPD] = useState(0)
  const [p1, setP1] = useState(0)
  const [sumofallvalues, setSumOFAll] = useState(0)
  const [sumofallvaluestemp, setSumOFAlltemp] = useState(0)

  const [sortResultFY, setSortResultFY] = useState([]);
  const [sortResultFYClicked, setSortResultFYClicked] = useState([]);
  const [openFY, setOpenFY] = React.useState(false);
  const [openFYClicked, setOpenFYClicked] = React.useState(false);

  const [sortResultFYClickedMonth, setSortResultFYClickedMonth] = useState([]);
  const [openFYClickedMonth, setOpenFYClickedMonth] = React.useState(false);

  const [isCPLoadingfy, setIsCPLoadingFY] = useState(false);
  const [isCPLoadingfyClicked, setIsCPLoadingFYClicked] = useState(false);
  const [isCPLoadingfyClickedMonth, setIsCPLoadingFYClickedMonth] = useState(false);

  const [customerListfy, setCustomerListFY] = useState([]);
  const [customerListfyclicked, setCustomerListFYClicked] = useState([]);
  const [customerListfyclickedMonth, setCustomerListFYClickedMonth] = useState([]);

  const [minutes, setMinutes] = useState(0);

  const updateMinutes = (newMinutes) => {
    setMinutes(newMinutes);
    localStorage.setItem('minutes', newMinutes.toString());
  };


  useEffect(() => {
    // Function to be executed every minute
    const updateEveryMinute = () => {
      // Update minutes
      updateMinutes(minutes + 1);
    };

    // Set up an interval to call the function every minute
    const intervalId = setInterval(updateEveryMinute, 36000000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [minutes]);



  const dispatch = useDispatch();


  const customerdataList = {
    "token": token,
    "targetName": "V0411AJA",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "1000000",
    "returnControlIDs": "F0411A.AAP|F0411A.DCT|F0411A.PN|F0411A.FY",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "0", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411A.POST",
            "operator": "NOT_EQUAL"
          },
          {
            "value":
              [{
                "content": "P", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411A.PST",
            "operator": "NOT_EQUAL"
          },
          {
            "value":
              [{
                "content": "PREP", //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411A.GLC",
            "operator": "NOT_EQUAL"
          },
        ]
    },
    "aggregation": {
      "orderBy": [{
        "column": "F0411A.AAP",
        "direction": "DESC"
      },
      ]
    }
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const getCustomerList = async () => {
    setIsCPLoading(true);
    await dispatch(NetAPASONDATE(customerdataList)).unwrap();
    setIsCPLoading(false);

  }

  // const getCustomerList = async () => {
  //   setIsCPLoading(true);
  //   await AxiosMain.post(`/jderest/v2/dataservice`,
  //     customerdataList,
  //     config)
  //     .then((res) => {
  //       console.log(res);
  //       if (res.status === 200) {
  //         let data_save = res.data.fs_DATABROWSE_V0411AJA.data.gridData.rowset;
  //         setCustomerList(data_save)
  //         setIsCPLoading(false);
  //         getTotalamount(data_save)
  //       }
  //     }).catch((e) => {
  //       Swal.fire({
  //         title: 'Warning',
  //         text: e,
  //         icon: "warning",
  //       })
  //       console.log("Error While getting Kit user", e);
  //       setIsCPLoading(false);
  //     })
  // }

  useEffect(() => {
    if (apiData === undefined) {
      if (apiData.fs_DATABROWSE_V0411AJA == undefined || apiData.fs_DATABROWSE_V0411AJA == null) {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    } else {
      if (minutes != 60) {
        if (apiData.fs_DATABROWSE_V0411AJA != null) {
          const data = apiData.fs_DATABROWSE_V0411AJA.data.gridData.rowset;
          setCustomerList(data)
          getTotalamount(data)
        }
        else {
          (async () => {
            const defaultData = await getCustomerList();
          })()
        }
      }
      else {
        (async () => {
          const defaultData = await getCustomerList();
        })()
      }
    }
  }, [apiData]);


  const getTotalamount = (data_save) => {
    var result = [];
    var dnValue = 0;
    var p1Value = 0;
    var p8Value = 0;
    var pdValue = 0;
    var pvValue = 0;
    data_save.reduce(function (res, value) {
      if (!res[value.F0411A_DCT]) {
        res[value.F0411A_DCT] = { F0411A_DCT: value.F0411A_DCT, F0411A_AAP: 0 };
        result.push(res[value.F0411A_DCT])
      }
      res[value.F0411A_DCT].F0411A_AAP += value.F0411A_AAP;
      return res;
    }, {});
    setSortResult(result)
    result.map((values) => {
      if (values.F0411A_DCT === "DN") {
        const value = values.F0411A_AAP
        dnValue = value;
        setDN(value)
      }
      if (values.F0411A_DCT === "P8") {
        const value = values.F0411A_AAP
        p8Value = value;
        setP8(value)
      }
      if (values.F0411A_DCT === "PV") {
        const value = values.F0411A_AAP
        pvValue = value;
        setPV(value)
      }
      if (values.F0411A_DCT === "PD") {
        const value = values.F0411A_AAP
        pdValue = value;
        setPD(value)
      }
      if (values.F0411A_DCT === "P1") {
        const value = values.F0411A_AAP
        p1Value = value;
        setP1(value)
      }
    })
    let summation = (dnValue + p1Value + p8Value + pdValue + pvValue)
    let summation2 = summation.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    setSumOFAll(summation2)
  }

  const handleCloseFY = () => {
    setOpenFY(false);
  };

  const onBarclickFY = async (elements, chart) => {
    let clickedValue = 0;

    if (elements[0]) {
      const i = elements[0].index;
      clickedValue = chart.data.labels[i]
      setIsCPLoadingFY(true);
      setOpenFY(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V0411AJA",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F0411A.AAP|F0411A.DCT|F0411A.PN|F0411A.FY",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": clickedValue, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F0411A.DCT",
                  "operator": "EQUAL"
                },
              ]
          },

        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V0411AJA.data.gridData.rowset;
            setCustomerListFY(data_save)
            setIsCPLoadingFY(false);
            getTotalamountFY(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFY(false);
        })
    }

  }

  const getTotalamountFY = (data_save) => {
    var result = [];
    data_save.reduce(function (res, value) {
      if (!res[value.F0411A_FY]) {
        res[value.F0411A_FY] = { F0411A_FY: value.F0411A_FY, F0411A_AAP: 0, F0411A_DCT: value.F0411A_DCT };
        result.push(res[value.F0411A_FY])
      }
      res[value.F0411A_FY].F0411A_AAP += value.F0411A_AAP;
      return res;
    }, {});
    console.log('result', result);
    const sorted = result.sort((a, b) => b.F0411A_FY - a.F0411A_FY);
    setSortResultFY(sorted)

    // const top10 = result.filter((month,idx) => idx < 10) 
    // const  sorted = top10.sort((a,b) => b.F0411A_AAP - a.F0411A_AAP);
    // setTop10Result(sorted)
    // data_save.reduce((a, v) => a = a + v.F4211_AEXP, 0)
    // setTotal(settotal.toFixed(2))
  }

  const stateFY = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: sortResultFY.map(o => o.F0411A_FY + " " + o.F0411A_DCT),
    datasets: [
      {
        label: 'By Year',
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',

        ],

        // borderWidth: 1,
        borderColor: '#fff',
        data: sortResultFY.map(o => parseFloat(o.F0411A_AAP).toFixed(2)),
      },
    ],
  }

  const stateFYClicked = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    labels: sortResultFYClicked.map(o => o.F0411A_FY + " " + o.F0411A_PN + " " + o.F0411A_DCT),
    datasets: [
      {
        label: 'By Month',
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',

        ],

        // borderWidth: 1,
        borderColor: '#fff',
        data: sortResultFYClicked.map(o => parseFloat(o.F0411A_AAP).toFixed(2)),
      },
    ],
  }


  const onBarclicked = async (elements, chart) => {
    let clickedValue = 0;

    if (elements[0]) {
      const i = elements[0].index;
      clickedValue = chart.data.labels[i]
      const splited_value = clickedValue.split(" ")

      const clickedValue_0 = splited_value[0]
      const clickedValue_1 = splited_value[1]
      setIsCPLoadingFYClicked(true);
      setOpenFYClicked(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V0411AJA",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F0411A.AAP|F0411A.DCT|F0411A.FY|F0411A.AN8|F0411A.PN",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": clickedValue_1, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F0411A.DCT",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": clickedValue_0, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F0411A.FY",
                  "operator": "EQUAL"
                }

              ]
          },

        },
        config)
        .then((res) => {
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V0411AJA.data.gridData.rowset;
            setCustomerListFYClicked(data_save)
            setIsCPLoadingFYClicked(false);
            getTotalamountFYClicked(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFYClicked(false);
        })
    }

  }

  const onBarclickedMonth = async (elements, chart) => {
    let clickedValue = 0;
    console.log(elements[0])
    if (elements[0]) {
      const i = elements[0].index;
      clickedValue = chart.data.labels[i]
      const splited_value = clickedValue.split(" ")
      const clickedValue_0 = splited_value[0]
      const clickedValue_1 = splited_value[1]
      const clickedValue_2 = splited_value[2]
      console.log(clickedValue_0, clickedValue_1, clickedValue_2)
      setIsCPLoadingFYClickedMonth(true);
      setOpenFYClickedMonth(true);
      await AxiosMain.post(`/jderest/v2/dataservice`,
        {
          "token": token,
          "targetName": "V0411AJA",
          "targetType": "view",
          "dataServiceType": "BROWSE",
          "maxPageSize": "1000000",
          "returnControlIDs": "F0411A.PST|F0411A.DOC|F0411A.AN8|F0101.ALPH|F0411A.AAP|F0411A.DCT|F0411A.DIVJ|F0411A.POST|F0411A.FY|F0411A.PN|F0411A.DDJ",
          "query": {
            "autoFind": true,
            "condition":
              [
                {
                  "value":
                    [{
                      "content": clickedValue_2, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F0411A.DCT",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": clickedValue_0, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F0411A.FY",
                  "operator": "EQUAL"
                },
                {
                  "value":
                    [{
                      "content": clickedValue_1, //subtractDate,//first  date of last year example today's date is 15/08/2023 then it should calculate last year's first date and then reduce 1 day from this date and pass as variable(31/12/2022)
                      "specialValueId": "LITERAL"
                    }
                    ],
                  "controlId": "F0411A.PN",
                  "operator": "EQUAL"
                }

              ]
          },

        },
        config)
        .then((res) => {
          console.log('bar monthly', res)
          if (res.status === 200) {
            let data_save = res.data.fs_DATABROWSE_V0411AJA.data.gridData.rowset;
            setCustomerListFYClickedMonth(data_save)
            setIsCPLoadingFYClickedMonth(false);
            // getTotalamountFYClicked(data_save)
          }
        }).catch((e) => {
          Swal.fire({
            title: 'Warning',
            text: e,
            icon: "warning",
          })
          console.log("Error While getting Kit user", e);
          setIsCPLoadingFYClickedMonth(false);
        })
    }

  }

  const getTotalamountFYClicked = (data_save) => {
    var result = [];
    data_save.reduce(function (res, value) {
      if (!res[value.F0411A_PN]) {
        res[value.F0411A_PN] = { F0411A_FY: value.F0411A_FY, F0411A_AAP: 0, F0411A_DCT: value.F0411A_DCT, F0411A_PN: value.F0411A_PN };
        result.push(res[value.F0411A_PN])
      }
      res[value.F0411A_PN].F0411A_AAP += value.F0411A_AAP;
      return res;
    }, {});
    const sorted = result.sort((a, b) => b.F0411A_PN - a.F0411A_PN);
    setSortResultFYClicked(sorted)
  }

  const columnsLable = [
    {
      id: 'AN8',
      name: 'Supplier Number',
      sortable: true,
      cell: (row) => <div>{row.F0411A_AN8}</div>,
      selector: (row) => row.F0411A_AN8,
    },

    {
      id: "ALPH",
      name: "Supplier Name",
      sortable: true,
      selector: (row) => row.F0101_ALPH,
      cell: (row) => <div>{row.F0101_ALPH}</div>,
    },
    {
      id: 'AAP',
      name: 'Open Amount',
      sortable: true,
      selector: (row) => row.F0411A_AAP,
      cell: (row) => <div>{row.F0411A_AAP}</div>,
    },
    {
      id: 'DOC',
      name: 'Dcoument Number',
      sortable: true,
      selector: (row) => <div>{row.F0411A_DOC}</div>,
      cell: (row) => <div>{row.F0411A_DOC}</div>,
    },
    {
      id: 'DCT',
      name: 'Dcoument Type',
      sortable: true,
      selector: (row) => <div>{row.F0411A_DCT}</div>,
      cell: (row) => <div>{row.F0411A_DCT}</div>,
    },
    {
      id: "DIVJ",
      name: "Invoice Date",
      sortable: true,
      cell: (row) => <div>{moment(row.F0411A_DIVJ).format('DD/MM/YYYY')}</div>,
      selector: (row) => row.F0411A_DIVJ,
    },

    {
      id: "PST",
      name: "Payment Status",
      sortable: true,
      cell: (row) => <div>{row.F0411A_PST}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F0411A_PST,
    },
    {
      id: "POST",
      name: "POST Status",
      sortable: true,
      cell: (row) => <div>{row.F0411A_POST}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F0411A_POST,
    },
    {
      id: "FY",
      name: "Year",
      sortable: true,
      cell: (row) => <div>{row.F0411A_FY}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F0411A_FY,
    },
    {
      id: "PN",
      name: "Period",
      sortable: true,
      cell: (row) => <div>{row.F0411A_PN}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F0411A_PN,
    },
    {
      id: "DDJ",
      name: "Due Date",
      sortable: true,
      cell: (row) => <div>{moment(row.F0411A_DDJ).format('DD/MM/YYYY')}</div>,
      // sortType: (a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // },
      selector: row => row.F0411A_DDJ,
    },
  ]

  const sortIcon = <ArrowDownWard />;


  // useEffect(() => {
  //   getCustomerList();

  // }, []);

  const labels = [
    `Voucher: ${pv.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}}`,
    `Purchase Return: ${pd.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}}`,
    `Advances: ${p8.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}}`,
    `Debit Note: ${dn.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}}`,
  ];
  const options = {
    chart: {
      type: 'pie',
    },
    labels: labels,
    colors: [
      'rgba(255, 159, 64, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 206, 86, 0.8)',
      'rgba(54, 162, 235, 0.8)',
    ], // Colors in hexadecimal format
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  const series = [
    parseFloat(pv.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(pd.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(p8.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(dn.toString().replace(/[^0-9.-]+/g, ''))
  ];

  const state = {
    labels: sortResult.map(o => o.F0411A_DCT),
    // labels: sortResult.map(o => o.F0411A_DCT),
    datasets: [
      {
        label: ['AP'],
        // backgroundColor: '#214B6C',
        // [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        //   'rgba(255, 205, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(201, 203, 207, 0.2)'
        // ],
        borderColor: [
          'rgb(237, 28, 36)',
          'rgb(247, 191, 71)',
          'rgb(27, 185, 52)',
          'rgb(51, 174, 154)',
          'rgb(29, 183, 255)',
          'rgb(202, 143, 255)',
          'rgb(201, 203, 207)'
        ],
        backgroundColor: [
          'rgba(153, 102, 255, 0.8)',

          'rgba(255, 99, 132, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(201, 203, 207, 0.8)',
        ],
        borderColor: '#fff',
        data: sortResult.map(o => parseFloat(o.F0411A_AAP).toFixed(2)),

      },
    ],
  }

  const options1 = {
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true
      }
    },
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom"

      },
      title: {
        display: false,
        text: ""
      }
    },
    onClick: (event, elements, chart) => {
      onBarclickFY(elements, chart)
    }
  };

  const handleCloseFYClicked = () => {
    setOpenFYClicked(false);
  };

  const handleCloseFYClickedMonth = () => {
    setOpenFYClickedMonth(false);
  };


  return (
    <div>
      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFY}
        onClose={handleCloseFY}
        id="FyModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Open AP by Year
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFY}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfy} component={(
                <div className='flex flex-wrap items-center justify-center w-5/5 m-0'>
                  <Bar
                    data={stateFY}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: true,
                          text: 'By Year',

                        }
                      },
                      onClick: (event, elements, chart) => {
                        onBarclicked(elements, chart)
                      }
                    }}
                  />
                </div>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFYClicked}
        onClose={handleCloseFYClicked}
        id="FyclickedModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Open AP By Month
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFYClicked}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfyClicked} component={(
                <div className='flex flex-wrap items-center justify-center w-5/5 m-0'>
                  <Bar
                    data={stateFYClicked}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: true,
                          text: 'By Month',

                        }
                      },
                      onClick: (event, elements, chart) => {
                        onBarclickedMonth(elements, chart)
                      }
                    }}
                  />
                </div>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFYClickedMonth}
        onClose={handleCloseFYClickedMonth}
        id="FyclickedModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Open AP
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFYClickedMonth}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfyClickedMonth} component={(
                <DataTableExtensions columns={columnsLable}
                  data={customerListfyclickedMonth}
                  export={true}
                  print={false}
                  filterPlaceholder="Search Here"
                  exportHeaders={true}
                >
                  <DataTable
                    className="cm-category-admin-table"
                    defaultSortFieldId="AN8"
                    sortable={true}
                    columns={columnsLable}
                    data={customerListfyclickedMonth}
                    striped
                    pagination
                    customStyles={tableCustomStyles}
                    expandableRows={false}
                    sortIcon={sortIcon}
                    fixedHeader={false}
                    pointerOnHover={true}
                    selectableRowsHighlight={true}
                    selectableRowsSingle={true}
                    selectableRowsVisibleOnly={true}
                    style={{ width: '100px' }}
                    overflowY={true}
                    progressPending={isCPLoadingfyClickedMonth}

                  // subHeaderComponent={subHeaderComponentMemo}
                  // subHeader={true}
                  />
                </DataTableExtensions>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-4 w-full max-w-md mb-0'>
            <p className="text-gray-500 text-sm font-bold leading-loose ml-[0.75rem]">Net Account Payable As on Date</p>
          </div>
          <div className='flex flex-col gap-y-4 w-full max-w-md mb-0'>
            <span style={{ color: 'rgb(17 24 39)', fontSize: 22 }}>{sumofallvalues}</span>
          </div>
          <div className="items-center justify-center my-5 m-0" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <Bar

              data={state}
              options={options1}
              plugins={[ChartDataLabels]}

            // options={{
            //   responsive: true,
            //   plugins: {
            //     legend: {
            //       position: 'top',
            //     },
            //     title: {
            //       display: true,
            //       text: 'Top 10 Item By Quantity',
            //       font: {
            //         size: 16,

            //       }
            //     }
            //   },
            // }}
            />
          </div>

          {/* <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem] textAlign">Voucher:<span style={{ color: 'rgb(17 24 39)', fontSize: 15 }}>{pv}</span></p>
          </div>

          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
            <p className="text-gray-500 text-sm font-bold leading-loose ml-[0.75rem] textAlign">Purchase Return:<span style={{ color: 'rgb(17 24 39)', fontSize: 15 }}>{pd}</span></p>
          </div>

          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className="text-gray-500 text-sm font-bold leading-loose ml-[0.75rem] textAlign">Advances:<span style={{color:'rgb(17 24 39)'}}>{p8}</span></p>
              </div>

          <div className='flex flex-col gap-y-3 w-4/5 max-w-md mb-0'>
              <p className="text-gray-500 text-sm font-bold leading-loose ml-[0.75rem] textAlign">Debit Note:<span style={{color:'rgb(17 24 39)'}}>{dn}</span></p>
          </div> */}


        </div>
      )} />
    </div>

  )
}