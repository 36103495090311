import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../components/LoadingComponent';
import AxiosMain from '../../utilits/const';


export default function NetAp() {
  const userauth = useSelector((state) => state.auth);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [total, setTotal] = useState(0)
  const curentDate = moment(new Date()).format("DD/MM/YYYY");


  const customerdataList = {
    "token": token,
    "targetName": "V0411A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "returnControlIDs": "F0411.AAP|F0411.DDJ|F0411.PN|F0411.FY",
    "maxPageSize": "10000",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "00001",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411.CO",
            "operator": "EQUAl"
          },
          {
            "value":
              [{
                "content": curentDate,
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411.DDJ",
            "operator": "GREATER"
          },
          // {
          //   "value":
          //     [{
          //       "content": "13/06/2023",
          //       "specialValueId": "LITERAL"
          //     }
          //     ],
          //   "controlId": "F0411.DDJ",
          //   "operator": "LESS"
          // },
          {
            "value":
              [{
                "content": "23",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411.FY",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": "20",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411.CTRY",
            "operator": "EQUAL"
          },
          {
            "value":
              [{
                "content": "D",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411.POST",
            "operator": "EQUAl"
          },
          {
            "value":
              [{
                "content": "0",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F0411.AAP",
            "operator": "GREATER"
          },
        ]
    }
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const getCustomerList = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataList,
      config)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V0411A.data.gridData.rowset;
          setCustomerList(data_save)
          setIsCPLoading(false);
          getTotalamount(data_save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }


  const getTotalamount = (data_save) => {
    let settotal = (data_save.reduce((a, v) => a = a + v.F0411_AAP, 0))
    setTotal(settotal.toFixed(2))
  }

  useEffect(() => {
    getCustomerList();

  }, []);

  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
          <div className='flex flex-col gap-y-4 w-full max-w-md mb-0'>
          <p className="text-gray-500 fontSmaller font-bold leading-loose ml-[0.75rem]">Net Account Payable As on Date </p>
          </div>
          <div className='flex flex-col gap-y-4 w-full max-w-md mb-0'>
            <span style={{color:'rgb(17 24 39)',fontSize:22}}>{total}</span>
          </div>
        </div>
      )} />
    </div>

  )
}