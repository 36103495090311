import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../components/LoadingComponent';
import AxiosMain from '../../utilits/const';


export default function YearToDateByItemGroupProduction() {
  const userauth = useSelector((state) => state.auth);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const token = userauth.data.data.userInfo.token
  const [customerList, setCustomerList] = useState([]);
  const [total, setTotal] = useState(0)
  const [qty, setQty] = useState(0)
  const curentYear = moment().year();;
  const nextDate = moment().add(1, 'days').format("DD/MM/YYYY")
  const lastDate = moment().startOf('year').format('DD/MM/YYYY');
  const subtractDate = moment(lastDate).subtract(1, "days").format("DD/MM/YYYY")

  const [frd, setFRD] = useState(0)
  const [hyd, setHYD] = useState(0)
  const [ext, setEXT] = useState(0)
  const [cab, setCAB] = useState(0)
  const [danda, setDANDA] = useState(0)
  const [alr, setALR] = useState(0)
  const [pmp, setPMP] = useState(0)
  const [mis, setMIS] = useState(0)
  const [fom, setFOM] = useState(0)
  const [hre, setHRE] = useState(0)
  const [bld, setBLD] = useState(0)
  const [mac, setMAC] = useState(0)
  const [fm2, setFM2] = useState(0)
  const [gas, setGAS] = useState(0)
  const [spk, setSPK] = useState(0)
  const [lit, setLIT] = useState(0)
  const [eqm, setEQM] = useState(0)
  const [blank, setBLANK] = useState(0)
  const [hva, setHVA] = useState(0)
  const [sth, setSTH] = useState(0)
  const [val, setVAL] = useState(0)
  const [noz, setNOZ] = useState(0)
  const [sgn, setSGN] = useState(0)
  const [trl, setTRL] = useState(0)
  const [blr, setBLR] = useState(0)
  const [sortResult, setSortResult] = useState([]);


  const customerdataList = {
    "token": token,
    "targetName": "V74R6000A",
    "targetType": "view",
    "dataServiceType": "BROWSE",
    "maxPageSize": "10000",
    "returnControlIDs": "F4111.TRQT|F4111.PAID|F4102.SRP1|F4102.SRP2|F4102.SRP3",
    "query": {
      "autoFind": true,
      "condition":
        [{
          "value":
            [{
              "content": subtractDate,//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F4111.TRDJ",//reciept date
          "operator": "GREATER"
        },
        {
          "value":
            [{
              "content": nextDate,//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F4111.TRDJ",//reciept date
          "operator": "LESS"
        },
        {
          "value":
            [{
              "content": "IC",//reciept date
              "specialValueId": "LITERAL"
            }
            ],
          "controlId": "F4111.DCT",//reciept date
          "operator": "EQUAL"
        },
        // {
        //   "value":
        //     [{
        //       "content": "TRUM",//reciept date
        //       "specialValueId": "LITERAL"
        //     }
        //     ],
        //   "controlId": "F4111.EA",//reciept date
        //   "operator": "EQUAL"
        // },
        ]
    }
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }



  const labels = [
    `Bladder: ${bld}`,
    `Cabinet: ${cab}`,
    `Extinguisher: ${ext}`,
    `Fire Alarm: ${alr}`,
    `Fire Pump: ${pmp}`,
    `Fire Rated Doors: ${frd}`,
    `Fm 200: ${fm2}`,
    `Foam: ${fom}`,
    `Hose Reel: ${hre}`,
    `Miscellaneous Items: ${mis}`,
    `Trailer: ${trl}`,
  ];
  const options = {
    chart: {
      type: 'pie',
    },
    labels: labels,
    colors: [
      'rgba(255, 159, 64, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 206, 86, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      
      'rgba(153, 102, 255, 0.8)',
     ],
    
    
    // [' #1e4a6db2', '#1e4a6de6', '#1e4a6de6', '#1e4a6dff'], // Colors in hexadecimal format
  
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width:'100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };


  const series = [
    
    parseFloat(bld.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(cab.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(ext.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(alr.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(pmp.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(frd.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(fm2.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(fom.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(hre.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(mis.toString().replace(/[^0-9.-]+/g, '')),
    parseFloat(trl.toString().replace(/[^0-9.-]+/g, ''))
  ];


  const getCustomerList = async () => {
    setIsCPLoading(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataList,
      config)
      .then((res) => {
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V74R6000A.data.gridData.rowset;
          setCustomerList(data_save)
          setIsCPLoading(false);
          getTotalamount(data_save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoading(false);
      })
  }


  const getTotalamount = (data_save) => {
    var result = [];
    data_save.reduce(function(res, value) {
      if (!res[value.F4102_SRP1]) {
        res[value.F4102_SRP1] = { F4102_SRP1: value.F4102_SRP1, F4111_PAID: 0 };
        result.push(res[value.F4102_SRP1])
      }
      res[value.F4102_SRP1].F4111_PAID += value.F4111_PAID ;
      return res;
    }, {});
    setSortResult(result)
  result.map((values)=>{
    if(values.F4102_SRP1 === "EXT"){
      const value = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setEXT(value)
    }
    if(values.F4102_SRP1 === "PMP"){
      const value2 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setPMP(value2)
    }
    if(values.F4102_SRP1 === "MIS"){
      const value3 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setMIS(value3)
    }
    if(values.F4102_SRP1 === "TRL"){
      const value4 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setTRL(value4)
    }
    if(values.F4102_SRP1 === "CAB"){
      const value4 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setCAB(value4)
    }
    if(values.F4102_SRP1 === "HRE"){
      const value4 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setHRE(value4)
    }
    if(values.F4102_SRP1 === "ALR"){
      const value4 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setALR(value4)
    }
    if(values.F4102_SRP1 === "FRD"){
      const value4 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setFRD(value4)
    }
    if(values.F4102_SRP1 === "BLD"){
      const value4 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setBLD(value4)
    }
    if(values.F4102_SRP1 === "FM2"){
      const value4 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setFM2(value4)
    }
    if(values.F4102_SRP1 === "FOM"){
      const value4 = values.F4111_PAID.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
      setFOM(value4)
    }
  })
  }

  useEffect(() => {
    (async () => {
      const defaultData = await getCustomerList();
    })()
  }, []);

  return (
    <div>
      <LoadingComponent isLoading={isCPLoading} component={(
        <div className="flex flex-wrap items-center justify-center my-5 m-0">
        <div className="items-center justify-center my-5 m-0"style={{ width: '100%', height: '100%', overflow: 'hidden'}}>
             
             <ReactApexChart options={options} series={series} type="pie" width={350} height={365} />
           </div>
        </div>
      )} />
    </div>

  )
}