const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        paddingLeft: '0 5px',
        justifyContent: 'center',
        backgroundColor: '#FFA500'
      },
    },
  }
  export { tableCustomStyles };