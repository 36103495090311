import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingComponent from '../../../components/LoadingComponent';
import AxiosMain from '../../../utilits/const';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';

import { tableCustomStyles } from '../../TableStyle';
export default function AllOrder() {

  const [isCPLoadingfy, setIsCPLoadingFY] = useState(false);
  const [customerListfy, setCustomerListFY] = useState([]);
  const [sortResultFY, setSortResultFY] = useState([]);
  const userauth = useSelector((state) => state.auth);
  const token = userauth.data.data.userInfo.token

  const [openFY, setOpenFY] = React.useState(false);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const customerdataListALL = {
    "token": token,
    "targetName": "V554211L",
    "targetType": "view",
    "maxPageSize": "1000000",
    "dataServiceType": "BROWSE",
    "returnControlIDs": "F0101.ALPH|F4211.DOCO|F4211.LNID|F4211.NXTR|F4211.DCTO|F4211.DSC1|F0101.ALPH|F4211.DRQJ|F4211.TRDJ|F4211.LITM|F4211.UPRC|F4211.AEXP|F4211.UNCS|F4211.ECST|F4211.FY|F0101.AC05",
    "query": {
      "autoFind": true,
      "condition":
        [
          {
            "value":
              [{
                "content": "620",
                "specialValueId": "LITERAL"
              }
              ],
            "controlId": "F4211.NXTR",
            "operator": "LESS"
          }
          // {
          //   "value":
          //     [{
          //       "content": lastmonthlastdate,//date less than 1 date example today's date is 15/08/2023 then it should calculate //last month's last date
          //       "specialValueId": "LITERAL"
          //     }
          //     ],
          //   "controlId": "F4211.TRDJ",
          //   "operator": "GREATER"
          // },
          // {
          //   "value":
          //     [{
          //       "content": nextDate,//date greater than 1 date for today's date plus 1 date
          //       "specialValueId": "LITERAL"
          //     }
          //     ],
          //   "controlId": "F4211.IVD",
          //   "operator": "LESS"
          // }
        ]
    }
  }


  const getrecordAll = async () => {
    setIsCPLoadingFY(true);
    setOpenFY(true);
    await AxiosMain.post(`/jderest/v2/dataservice`,
      customerdataListALL,
      config)
      .then((res) => {
        console.log('all', res);
        if (res.status === 200) {
          let data_save = res.data.fs_DATABROWSE_V554211L.data.gridData.rowset;
          setCustomerListFY(data_save)
          setIsCPLoadingFY(false);
          // getTotalamountDB(data_save)
        }
      }).catch((e) => {
        Swal.fire({
          title: 'Warning',
          text: e,
          icon: "warning",
        })
        console.log("Error While getting Kit user", e);
        setIsCPLoadingFY(false);
      })
  }
  const columnsLable = [
    {
      id: 'alph',
      name: 'Customer Name',
      sortable: true,
      selector: (row) => row.F0101_ALPH,
      cell: (row) => <div>{row.F0101_ALPH}</div>,
    },
    {
      id: 'doco',
      name: 'Order Number',
      sortable: true,
      selector: (row) => <div>{row.F4211_DOCO}</div>,
      cell: (row) => <div>{row.F4211_DOCO}</div>,
    },
    {
      id: 'drqj',
      name: 'Requested Date',
      sortable: true,
      selector: (row) => <div>{row.F4211_DRQJ}</div>,
      cell: (row) => <div>{row.F4211_DRQJ}</div>,
    },
    {
      id: 'trdj',
      name: 'Order Date',
      sortable: true,
      selector: (row) => <div>{row.F4211_TRDJ}</div>,
      cell: (row) => <div>{row.F4211_TRDJ}</div>,
    },
    {
      id: 'litm',
      name: 'Item Number',
      sortable: true,
      selector: (row) => <div>{row.F4211_LITM}</div>,
      cell: (row) => <div>{row.F4211_LITM}</div>,
    },
    {
      id: 'dsc1',
      name: 'Item Description',
      sortable: true,
      selector: (row) => <div>{row.F4211_DSC1}</div>,
      cell: (row) => <div>{row.F4211_DSC1}</div>,
    },
    {
      id: 'nxtr',
      name: 'Order Status',
      sortable: true,
      selector: (row) => <div>{row.F4211_NXTR}</div>,
      cell: (row) => <div>{row.F4211_NXTR}</div>,
    },
    {
      id: 'uprc',
      name: 'Unit Price',
      sortable: true,
      selector: (row) => <div>{row.F4211_UPRC}</div>,
      cell: (row) => <div>{row.F4211_UPRC}</div>,
    },
    {
      id: 'aexp',
      name: 'Extended Price',
      sortable: true,
      selector: (row) => <div>{row.F4211_AEXP}</div>,
      cell: (row) => <div>{row.F4211_AEXP}</div>,
    },
    {
      id: 'uncs',
      name: 'Unit Cost',
      sortable: true,
      selector: (row) => <div>{row.F4211_UNCS}</div>,
      cell: (row) => <div>{row.F4211_UNCS}</div>,
    },
    {
      id: 'ecst',
      name: 'Extended Cost',
      sortable: true,
      selector: (row) => <div>{row.F4211_ECST}</div>,
      cell: (row) => <div>{row.F4211_ECST}</div>,
    },
    {
      id: 'ac05',
      name: 'Sales Person Code',
      sortable: true,
      selector: (row) => <div>{row.F0101_AC05}</div>,
      cell: (row) => <div>{row.F0101_AC05}</div>,
    }


  ]

  const handleCloseFY = () => {
    setOpenFY(false);
  };

  const OnClicK = () => {
    setOpenFY(true);
    console.log('OnClicK');
    getrecordAll();
  };
  return (
   <div>
    <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={'md'}
        open={openFY}
        onClose={handleCloseFY}
        id="FyModal"
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            maxHeight: 700
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Open Orders
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFY}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div >
              <LoadingComponent isLoading={isCPLoadingfy} component={(
                <DataTableExtensions columns={columnsLable}
                  data={customerListfy}
                  export={true}
                  print={false}
                  filterPlaceholder="Search Here"
                  exportHeaders={true}
                >
                  <DataTable
                    className="cm-category-admin-table"
                    defaultSortFieldId="orderNumber"
                    sortable={true}
                    columns={columnsLable}
                    data={customerListfy}
                    striped
                    pagination
                    customStyles={tableCustomStyles}
                    expandableRows={false}
                    // sortIcon={sortIcon}
                    fixedHeader={false}
                    pointerOnHover={true}
                    selectableRowsHighlight={true}
                    selectableRowsSingle={true}
                    selectableRowsVisibleOnly={true}
                    style={{ width: '100px' }}
                    overflowY={true}
                    progressPending={isCPLoadingfy}

                  // subHeaderComponent={subHeaderComponentMemo}
                  // subHeader={true}
                  />
                </DataTableExtensions>
              )} />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>
   <button className='fontSmaller' style={{ color: 'rgb(17 24 39)' }}  onClick={() => OnClicK()} >Open Orders</button>
   </div>
  )
}