import React, { useState } from 'react';
// import DropDownIcon from 'remixicon-react/ArrowDropDownLineIcon';
// import ProfileIcon from 'remixicon-react/User3FillIcon';
import { Avatar } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import LogtIcon from 'remixicon-react/LogoutBoxRLineIcon';
import Swal from 'sweetalert2';

import { useDispatch, useSelector } from 'react-redux';
import { removeAuthState } from '../redux/features/authStateSlice';


// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }




export default function UserProfileDropdown(props) {
  const { profile } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.data);
  const [showProfileMenu, setshowProfileMenu] = useState(false)
//   const config = {
//     headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${userData.token.access}`,
//     },
//   } 


  const clickHandler = (e) => {
    setshowProfileMenu(!showProfileMenu)
  };

//   React.useEffect(()=>{
//     const interval = setInterval(()=>{
//       var date = new Date(userData.user.exp * 1000 - 36000).toLocaleString();
//       var newDate = moment(date,'DD/MM/YYYY HH:mm').toDate();
//       var subtractDate = moment(newDate).subtract(1,"hours").toDate()
//       var curentDate = new Date();
//       var curentTime = moment(curentDate,'DD/MM/YYYY HH:mm').toDate();
//       if(subtractDate === curentTime){
//         AxiosMain.get("/logout/", config).then((res) => {
//           if (res.status === 200) {
//             Swal.fire({
//               title: 'Done',
//               text: res.data.data,
//               icon: "success",
//               timer: 3000,
//               showConfirmButton: false
//             })
            
//             dispatch(removeAuthState());
//             dispatch(removePersonalDBState());
//             dispatch(removeB2BDBState());
//             navigate("/")
//           }
//         }).catch((e) => {
//           if (e.response?.data) {
//             Swal.fire({
//               title: 'Warning',
//               text: `Something Went Wrong!`,
//               icon: "warning",
//             })
//           }
//           else {
//             Swal.fire({
//               title: 'Warning',
//               text: `Something Went wrong!`,
//               icon: "warning",
//             })
//           }
//         })
//       }else if(subtractDate <= curentTime){
//         console.log('else if called')
//         AxiosMain.get("/logout/", config).then((res) => {
//           if (res.status === 200) {
//             Swal.fire({
//               title: 'Done',
//               text: res.data.data,
//               icon: "success",
//               timer: 3000,
//               showConfirmButton: false
//             })
            
//             dispatch(removeAuthState());
//             dispatch(removePersonalDBState());
//             dispatch(removeB2BDBState());
//             navigate("/")
//           }
//         }).catch((e) => {
//           if (e.response?.data) {
//             Swal.fire({
//               title: 'Warning',
//               text: `Something Went Wrong!`,
//               icon: "warning",
//             })
//           }
//           else {
//             Swal.fire({
//               title: 'Warning',
//               text: `Something Went wrong!`,
//               icon: "warning",
//             })
//           }
//         })
//       }
//       else{
//         Swal.fire({
//           title: 'Warning',
//           text: `Something Went wrong!`,
//           icon: "warning",
//         })
//       }

//     },[3600000]);
//     return () => clearInterval(interval);
//   },[])


  const logoutuser = () => {
    dispatch(removeAuthState());
    Swal.fire({
              title: 'Done',
              text: 'Logout Successfully',
              icon: "success",
              timer: 3000,
              showConfirmButton: false
            })
    navigate("/")
    
    //   AxiosMain.get("/logout/").then((res) => {
    //     if (res.status === 200) {
    //       Swal.fire({
    //         title: 'Done',
    //         text: res.data.data,
    //         icon: "success",
    //         timer: 3000,
    //         showConfirmButton: false
    //       })
          
    //       dispatch(removeAuthState());
    //       dispatch(removePersonalDBState());
    //       dispatch(removeB2BDBState());
    //       navigate("/")
    //     }
    //   }).catch((e) => {
    //     if (e.response?.data) {
    //       Swal.fire({
    //         title: 'Warning',
    //         text: `Something Went Wrong!`,
    //         icon: "warning",
    //       })
    //     }
    //     else {
    //       Swal.fire({
    //         title: 'Warning',
    //         text: `Something Went wrong!`,
    //         icon: "warning",
    //       })
    //     }
    //   })
  }
  return (
    <div className='relative inline-block text-left'>
      <h3 onClick={clickHandler} className="flex flex-row cursor-pointer justify-center items-center rounded-full w-full">
        <p className=' hidden lg:flex lg:text-xs xl:text-base pr-2  min-w-fit text-white'>Welcome {profile && profile.username ? (profile.username) : (<>User</>)}</p>
        {/* <img className='rounded-[20px]' src={logo} alt="logo" style={{ width: "40px", height: "30px" }} /> */}
        <Avatar rounded={true}  alt="avatar of Jese" bordered
          color="success" size="md"
          
          />
      </h3>

      {showProfileMenu && <div className='origin-top-right absolute right-0 mt-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
        {/* <div className='p-2 text-[#5a5c69] cm-top-bar-links-list'> */}
          {/* <div className='flex py-1 px-2 gap-x-4 cursor-pointer' onClick={() => { setshowProfileMenu(!showProfileMenu); navigate("/profile"); }}><ProfileIcon />Profile</div> */}
          {/* <div className='flex py-1 px-2 gap-x-4 cursor-pointer'><SettingsIcon />Settings</div> */}
          {/* <div className='flex py-1 px-2 gap-x-4 cursor-pointer'><ActivityIcon />Activity Log</div> */}
          {/* <hr className='sidebar-divider' /> */}
          <div className='flex py-1 px-2 gap-x-4 cursor-pointer' onClick={() => logoutuser()}><LogtIcon />Logout</div>
        {/* </div> */}
      </div>}
    </div>
  )
}